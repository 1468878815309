import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { type Currency, type Role, type Service, type SupportedLanguage, type TransformedEnum } from '../types';
import { defaultLanguage, supportedLanguages } from '../constants';
import { localStorageKeys, localStorageService } from '../../../shared/utils/localStorage';

interface AppState {
	enums: TransformedEnum | null;
	isLoading: boolean;
	has2fa: boolean;
	isInited: boolean;
	services: Service[];
	roles: Role[];
	language: SupportedLanguage;
	currencies: Currency[];
	history: string[];
}
const initialState: AppState = {
	enums: null,
	isLoading: true,
	has2fa: false,
	isInited: false,
	services: [],
	language: 'en',
	currencies: [],
	history: [],
	roles: [],
};

export const appSlice = createSlice({
	name: 'app',
	initialState,
	reducers: {
		setEnums(state, { payload }: PayloadAction<TransformedEnum>) {
			state.enums = payload;
		},
		setServices(state, { payload }: PayloadAction<Service[]>) {
			state.services = payload;
		},
		setLoading(state, { payload }: PayloadAction<boolean>) {
			state.isLoading = payload;
		},
		setHas2fa(state, { payload }: PayloadAction<boolean>) {
			state.has2fa = payload;
		},
		setInited(state, { payload }: PayloadAction<boolean>) {
			state.isInited = payload;
		},
		initLanguage(state) {
			const browserLanguage = navigator?.language.split('-')[0] as SupportedLanguage;
			const language = supportedLanguages.includes(browserLanguage) ? browserLanguage : defaultLanguage;
			localStorageService.set(localStorageKeys.LANGUAGE, language);
			state.language = language;
		},
		setLanguage(state, { payload }: PayloadAction<SupportedLanguage>) {
			state.language = payload;
		},
		setCurrencies(state, { payload }: PayloadAction<Currency[]>) {
			state.currencies = payload;
		},
		setRoles(state, { payload }: PayloadAction<Role[]>) {
			state.roles = payload;
		},
		addHistoryRecord(state, { payload }: PayloadAction<string>) {
			if (state.history[state.history.length - 1] !== payload) {
				state.history = [...state.history, payload];
			}
		},
		removeHistoryRecord(state) {
			state.history.pop();
		},
	},
});

export const {
	setEnums,
	setLoading,
	setServices,
	setLanguage,
	setCurrencies,
	setRoles,
	addHistoryRecord,
	removeHistoryRecord,
	setInited,
	initLanguage,
	setHas2fa,
} = appSlice.actions;

export default appSlice.reducer;
