import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import type { IAuditLog, ITableSettings, Nullable, UpdateTableData } from 'shared/types';
import type { ColDef } from 'ag-grid-community';
import { initialSettings } from '../constants';
import {
	type IWithdrawal,
	type IWithdrawalDetails,
	type ITransaction,
	WithdrawalStep,
	type IWithdrawalAccount,
	type IWithdrawalAccountWallet,
	type IAddressData,
} from '../types';
import { checkWithdrawalAmountAction, loadWithdrawalsAction } from './actions';

interface WithdrawalsState {
	withdrawals: IWithdrawal[];
	totalPages: number;
	settings: ITableSettings;
	isLoading: boolean;
	columns: Array<ColDef<IWithdrawal>>;
	selectedWithdrawal: {
		id: Nullable<string>;
		mainData: Nullable<IWithdrawalDetails>;
		transactions: {
			settings: ITableSettings;
			columns: Array<ColDef<ITransaction>>;
			rows: ITransaction[];
			totalPages: number;
			isLoading: boolean;
		};
		logs: IAuditLog[];
	};
	new: {
		activeStep: WithdrawalStep;
		availableAccounts: IWithdrawalAccount[];
		selectedWallet: Nullable<IWithdrawalAccountWallet>;
		amount: Nullable<number>;
		fee: number;
		totalAmount: number;
		address: Nullable<IAddressData>;
	};
}
const initialState: WithdrawalsState = {
	withdrawals: [],
	totalPages: 1,
	settings: initialSettings,
	isLoading: false,
	columns: [],
	selectedWithdrawal: {
		id: null,
		mainData: null,
		transactions: {
			settings: initialSettings,
			columns: [],
			rows: [],
			totalPages: 1,
			isLoading: false,
		},
		logs: [],
	},
	new: {
		activeStep: WithdrawalStep.WITHDRAWAL_ACCOUNT,
		availableAccounts: [],
		selectedWallet: null,
		amount: null,
		address: null,
		fee: 0,
		totalAmount: 0,
	},
};

export const appSlice = createSlice({
	name: 'withdrawals',
	initialState,
	reducers: {
		setWithdrawals(state, { payload }: PayloadAction<IWithdrawal[]>) {
			state.withdrawals = payload;
		},
		setColumns(state, { payload }: PayloadAction<Array<ColDef<IWithdrawal>>>) {
			state.columns = payload;
		},
		setTotalPages(state, { payload }: PayloadAction<number>) {
			state.totalPages = payload;
		},
		updateSettings(state, { payload }: PayloadAction<UpdateTableData>) {
			state.settings[payload.key] = payload.value as never;
		},
		setSelectedWithdrawalId(state, { payload }: PayloadAction<string>) {
			state.selectedWithdrawal.id = payload;
		},
		setSelectedWithdrawalData(state, { payload }: PayloadAction<IWithdrawalDetails>) {
			state.selectedWithdrawal.mainData = payload;
		},
		setSelectedWithdrawalAuditLogs(state, { payload }: PayloadAction<IAuditLog[]>) {
			state.selectedWithdrawal.logs = payload;
		},
		setSelectedWithdrawalTransactions(state, { payload }: PayloadAction<ITransaction[]>) {
			state.selectedWithdrawal.transactions.rows = payload;
		},
		setSelectedWithdrawalTransactionsColumns(state, { payload }: PayloadAction<Array<ColDef<ITransaction>>>) {
			state.selectedWithdrawal.transactions.columns = payload;
		},
		setSelectedWithdrawalTransactionsTotalPages(state, { payload }: PayloadAction<number>) {
			state.selectedWithdrawal.transactions.totalPages = payload;
		},
		updateSelectedWithdrawalTransactionsSettings(state, { payload }: PayloadAction<UpdateTableData>) {
			state.selectedWithdrawal.transactions.settings[payload.key] = payload.value as never;
		},
		setNewWithdrawalStep(state, { payload }: PayloadAction<WithdrawalStep>) {
			state.new.activeStep = payload;
		},
		setNewWithdrawalAccounts(state, { payload }: PayloadAction<IWithdrawalAccount[]>) {
			state.new.availableAccounts = payload;
		},
		setNewWithdrawalAccountWallet(state, { payload }: PayloadAction<Nullable<IWithdrawalAccountWallet>>) {
			state.new.selectedWallet = payload;
		},
		setNewWithdrawalAmount(state, { payload }: PayloadAction<Nullable<number>>) {
			state.new.amount = payload;
		},
		setNewWithdrawalFee(state, { payload }: PayloadAction<number>) {
			state.new.fee = payload;
		},
		setNewWithdrawalTotalAmount(state, { payload }: PayloadAction<number>) {
			state.new.totalAmount = payload;
		},
		setNewWithdrawalAddress(state, { payload }: PayloadAction<Nullable<IAddressData>>) {
			state.new.address = payload;
		},
	},
	extraReducers: builder => {
		builder.addCase(loadWithdrawalsAction.pending, (state, action) => {
			state.isLoading = true;
		});

		builder.addCase(loadWithdrawalsAction.fulfilled, (state, action) => {
			state.isLoading = false;
		});
		builder.addCase(loadWithdrawalsAction.rejected, (state, action) => {
			state.isLoading = false;
		});
		builder.addCase(checkWithdrawalAmountAction.rejected, (state, action) => {
			throw new Error('Amount check was rejected');
		});
	},
});

export const {
	setWithdrawals,
	setColumns,
	setTotalPages,
	updateSettings,
	setSelectedWithdrawalId,
	setSelectedWithdrawalData,
	setSelectedWithdrawalAuditLogs,
	setSelectedWithdrawalTransactions,
	setSelectedWithdrawalTransactionsColumns,
	setSelectedWithdrawalTransactionsTotalPages,
	updateSelectedWithdrawalTransactionsSettings,
	setNewWithdrawalStep,
	setNewWithdrawalAccounts,
	setNewWithdrawalAccountWallet,
	setNewWithdrawalAmount,
	setNewWithdrawalAddress,
	setNewWithdrawalFee,
	setNewWithdrawalTotalAmount,
} = appSlice.actions;

export default appSlice.reducer;
