import React, { type FC } from 'react';
import { Outlet } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import Header from './components/Header';

const MainContainer: FC = () => {
	return (
		<div className="flex items-stretch min-h-screen">
			<Sidebar />
			<div className="w-full flex flex-col" style={{ maxWidth: 'calc(100% - 255px)' }}>
				<Header />
				<div className="bg-gray-100 flex-1 px-8 pt-6">
					<Outlet />
				</div>
			</div>
		</div>
	);
};

export default MainContainer;
