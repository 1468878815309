import React, {
	type ChangeEvent,
	type FC,
	type MouseEvent as RMouseEvent,
	type ReactNode,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import clsx from 'clsx';
import { type IHeaderParams } from 'ag-grid-community';
import { useTranslation } from 'react-i18next';
import { customEventEmitter } from 'shared/utils';
import { type Nullable, Order } from 'shared/types';
import { PortalModal } from 'components';
import { useCustomEventListener } from 'shared/hooks';
import { Input } from 'components/UI';
import { ReactComponent as ArrowIcon } from 'assets/icons/arrow.svg';
// Import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import { ReactComponent as DoubleArrowIcon } from 'assets/icons/double-arrow.svg';
import { ReactComponent as DoubleArrowActiveIcon } from 'assets/icons/double-arrow-active.svg';
import SelectSettings from './SelectSettings';
import RangeSetting from './RangeSetting';
import { type DateTimeRange, type Range } from '../types';
import RangeDateTimeSetting from './RangeDateTimeSetting';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

const HeaderComponent: FC<IHeaderParams> = props => {
	const { t } = useTranslation();
	// Const [tooltipCoordinates, setTooltipCoordinates] = useState<Nullable<DOMRect>>(null);
	const [expandCoordinates, setExpandCoordinates] = useState<Nullable<DOMRect>>(null);
	// Const [visibleTooltip, setVisibleTooltip] = useState<boolean>(false);
	const [expanded, setExpanded] = useState<boolean>(false);
	// Const tooltipRef = useRef<HTMLDivElement>(null);
	const expandBtnRef = useRef<HTMLDivElement>(null);
	const portalBodyRef = useRef<HTMLDivElement>(null);

	const field = useMemo<string>(() => props.column.getColDef().field ?? '', [props.column]);
	const settings = useMemo<{
		value: string | number[] | boolean | Range | DateTimeRange;
		orderBy: string;
		orderKey?: string;
		orderDir: Order;
		isSortingHidden?: boolean;
		isFiltersHidden?: boolean;
		filterOptions?: Array<{
			id: number;
			value: string;
		}>;
	}>(() => props.column.getColDef()?.cellEditor, [props]);
	const initialSelected = useMemo<number[]>(() => {
		if (Array.isArray(settings.value) && settings.value.length) return settings.value; // Groups, classes, statuses if exist
		if (typeof settings.value === 'boolean') return [Number(settings.value)]; // Boolean as number if exist
		if (settings.filterOptions) return settings.filterOptions?.map(opt => opt.id); // Groups, classes, statuses, booleans if empty
		return [];
	}, [settings]);

	const rangeValues = useMemo<Range>(() => {
		if (typeof settings.value !== 'object' || Array.isArray(settings.value) || !settings.value) return { from: null, to: null };

		return {
			from: (settings.value as Range).from,
			to: (settings.value as Range).to,
		};
	}, [settings.value]);

	const [selectedItems, setSelectedItems] = useState<number[]>(initialSelected);

	// Const showTooltip = useCallback((e: RMouseEvent) => {
	// 	setTooltipCoordinates(e.currentTarget.getBoundingClientRect());
	// 	setVisibleTooltip(true);
	// }, []);
	//
	// const hideTooltip = useCallback(() => {
	// 	setVisibleTooltip(false);
	// }, []);

	const toggleExpanded = useCallback((e: RMouseEvent) => {
		setExpanded(prev => !prev);
		setExpandCoordinates(e.currentTarget.getBoundingClientRect());
	}, []);

	const handleClose = useCallback(() => {
		setExpanded(false);
	}, []);

	const handleClickOut = useCallback(
		(e: MouseEvent) => {
			if (!e.composedPath().includes(expandBtnRef.current as Element) && !e.composedPath().includes(portalBodyRef.current as Element))
				handleClose();
		},
		[handleClose],
	);

	const handleTableScroll = useCallback(() => {
		if (!expanded) return;
		setExpanded(false);
	}, [expanded]);

	const handleFilterChange = useCallback((key: string, value: unknown) => {
		customEventEmitter.trigger(customEventEmitter.events.UPDATE_FILTER_TABLE, { key, value });
	}, []);

	const handleChangeOrder = useCallback(() => {
		if ((settings.orderKey ?? field) === settings.orderBy) {
			if (settings.orderDir === Order.DESC) {
				handleFilterChange('OrderBy', '');
				handleFilterChange('OrderDir', Order.EMPTY);
				return;
			}

			handleFilterChange('OrderDir', settings.orderDir === Order.ASC ? Order.DESC : Order.ASC);
			return;
		}

		handleFilterChange('OrderBy', settings.orderKey ?? field);
		handleFilterChange('OrderDir', Order.ASC);
	}, [field, handleFilterChange, settings]);

	const handleConfirmSettings = useCallback(
		(key: string, value?: Range | DateTimeRange) => {
			switch (field) {
				case 'group':
				case 'status':
				case 'service':
				case 'services':
				case 'currency':
				case 'fromCurrency':
				case 'type':
				case 'roleName':
				case 'class': {
					handleFilterChange(key, selectedItems.length === settings.filterOptions?.length ? null : selectedItems);
					break;
				}

				case 'allowed':
				case 'whiteListOnly':
				case 'splitWithdrawalsAllowed':
				case 'allowAutoExchange': {
					handleFilterChange(key, selectedItems.length === 2 ? null : Boolean(selectedItems[0]));
					break;
				}

				case 'priority':
				case 'amountFrom':
				case 'balanceAmount':
				case 'minExchangeReserveAmount':
				case 'maxExchangeReserveAmount':
				case 'balance':
				case 'exchangeAmount':
				case 'amount':
				case 'fee':
				case 'feeIn':
				case 'netAmount':
				case 'totalAmount':
				case 'holdAmount':
				case 'actualAmount':
				case 'expectedAmount':
				case 'overdraftAmount': {
					if (!value) break;
					if (field === 'amountFrom') {
						handleFilterChange('AmountFromMin', (value as Range).from);
						handleFilterChange('AmountFromMax', (value as Range).to);
						break;
					}

					if (field === 'amount') {
						handleFilterChange('AmountMin', (value as Range).from);
						handleFilterChange('AmountMax', (value as Range).to);
						break;
					}

					if (field === 'fee') {
						handleFilterChange('FeeMin', (value as Range).from);
						handleFilterChange('FeeMax', (value as Range).to);
						break;
					}

					if (field === 'feeIn') {
						handleFilterChange('FeeInMin', (value as Range).from);
						handleFilterChange('FeeInMax', (value as Range).to);
						break;
					}

					if (field === 'netAmount') {
						handleFilterChange('NetAmountMin', (value as Range).from);
						handleFilterChange('NetAmountMax', (value as Range).to);
						break;
					}

					if (field === 'exchangeAmount') {
						handleFilterChange('ExchangeBalanceAmountFrom', (value as Range).from);
						handleFilterChange('ExchangeBalanceAmountTo', (value as Range).to);
						break;
					}

					if (field === 'totalAmount') {
						handleFilterChange('TotalBalanceAmountFrom', (value as Range).from);
						handleFilterChange('TotalBalanceAmountTo', (value as Range).to);
						break;
					}

					if (field === 'holdAmount') {
						handleFilterChange('HoldBalanceAmountFrom', (value as Range).from);
						handleFilterChange('HoldBalanceAmountTo', (value as Range).to);
						break;
					}

					if (field === 'balance') {
						handleFilterChange('BalanceMin', (value as Range).from);
						handleFilterChange('BalanceMax', (value as Range).to);
						break;
					}

					if (field === 'actualAmount') {
						handleFilterChange('ActualAmountMin', (value as Range).from);
						handleFilterChange('ActualAmountMax', (value as Range).to);
						break;
					}

					if (field === 'expectedAmount') {
						handleFilterChange('ExpectedAmountMin', (value as Range).from);
						handleFilterChange('ExpectedAmountMax', (value as Range).to);
						break;
					}

					const keyFrom = field.endsWith('From')
						? field.charAt(0).toUpperCase() + field.slice(1, -4) + 'From'
						: field.charAt(0).toUpperCase() + field.slice(1) + 'From';
					const keyTo = field.endsWith('From')
						? field.charAt(0).toUpperCase() + field.slice(1, -4) + 'To'
						: field.charAt(0).toUpperCase() + field.slice(1) + 'To';
					handleFilterChange(keyFrom, (value as Range).from);
					handleFilterChange(keyTo, (value as Range).to);

					break;
				}

				case 'processedAt':
				case 'createdAt': {
					if (!value) break;
					const keyFrom = field.charAt(0).toUpperCase() + field.slice(1) + 'From';
					const keyTo = field.charAt(0).toUpperCase() + field.slice(1) + 'To';
					const valueFrom = dayjs((value as Range).from)
						.utc()
						.format();
					const valueTo = dayjs((value as Range).to)
						.utc()
						.format();
					if ((value as Range).from) handleFilterChange(keyFrom, valueFrom);
					if ((value as Range).to) handleFilterChange(keyTo, valueTo);
					break;
				}

				default:
					handleFilterChange(key, null);
			}

			setExpanded(false);
		},
		[field, handleFilterChange, selectedItems, settings.filterOptions?.length],
	);
	const renderSettings = useCallback(
		(field: string): ReactNode => {
			const fieldSettings: Record<string, ReactNode> = {
				group: (
					<SelectSettings
						onClose={handleClose}
						onConfirm={handleConfirmSettings}
						selectedItems={selectedItems}
						setSelectedItems={setSelectedItems}
						settingKey="Groups"
						options={settings.filterOptions}
					/>
				),
				status: (
					<SelectSettings
						onClose={handleClose}
						onConfirm={handleConfirmSettings}
						selectedItems={selectedItems}
						setSelectedItems={setSelectedItems}
						settingKey="Statuses"
						options={settings.filterOptions}
					/>
				),
				class: (
					<SelectSettings
						onClose={handleClose}
						onConfirm={handleConfirmSettings}
						selectedItems={selectedItems}
						setSelectedItems={setSelectedItems}
						settingKey="Classes"
						options={settings.filterOptions}
					/>
				),
				whiteListOnly: (
					<SelectSettings
						onClose={handleClose}
						onConfirm={handleConfirmSettings}
						selectedItems={selectedItems}
						setSelectedItems={setSelectedItems}
						settingKey="WhiteListOnly"
						options={settings.filterOptions}
					/>
				),
				allowAutoExchange: (
					<SelectSettings
						onClose={handleClose}
						onConfirm={handleConfirmSettings}
						selectedItems={selectedItems}
						setSelectedItems={setSelectedItems}
						settingKey="AllowAutoExchange"
						options={settings.filterOptions}
					/>
				),
				services: (
					<SelectSettings
						onClose={handleClose}
						onConfirm={handleConfirmSettings}
						selectedItems={selectedItems}
						setSelectedItems={setSelectedItems}
						settingKey="Services"
						options={settings.filterOptions}
					/>
				),
				service: (
					<SelectSettings
						onClose={handleClose}
						onConfirm={handleConfirmSettings}
						selectedItems={selectedItems}
						setSelectedItems={setSelectedItems}
						settingKey="Service"
						options={settings.filterOptions}
					/>
				),
				type: (
					<SelectSettings
						onClose={handleClose}
						onConfirm={handleConfirmSettings}
						selectedItems={selectedItems}
						setSelectedItems={setSelectedItems}
						settingKey="Types"
						options={settings.filterOptions}
					/>
				),
				roleName: (
					<SelectSettings
						onClose={handleClose}
						onConfirm={handleConfirmSettings}
						selectedItems={selectedItems}
						setSelectedItems={setSelectedItems}
						settingKey="RoleNames"
						options={settings.filterOptions}
					/>
				),
				allowed: (
					<SelectSettings
						onClose={handleClose}
						onConfirm={handleConfirmSettings}
						selectedItems={selectedItems}
						setSelectedItems={setSelectedItems}
						settingKey="Allowed"
						options={settings.filterOptions}
					/>
				),
				title: (
					<Input
						data-test-id="client-search-title"
						placeholder={t('ui.table.startSearching')}
						search
						value={settings.value as string}
						onInput={(e: ChangeEvent<HTMLInputElement>) => {
							handleFilterChange('Title', e.target.value);
						}}
					/>
				),
				userName: (
					<Input
						data-test-id="client-search-userName"
						placeholder={t('ui.table.startSearching')}
						search
						value={settings.value as string}
						onInput={(e: ChangeEvent<HTMLInputElement>) => {
							handleFilterChange('UserName', e.target.value);
						}}
					/>
				),
				email: (
					<Input
						data-test-id="client-search-email"
						placeholder={t('ui.table.startSearching')}
						search
						value={settings.value as string}
						onInput={(e: ChangeEvent<HTMLInputElement>) => {
							handleFilterChange('Email', e.target.value);
						}}
					/>
				),
				shortTitle: (
					<Input
						data-test-id="client-search-shortTitle"
						placeholder={t('ui.table.startSearching')}
						search
						value={settings.value as string}
						onInput={(e: ChangeEvent<HTMLInputElement>) => {
							handleFilterChange('ShortTitle', e.target.value);
						}}
					/>
				),
				businessWebsite: (
					<Input
						data-test-id="client-search-businessWebsite"
						placeholder={t('ui.table.startSearching')}
						search
						value={settings.value as string}
						onInput={(e: ChangeEvent<HTMLInputElement>) => {
							handleFilterChange('BusinessWebsite', e.target.value);
						}}
					/>
				),
				companyEmail: (
					<Input
						data-test-id="client-search-companyEmail"
						placeholder={t('ui.table.startSearching')}
						search
						value={settings.value as string}
						onInput={(e: ChangeEvent<HTMLInputElement>) => {
							handleFilterChange('CompanyEmail', e.target.value);
						}}
					/>
				),
				companyPhone: (
					<Input
						data-test-id="client-search-companyPhone"
						placeholder={t('ui.table.startSearching')}
						search
						value={settings.value as string}
						onInput={(e: ChangeEvent<HTMLInputElement>) => {
							handleFilterChange('CompanyPhone', e.target.value);
						}}
					/>
				),
				paymentSystemAccountTitle: (
					<Input
						data-test-id="client-search-paymentSystemAccountTitle"
						placeholder={t('ui.table.startSearching')}
						search
						value={settings.value as string}
						onInput={(e: ChangeEvent<HTMLInputElement>) => {
							handleFilterChange('PaymentSystemAccountTitle', e.target.value);
						}}
					/>
				),
				externalUserId: (
					<Input
						data-test-id="client-search-externalUserId"
						placeholder={t('ui.table.startSearching')}
						search
						value={settings.value as string}
						onInput={(e: ChangeEvent<HTMLInputElement>) => {
							handleFilterChange('ExternalUserId', e.target.value);
						}}
					/>
				),
				userMessage: (
					<Input
						data-test-id="client-search-userMessage"
						placeholder={t('ui.table.startSearching')}
						search
						value={settings.value as string}
						onInput={(e: ChangeEvent<HTMLInputElement>) => {
							handleFilterChange('UserMessage', e.target.value);
						}}
					/>
				),
				reasonMessage: (
					<Input
						data-test-id="client-search-reasonMessage"
						placeholder={t('ui.table.startSearching')}
						search
						value={settings.value as string}
						onInput={(e: ChangeEvent<HTMLInputElement>) => {
							handleFilterChange('ReasonMessage', e.target.value);
						}}
					/>
				),
				paymentSystemTitle: (
					<Input
						data-test-id="client-search-paymentSystemTitle"
						placeholder={t('ui.table.startSearching')}
						search
						value={settings.value as string}
						onInput={(e: ChangeEvent<HTMLInputElement>) => {
							handleFilterChange('PaymentSystemTitle', e.target.value);
						}}
					/>
				),
				paymentSystemWalletTitle: (
					<Input
						data-test-id="client-search-paymentSystemWalletTitle"
						placeholder={t('ui.table.startSearching')}
						search
						value={settings.value as string}
						onInput={(e: ChangeEvent<HTMLInputElement>) => {
							handleFilterChange('PaymentSystemWalletTitle', e.target.value);
						}}
					/>
				),
				businessId: (
					<Input
						data-test-id="client-search-businessId"
						placeholder={t('ui.table.startSearching')}
						search
						value={settings.value as string}
						onInput={(e: ChangeEvent<HTMLInputElement>) => {
							handleFilterChange('BusinessId', e.target.value);
						}}
					/>
				),
				id: (
					<Input
						data-test-id="client-search-id"
						placeholder={t('ui.table.startSearching')}
						search
						value={settings.value as string}
						onInput={(e: ChangeEvent<HTMLInputElement>) => {
							handleFilterChange('Id', e.target.value);
						}}
					/>
				),
				phoneNumber: (
					<Input
						data-test-id="client-search-phoneNumber"
						placeholder={t('ui.table.startSearching')}
						search
						value={settings.value as string}
						onInput={(e: ChangeEvent<HTMLInputElement>) => {
							handleFilterChange('PhoneNumber', e.target.value);
						}}
					/>
				),
				number: (
					<Input
						data-test-id="client-search-number"
						placeholder={t('ui.table.startSearching')}
						search
						value={settings.value as string}
						onInput={(e: ChangeEvent<HTMLInputElement>) => {
							handleFilterChange('Number', e.target.value);
						}}
					/>
				),
				client: (
					<Input
						data-test-id="client-search-client"
						placeholder={t('ui.table.startSearching')}
						search
						value={settings.value as string}
						onInput={(e: ChangeEvent<HTMLInputElement>) => {
							handleFilterChange('Client', e.target.value);
						}}
					/>
				),
				paymentSystemName: (
					<Input
						data-test-id="client-search-paymentSystemName"
						placeholder={t('ui.table.startSearching')}
						search
						value={settings.value as string}
						onInput={(e: ChangeEvent<HTMLInputElement>) => {
							handleFilterChange('PaymentSystem', e.target.value);
						}}
					/>
				),
				paymentSystem: (
					<Input
						data-test-id="client-search-paymentSystem"
						placeholder={t('ui.table.startSearching')}
						search
						value={settings.value as string}
						onInput={(e: ChangeEvent<HTMLInputElement>) => {
							handleFilterChange('PaymentSystem', e.target.value);
						}}
					/>
				),
				accountName: (
					<Input
						data-test-id="client-search-accountName"
						placeholder={t('ui.table.startSearching')}
						search
						value={settings.value as string}
						onInput={(e: ChangeEvent<HTMLInputElement>) => {
							handleFilterChange('AccountName', e.target.value);
						}}
					/>
				),
				wallet: (
					<Input
						data-test-id="client-search-wallet"
						placeholder={t('ui.table.startSearching')}
						search
						value={settings.value as string}
						onInput={(e: ChangeEvent<HTMLInputElement>) => {
							handleFilterChange('Wallet', e.target.value);
						}}
					/>
				),
				description: (
					<Input
						data-test-id="client-search-description"
						placeholder={t('ui.table.startSearching')}
						search
						value={settings.value as string}
						onInput={(e: ChangeEvent<HTMLInputElement>) => {
							handleFilterChange('Description', e.target.value);
						}}
					/>
				),
				fromWallet: (
					<Input
						data-test-id="client-search-fromWallet"
						placeholder={t('ui.table.startSearching')}
						search
						value={settings.value as string}
						onInput={(e: ChangeEvent<HTMLInputElement>) => {
							handleFilterChange('FromWallet', e.target.value);
						}}
					/>
				),
				toWallet: (
					<Input
						data-test-id="client-search-toWallet"
						placeholder={t('ui.table.startSearching')}
						search
						value={settings.value as string}
						onInput={(e: ChangeEvent<HTMLInputElement>) => {
							handleFilterChange('ToWallet', e.target.value);
						}}
					/>
				),
				priority: <RangeSetting testId="priority" onClose={handleClose} onConfirm={handleConfirmSettings} values={rangeValues} />,
				minExchangeReserveAmount: (
					<RangeSetting
						testId="minExchangeReserveAmount"
						onClose={handleClose}
						onConfirm={handleConfirmSettings}
						values={rangeValues}
					/>
				),
				maxExchangeReserveAmount: (
					<RangeSetting
						testId="maxExchangeReserveAmount"
						onClose={handleClose}
						onConfirm={handleConfirmSettings}
						values={rangeValues}
					/>
				),
				amountFrom: (
					<RangeSetting testId="amountFrom" onClose={handleClose} onConfirm={handleConfirmSettings} values={rangeValues} />
				),
				balance: <RangeSetting testId="balance" onClose={handleClose} onConfirm={handleConfirmSettings} values={rangeValues} />,
				amount: <RangeSetting testId="amount" onClose={handleClose} onConfirm={handleConfirmSettings} values={rangeValues} />,
				fee: <RangeSetting testId="fee" onClose={handleClose} onConfirm={handleConfirmSettings} values={rangeValues} />,
				feeIn: <RangeSetting testId="feeIn" onClose={handleClose} onConfirm={handleConfirmSettings} values={rangeValues} />,
				netAmount: <RangeSetting testId="netAmount" onClose={handleClose} onConfirm={handleConfirmSettings} values={rangeValues} />,
				balanceAmount: (
					<RangeSetting testId="balanceAmount" onClose={handleClose} onConfirm={handleConfirmSettings} values={rangeValues} />
				),
				exchangeAmount: (
					<RangeSetting testId="exchangeAmount" onClose={handleClose} onConfirm={handleConfirmSettings} values={rangeValues} />
				),
				totalAmount: (
					<RangeSetting testId="totalAmount" onClose={handleClose} onConfirm={handleConfirmSettings} values={rangeValues} />
				),
				holdAmount: (
					<RangeSetting testId="holdAmount" onClose={handleClose} onConfirm={handleConfirmSettings} values={rangeValues} />
				),
				actualAmount: (
					<RangeSetting testId="actualAmount" onClose={handleClose} onConfirm={handleConfirmSettings} values={rangeValues} />
				),
				expectedAmount: (
					<RangeSetting testId="expectedAmount" onClose={handleClose} onConfirm={handleConfirmSettings} values={rangeValues} />
				),
				currency: (
					<SelectSettings
						onClose={handleClose}
						onConfirm={handleConfirmSettings}
						selectedItems={selectedItems}
						setSelectedItems={setSelectedItems}
						settingKey="Currencies"
						options={settings.filterOptions}
					/>
				),
				fromCurrency: (
					<SelectSettings
						onClose={handleClose}
						onConfirm={handleConfirmSettings}
						selectedItems={selectedItems}
						setSelectedItems={setSelectedItems}
						settingKey="FromCurrencies"
						options={settings.filterOptions}
					/>
				),
				splitWithdrawalsAllowed: (
					<SelectSettings
						onClose={handleClose}
						onConfirm={handleConfirmSettings}
						selectedItems={selectedItems}
						setSelectedItems={setSelectedItems}
						settingKey="SplitWithdrawalsAllowed"
						options={settings.filterOptions}
					/>
				),
				overdraftAmount: (
					<RangeSetting testId="overdraftAmount" onClose={handleClose} onConfirm={handleConfirmSettings} values={rangeValues} />
				),
				processedAt: <RangeDateTimeSetting onClose={handleClose} onConfirm={handleConfirmSettings} values={rangeValues} />,
				createdAt: <RangeDateTimeSetting onClose={handleClose} onConfirm={handleConfirmSettings} values={rangeValues} />,
			};
			return fieldSettings[field];
		},
		[handleClose, handleConfirmSettings, selectedItems, settings.filterOptions, settings.value, t, rangeValues, handleFilterChange],
	);

	useCustomEventListener(customEventEmitter.events.SCROLL_TABLE, handleTableScroll);

	useEffect(() => {
		document.addEventListener('click', handleClickOut);
		return () => {
			document.removeEventListener('click', handleClickOut);
		};
	}, [handleClickOut]);

	useEffect(() => {
		setSelectedItems(initialSelected);
	}, [initialSelected]);

	return (
		<div className="relative">
			<div className="uppercase text-xs font-medium flex items-center w-full text-gray-500">
				{!settings.isFiltersHidden && (
					<div
						data-test-id={`client-header-toggle-${field}`}
						ref={expandBtnRef}
						onClick={toggleExpanded}
						className="cursor-pointer flex justify-center items-center min-w-[16px] w-4 h-4 bg-gray-200 hover:bg-gray-300 duration-100 rounded-sm mr-1">
						<ArrowIcon className={clsx('max-w-[8px]', expanded && 'rotate-180')} />
					</div>
				)}

				<span>{props.displayName}</span>
				{/* {isTitle && ( */}
				{/*	<div className="cursor-pointer ml-1.5" ref={tooltipRef} onMouseOver={showTooltip} onMouseOut={hideTooltip}> */}
				{/*		<InfoIcon /> */}
				{/*		{tooltipCoordinates && ( */}
				{/*			<PortalModal middle coordinates={tooltipCoordinates} show={visibleTooltip}> */}
				{/*				<div */}
				{/*					role="tooltip" */}
				{/*					data-content={true} */}
				{/*					className="text-dark-700 text-xs whitespace-nowrap z-20 left-full  transition duration-150 ease-in-out bg-white rounded p-1 border border-dark-900"> */}
				{/*					Тултип название */}
				{/*				</div> */}
				{/*			</PortalModal> */}
				{/*		)} */}
				{/*	</div> */}
				{/* )} */}

				{!settings.isSortingHidden && (
					<div
						data-test-id={`client-header-sort-${field}`}
						className={clsx(
							'cursor-pointer flex justify-center items-center w-4 h-4 hover:bg-gray-300 duration-100 rounded-sm ml-2',
						)}
						onClick={handleChangeOrder}>
						{(settings.orderKey ?? field) === settings.orderBy ? (
							<DoubleArrowActiveIcon className={clsx(settings.orderDir === Order.DESC && 'rotate-180')} />
						) : (
							<DoubleArrowIcon className="max-w-[8px]" />
						)}
					</div>
				)}
			</div>
			{expandCoordinates && (
				<PortalModal coordinates={expandCoordinates} show={expanded}>
					<div ref={portalBodyRef}>
						<div className="shadow-lg border border-gray-200 rounded-md bg-white px-3 py-2 min-w-64 max-w-[300px]">
							{renderSettings(field)}
						</div>
					</div>
				</PortalModal>
			)}
		</div>
	);
};

export default HeaderComponent;
