import React, {
	type FC,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react';
import clsx from 'clsx';
import QRCode from 'react-qr-code';
import { useTranslation } from 'react-i18next';
import { Modal } from 'components';
import { AuthStep, type ITwoStepData } from '../types';
import { ReactComponent as CheckIcon } from 'assets/icons/white-check.svg';
import { ReactComponent as AutentificatorIcon } from 'assets/icons/autentificator.svg';
import { ReactComponent as CopyIcon } from 'assets/icons/copy-white.svg';
import { ReactComponent as GenerateIcon } from 'assets/icons/generate.svg';

import type { ITab, Nullable } from 'shared/types';
import { Button } from 'components/UI';
import { toast } from 'react-toastify';
import SettingsService from '../service';
import { setHas2fa } from '../../app/store/reducer';
import { useAppDispatch } from '../../../shared/hooks';
import { customEventEmitter } from '../../../shared/utils';

interface IProps {
	enabled: boolean;
	onClose: () => void;
	onConfirm: () => void;
}
const TwoStepAuthModal: FC<IProps> = ({ onClose, onConfirm, enabled }) => {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();

	const [activeStep, setActiveStep] = useState<AuthStep>(AuthStep.REGISTER);
	const [countdown, setСountdown] = useState<number>(20);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [twoStepData, setTwoStepData] = useState<Nullable<ITwoStepData>>(null);
	const [codes, setCodes] = useState<string[]>([]);
	const steps = useMemo<ITab[]>(
		() => [
			{
				id: 1,
				value: AuthStep.REGISTER as unknown as string,
				text: t('modules.settings.twoStep.register'),
			},
			{
				id: 2,
				value: AuthStep.VERIFICATION as unknown as string,
				text: t('modules.settings.twoStep.verification'),
			},
			{
				id: 3,
				value: AuthStep.COPY as unknown as string,
				text: t('modules.settings.twoStep.copy'),
			},
		],
		[t],
	);

	const handleCancel = useCallback(() => {
		setActiveStep(AuthStep.REGISTER);
		setСountdown(1);
		onClose();
		if (activeStep === AuthStep.COPY) {
			void SettingsService.getTwoStepData(false);
			dispatch(setHas2fa(false));
			customEventEmitter.trigger(customEventEmitter.events.UPDATE_TOKENS);
		}
	}, [onClose, activeStep, dispatch]);

	const submitOtpCode = useCallback(async () => {
		setIsLoading(true);
		let otp = '';
		const inputs = document.querySelectorAll<HTMLInputElement>('#otp *[id]');
		inputs.forEach(input => {
			otp += input.value;
		});

		if (otp.length !== 6) {
			const inputs = document.querySelectorAll<HTMLInputElement>('#otp *[id]');
			const emptyInput = [...inputs].find(input => !input.value);
			if (emptyInput) emptyInput.focus();
			setIsLoading(false);
			return;
		}

		try {
			const { recoveryCodes } = await SettingsService.confirmTwoStep(otp);
			setCodes(recoveryCodes);
			setActiveStep(AuthStep.COPY);
			dispatch(setHas2fa(true));
		} finally {
			setIsLoading(false);
		}
	}, [dispatch]);

	const handleConfirm = useCallback(async () => {
		switch (activeStep) {
			case AuthStep.REGISTER:
				setActiveStep(AuthStep.VERIFICATION);
				break;
			case AuthStep.VERIFICATION:
				setСountdown(20);
				await submitOtpCode();
				break;
			case AuthStep.COPY:
				setActiveStep(AuthStep.REGISTER);
				setСountdown(0);
				onConfirm();
				break;
			default:
		}
	}, [activeStep, onConfirm, submitOtpCode]);

	const OTPInput = (): void => {
		if (activeStep !== AuthStep.VERIFICATION) return;
		const inputs = document.querySelectorAll('#otp *[id]');
		for (let i = 0; i < inputs.length; i++) {
			inputs[i].addEventListener('paste', event => {
				const paste = (event as ClipboardEvent).clipboardData?.getData('text');
				if (!paste) return;
				if (Number.isInteger(Number(paste))) {
					event.stopPropagation();
					event.preventDefault();
					for (let j = 0; j < paste.length; j++) {
						if (!inputs[j]) return;
						(inputs[j] as HTMLInputElement).value = paste[j];
					}
				}
			});
			inputs[i].addEventListener('keydown', function (e: Event) {
				const event = e as KeyboardEvent;
				if (event.ctrlKey) return;
				if (event.key === 'Backspace') {
					(inputs[i] as HTMLInputElement).value = '';
					if (i !== 0) (inputs[i - 1] as HTMLInputElement).focus();
				} else {
					if (
						i === inputs.length - 1 &&
						(inputs[i] as HTMLInputElement).value !== ''
					) {
						return true;
					}

					if (event.keyCode > 47 && event.keyCode < 58) {
						(inputs[i] as HTMLInputElement).value = event.key;
						if (i !== inputs.length - 1)
							(inputs[i + 1] as HTMLInputElement).focus();
						event.preventDefault();
					} else if (event.keyCode > 64 && event.keyCode < 91) {
						(inputs[i] as HTMLInputElement).value = String.fromCharCode(
							event.keyCode,
						);
						if (i !== inputs.length - 1)
							(inputs[i + 1] as HTMLInputElement).focus();
						event.preventDefault();
					}

					if (!Number.isInteger(Number((e.target as HTMLInputElement).value))) {
						(e.target as HTMLInputElement).value = '';
						(e.target as HTMLInputElement).focus();
					}
				}
			});
		}
	};

	const handleCopy = useCallback(() => {
		void navigator.clipboard.writeText(codes.join('\r\n'));
		toast.success(t('ui.copied'), {
			position: 'top-right',
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: 0,
			theme: 'light',
		});
		setСountdown(1);
	}, [t, codes]);

	const setupCountDown = useCallback(() => {
		const interval = setInterval(() => {
			if (activeStep !== AuthStep.COPY) {
				clearInterval(interval);
				return;
			}

			setСountdown(prev => {
				if (prev === 1) clearInterval(interval);
				return prev - 1;
			});
		}, 1000);
	}, [activeStep]);

	const handleGenerate = useCallback(async () => {
		const { recoveryCodes } = await SettingsService.getRecoveryCodes();
		setCodes(recoveryCodes);
		setСountdown(20);
		if (countdown === 0) setupCountDown();
	}, [setupCountDown, countdown]);

	useEffect(() => {
		setIsLoading(true);
		void SettingsService.getTwoStepData(true)
			.then(setTwoStepData)
			.finally(() => {
				setIsLoading(false);
			});
	}, []);

	useEffect(OTPInput, [activeStep]);
	useEffect(setupCountDown, [setupCountDown]);

	return (
		<Modal show onClose={handleCancel}>
			<div className="w-[875px]">
				<h2 className="text-2xl leading-7 font-bold text-gray-900 text-center mb-2">
					{t('modules.settings.twoStep.title')}
				</h2>
				<div className="px-6 py-4 flex items-center justify-between mb-2">
					{steps.map(step => (
						<div className="flex items-center" key={step.id}>
							<div
								className={clsx(
									'w-10 h-10 rounded-full border border-2 border-gray-300 flex justify-center items-center mr-4',
									activeStep === step.id && 'border-main',
									activeStep > step.id && 'border-main bg-main',
								)}>
								<span
									className={clsx(
										'text-sm font-medium text-gray-500',
										activeStep === step.id && 'text-main',
										activeStep > step.id && 'hidden',
									)}>
									0{step.id}
								</span>
								{activeStep > step.id && <CheckIcon />}
							</div>
							<div
								className={clsx(
									'max-w-[170px] text-sm font-medium text-gray-500',
									activeStep === step.id && 'text-main',
									activeStep > step.id && 'text-gray-900',
								)}>
								{step.text}
							</div>
						</div>
					))}
				</div>
				{activeStep === 1 && (
					<div className="px-6 mb-6 px-4">
						<p className="text-sm leading-5 font-normal text-gray-500 mb-2">
							{t('modules.settings.twoStep.scanQR')}
						</p>
						<div className="flex justify-center mb-2 p-4">
							{twoStepData?.qrCodeUri && (
								<QRCode
									value={twoStepData.qrCodeUri}
									width={224}
									height={224}
								/>
							)}
						</div>
						<p className="mb-2 text-gray-900 text-sm">
							{t('modules.settings.twoStep.typeCode')}
						</p>
						<p
							className="mb-2 text-gray-900 text-sm font-bold"
							style={{ wordSpacing: 8 }}>
							{twoStepData?.sharedKey}
						</p>
						<p className="text-sm leading-5 font-normal text-gray-500">
							{t('modules.settings.twoStep.seeCode')}
						</p>
					</div>
				)}
				{activeStep === 2 && (
					<div>
						<div className="flex justify-center items-center mb-8">
							<AutentificatorIcon className="mr-4" />
							<p className="max-w-[328px] text-sm text-gray-900">
								{t('modules.settings.twoStep.use')}{' '}
								<span className="font-bold">Authenticator</span>{' '}
								{t('modules.settings.twoStep.forVerify')}
							</p>
						</div>
						<p className="max-w-[377px] mx-auto text-sm leading-5 font-medium text-gray-700 mb-1">
							{t('modules.settings.twoStep.type')}
						</p>
						<div
							id="otp"
							className="flex flex-row items-center justify-center w-full mb-6 space-x-4">
							<div className="h-[42px] w-[50px]">
								<input
									className="w-full h-full flex flex-col items-center justify-center text-center px-2 outline-none rounded-xl border border-gray-200 text-lg bg-white focus:bg-gray-50 focus:ring-1 ring-blue-700"
									type="text"
									name=""
									id="1"
									maxLength={1}
								/>
							</div>
							<div className="h-[42px] w-[50px]">
								<input
									className="w-full h-full flex flex-col items-center justify-center text-center px-2 outline-none rounded-xl border border-gray-200 text-lg bg-white focus:bg-gray-50 focus:ring-1 ring-blue-700"
									type="text"
									name=""
									id="2"
									maxLength={1}
								/>
							</div>
							<div className="h-[42px] w-[50px]">
								<input
									className="w-full h-full flex flex-col items-center justify-center text-center px-2 outline-none rounded-xl border border-gray-200 text-lg bg-white focus:bg-gray-50 focus:ring-1 ring-blue-700"
									type="text"
									name=""
									id="3"
									maxLength={1}
								/>
							</div>
							<div className="h-[42px] w-[50px]">
								<input
									className="w-full h-full flex flex-col items-center justify-center text-center px-2 outline-none rounded-xl border border-gray-200 text-lg bg-white focus:bg-gray-50 focus:ring-1 ring-blue-700"
									type="text"
									name=""
									id="4"
									maxLength={1}
								/>
							</div>
							<div className="h-[42px] w-[50px]">
								<input
									className="w-full h-full flex flex-col items-center justify-center text-center px-2 outline-none rounded-xl border border-gray-200 text-lg bg-white focus:bg-gray-50 focus:ring-1 ring-blue-700"
									type="text"
									name=""
									id="5"
									maxLength={1}
								/>
							</div>
							<div className="h-[42px] w-[50px]">
								<input
									className="w-full h-full flex flex-col items-center justify-center text-center px-2 outline-none rounded-xl border border-gray-200 text-lg bg-white focus:bg-gray-50 focus:ring-1 ring-blue-700"
									type="text"
									name=""
									id="6"
									maxLength={1}
								/>
							</div>
						</div>
					</div>
				)}
				{activeStep === 3 && (
					<div className="px-4">
						<h4 className="text-gray-900 text-xl mb-2">
							{t('modules.settings.twoStep.backupCodes')}
						</h4>
						<p className="text-sm leading-5 font-normal text-gray-500 mb-2">
							{t('modules.settings.twoStep.backupDescription')}
						</p>
						<p className="text-sm leading-5 font-normal text-gray-500 mb-2">
							{t('modules.settings.twoStep.backupSave')}
						</p>
						<div className="flex flex-wrap max-w-[650px] mb-6">
							{codes.map((code, idx) => (
								<div
									key={`${code} ${idx}`}
									className="text-gray-700 text-sm font-bold p-2 bg-gray-100 rounded mr-2 mb-2">
									{code}
								</div>
							))}
						</div>
						<div className="flex justify-between mb-6">
							<div className="flex">
								<Button
									className="mr-3 border-main"
									data-test-id="client-settings-2step-copy"
									variant="create"
									onClick={handleCopy}>
									<div className="flex items-center text-sm leading-5 font-medium">
										<CopyIcon className="mr-2" />{' '}
										<span>{t('ui.buttons.copy')}</span>
									</div>
								</Button>
								<Button
									className="bg-white border-main hover:bg-gray-100"
									data-test-id="client-settings-2step-generate"
									variant="create"
									onClick={handleGenerate}>
									<div className="flex items-center text-sm leading-5 font-medium text-gray-700">
										<GenerateIcon fill="#111827" className="mr-2" />{' '}
										<span>{t('modules.settings.twoStep.generate')}</span>
									</div>
								</Button>
							</div>
						</div>
					</div>
				)}

				<div className="flex justify-end items-center py-3 -mb-6 px-6 -mx-6 bg-gray-50 rounded-b-lg">
					<Button
						className="mr-3"
						data-test-id="client-settings-2step-cancel"
						variant="secondary"
						onClick={handleCancel}>
						{t('ui.buttons.cancel')}
					</Button>
					<Button
						disabled={
							(activeStep === AuthStep.COPY && Boolean(countdown)) || isLoading
						}
						data-test-id="client-settings-2step-confirm"
						variant="create"
						onClick={handleConfirm}>
						{countdown && activeStep === 3 ? (
							<>
								{t('modules.settings.twoStep.continue')}&nbsp;
								{countdown}
								{t('modules.settings.twoStep.seconds')}
							</>
						) : (
							<>{t('ui.buttons.continue')}</>
						)}
					</Button>
				</div>
			</div>
		</Modal>
	);
};

export default TwoStepAuthModal;
