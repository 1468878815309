import { combineReducers } from '@reduxjs/toolkit';
import { appReducer } from 'modules/app';
import { AccountsReducer } from 'modules/accounts';
import { operationsReducer } from 'modules/operations';
import { settingsReducer } from 'modules/settings';
import { withdrawalsReducer } from 'modules/withdrawals';

const rootReducer = combineReducers({
	app: appReducer,
	accounts: AccountsReducer,
	operations: operationsReducer,
	settings: settingsReducer,
	withdrawals: withdrawalsReducer,
});

export default rootReducer;
