import React, { type FC } from 'react';
import { ReactComponent as LogoIcon } from 'assets/icons/logo_white.svg';
const Footer: FC = () => {
	return (
		<div className="bg-indigo-800 py-12 px-[60px] flex items-center justify-between">
			<LogoIcon />
			<span className="text-white text-base font-normal">Obmenka.ua - Acquiring © {new Date().getFullYear()}</span>
		</div>
	);
};

export default Footer;
