import { createAsyncThunk } from '@reduxjs/toolkit';
import type { ITableSettings } from 'shared/types';
import { mockEmployee, mockEmployees } from '../constants';
import { setSelectedMerchantEmployee, setSelectedMerchantUsersRows, setSelectedMerchantUsersTotalPages } from './reducer';

const LOAD_EMPOYEES = 'employees/load-one';

const loadEmployeesAction = createAsyncThunk(LOAD_EMPOYEES, async (settings: ITableSettings, { rejectWithValue, dispatch }) => {
	try {
		const {
			content,
			itemsCount,
			paging: { pageSize },
		} = mockEmployees;

		dispatch(setSelectedMerchantUsersRows(content));
		dispatch(setSelectedMerchantUsersTotalPages(Math.ceil(itemsCount / pageSize) || 1));
	} catch (e) {
		return rejectWithValue(e);
	}
});

const loadEmployeeAction = createAsyncThunk(LOAD_EMPOYEES, async (id: string, { rejectWithValue, dispatch }) => {
	try {
		const employee = mockEmployee;
		dispatch(setSelectedMerchantEmployee(employee));
	} catch (e) {
		return rejectWithValue(e);
	}
});

export { loadEmployeesAction, loadEmployeeAction };
