import React, { type FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';

import { PageHeader } from 'components';
import { type Crumb } from 'components/PageHeader/types';
import routes from 'shared/constants/routes';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { AccountsTab } from './types';
import BasicDataTab from './components/BasicDataTab';
import WalletsTab from './components/WalletsTab';
import ConnectionTab from './components/ConnectionTab';
import { useAccountTabs } from './hooks/useAccountTabs';
import AccountService from './service';
import { setSelectedId, setSelectedMainData } from './store/reducer';

const Account: FC = () => {
	const { t } = useTranslation();
	const { id, tab } = useParams();
	const { pathname } = useLocation();

	const { tabs, activeTab, handleTabClick } = useAccountTabs({ id, tab });

	const { mainData } = useAppSelector(state => state.accounts.selectedAccount);
	const dispatch = useAppDispatch();
	const isCreating = useMemo<boolean>(() => pathname.includes('create'), [pathname]);
	const crumbs = useMemo<Crumb[]>(
		() => [
			{ name: t('modules.accounts.clientAccounts'), link: routes.accounts.index },
			{ name: mainData?.title ?? t('modules.accounts.create') },
		],
		[t, mainData],
	);

	useEffect(() => {
		if (!id || isCreating) return;
		void AccountService.getAccountDetails(id).then(data => dispatch(setSelectedMainData(data)));
		dispatch(setSelectedId(id));
	}, [id, isCreating]);

	useEffect(
		() => () => {
			dispatch(setSelectedId(null));
			dispatch(setSelectedMainData(null));
		},
		[],
	);

	return (
		<div className="pb-10">
			<PageHeader
				previousPage={routes.accounts.index}
				activeTab={activeTab}
				onTabClick={handleTabClick}
				crumbs={crumbs}
				tabs={tabs}
				title={t('modules.clientDetails.title')}
				className="mb-6 relative z-10"
				hideTabs={isCreating}
			/>
			{activeTab.value === AccountsTab.BASIC_DATA && <BasicDataTab isCreating={isCreating} />}
			{activeTab.value === AccountsTab.WALLETS && <WalletsTab />}
			{activeTab.value === AccountsTab.CONNECTION && <ConnectionTab />}
		</div>
	);
};

export default Account;
