import React, { type FC, type ReactNode, useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import clsx from 'clsx';

const modalRoot = document.getElementById('portal-root');

interface IProps {
	coordinates: DOMRect;
	children: ReactNode;
	show: boolean;
	middle?: boolean;
}

const PortalModal: FC<IProps> = ({ children, coordinates, middle, show }) => {
	const modalRef = useRef<HTMLDivElement>(null);
	const [modalWidth, setModalWidth] = useState<number>(0);

	const alignLeft = useCallback(
		(left: number): boolean => {
			const screenWidth = document.body.offsetWidth - 64; // - paddings
			return screenWidth - left >= modalWidth;
		},
		[modalWidth],
	);

	const top = useMemo<string>(() => {
		let { top } = coordinates;
		const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
		if (middle) top -= 30;
		else top += scrollTop + 27;
		return `${top}px`;
	}, [coordinates, middle]);

	const left = useMemo<string>(() => {
		let { left } = coordinates;
		if (!alignLeft(coordinates.left) && !middle) left -= modalWidth - 16;
		return `${left}px`;
	}, [alignLeft, coordinates, middle, modalWidth]);

	useLayoutEffect(() => {
		if (!modalRef.current) return;
		setModalWidth(modalRef.current.clientWidth);
	}, [show]);

	if (!modalRoot) return <></>;

	return createPortal(
		<div
			className={clsx('absolute bg-white w-fit z-50 shadow-portal', !show && 'hidden', middle && '-translate-x-2/4')}
			style={{ top, left }}
			ref={modalRef}>
			{children}
		</div>,
		modalRoot,
	);
};

export default PortalModal;
