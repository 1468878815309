import React, { type FC, useEffect } from 'react';
import { useAuth } from './hooks/useAuth';
import { FullscreenLoader } from 'components/UI';

const RenewToken: FC = () => {
	const { renewCallback } = useAuth();

	useEffect(() => {
		void renewCallback();
		// eslint-disable-next-line
    }, []);

	return <FullscreenLoader />;
};

export default RenewToken;
