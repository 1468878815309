import { Order } from 'shared/types';

export const initialSelectedAccount = {
	id: null,
	mainData: null,
	wallets: {
		availableIntegrations: [],
		columns: [],
		rows: [],
		settings: {
			Keyword: '',
			OrderBy: '',
			OrderDir: Order.EMPTY,
			PageNumber: 1,
			PageSize: 25,
		},
		totalPages: 1,
		isLoading: false,
		itemsCount: null,
	},
	connectionData: {
		paymentWayType: '',
		productSecret: '',
		testSecret: '',
		ipAddresses: [''],
	},
};
