import React, { type FC } from 'react';
import { type IServiceCard } from '../types';
import { useTranslation } from 'react-i18next';

const ServiceCard: FC<IServiceCard> = ({ title, description, link, icon }) => {
	const { t } = useTranslation();

	return (
		<div className="bg-white p-6 pt-12 border border-gray-200 rounded-lg relative flex flex-col justify-between">
			<div className="mb-3">
				<h5 className="mb-3 text-lg leading-7 font-medium text-gray-900 text-center">{title}</h5>
				<p className="text-base leading-6 text-gray-500 text-center">{description}</p>
			</div>
			<a href={link} className="text-main text-sm leading-5 font-medium underline block text-center">
				{t('modules.home.services.more')}
			</a>
			<div className="absolute start-2/4 -translate-x-2/4 -top-[26px]">{icon}</div>
		</div>
	);
};

export default ServiceCard;
