import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import routes from 'shared/constants/routes';
import { type ITab } from 'shared/types';
import { AccountsTab } from '../types';
import { useAppDispatch, useAppSelector } from '../../../shared/hooks';
import { addHistoryRecord } from '../../app';

type ReturnType = {
	tabs: ITab[];
	activeTab: ITab;
	handleTabClick: (tab: ITab) => void;
};
export const useAccountTabs = ({ id, tab }: { id?: string; tab?: string }): ReturnType => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const { t } = useTranslation();
	const { pathname } = useLocation();
	const { has2fa } = useAppSelector(state => state.app);

	const isCreating = useMemo<boolean>(() => pathname.includes('create'), [pathname]);

	const openConnections = useCallback(() => {
		if (!id) return;
		dispatch(addHistoryRecord(window.location.pathname));
		navigate(routes.accounts.createWalletConnection(id));
	}, [dispatch, id, navigate]);

	const tabs = useMemo<ITab[]>(
		() => [
			{
				id: 1,
				value: AccountsTab.BASIC_DATA,
				text: t('ui.tabs.basicData'),
			},
			{
				id: 2,
				value: AccountsTab.WALLETS,
				text: t('ui.tabs.wallets'),
				action: {
					name: t('modules.wallets.connect'),
					onClick: openConnections,
					disabled: isCreating,
					disabledVia2fa: !has2fa,
				},
			},
			{
				id: 3,
				value: AccountsTab.CONNECTION,
				text: t('ui.tabs.connection'),
				disabledVia2fa: !has2fa,
			},
		],
		[t, openConnections, isCreating, has2fa],
	);

	const activeTab = useMemo<ITab>(() => {
		return tabs.find(tb => tb.value === tab) ?? tabs[0];
	}, [tab, tabs]);

	const handleTabClick = useCallback(
		(tab: ITab) => {
			if (Boolean(tab.disabledVia2fa) || !id || tab.value === activeTab.value) return;
			navigate(routes.accounts.details(id, tab.value));
		},
		[id, navigate, activeTab],
	);

	return { tabs, activeTab, handleTabClick };
};
