import React, { type FC } from 'react';
import clsx from 'clsx';
import { type SizeConfig, type Size } from './types';
import styles from './Spinner.module.scss';

const sizeConfig: SizeConfig = {
	lg: 'w-20 h-20',
	md: 'w-9 h-9',
	sm: 'w-7 h-7',
	xs: 'w-4 h-4',
};

interface IProps {
	size?: Size;
	color?: string;
}

const Spinner: FC<IProps> = ({ size = 'md', color }) => (
	<span style={{ borderColor: color }} className={clsx(styles.loader, sizeConfig[size], 'animate-spin')} />
);

export default Spinner;
