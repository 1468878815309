import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { type ColDef } from 'ag-grid-community';
import { type ITableSettings, type Nullable, type UpdateTableData } from 'shared/types';
import { type IOperation, type IOperationDetails } from '../types';
import { initialSettings } from '../constants';
import { loadOperationsAction } from './actions';

interface IOperationState {
	operations: IOperation[];
	totalPages: number;
	settings: ITableSettings;
	isLoading: boolean;
	columns: Array<ColDef<IOperation>>;
	selectedOperation: {
		id: Nullable<string>;
		mainData: Nullable<IOperationDetails>;
	};
	itemsCount: Nullable<number>;
	maxRecordsForReport: Nullable<number>;
}
const initialState: IOperationState = {
	operations: [],
	totalPages: 1,
	settings: initialSettings,
	isLoading: false,
	columns: [],
	itemsCount: null,
	maxRecordsForReport: null,
	selectedOperation: {
		id: null,
		mainData: null,
	},
};
export const operationSlice = createSlice({
	name: 'operation',
	initialState,
	reducers: {
		setOperations(state, { payload }: PayloadAction<IOperation[]>) {
			state.operations = payload;
		},
		setColumns(state, { payload }: PayloadAction<Array<ColDef<IOperation>>>) {
			state.columns = payload;
		},
		setTotalPages(state, { payload }: PayloadAction<number>) {
			state.totalPages = payload;
		},
		setTotalCount(state, { payload }: PayloadAction<number>) {
			state.itemsCount = payload;
		},
		setMaxItems(state, { payload }: PayloadAction<number>) {
			state.maxRecordsForReport = payload;
		},
		updateSettings(state, { payload }: PayloadAction<UpdateTableData>) {
			state.settings[payload.key] = payload.value as never;
		},
		setSelectedOperationId(state, { payload }: PayloadAction<string>) {
			state.selectedOperation.id = payload;
		},
		setSelectedOperationData(state, { payload }: PayloadAction<IOperationDetails>) {
			state.selectedOperation.mainData = payload;
		},
	},
	extraReducers: builder => {
		builder.addCase(loadOperationsAction.pending, state => {
			state.isLoading = true;
		});
		builder.addCase(loadOperationsAction.fulfilled, state => {
			state.isLoading = false;
		});
		builder.addCase(loadOperationsAction.rejected, state => {
			state.isLoading = false;
		});
	},
});

export const {
	setOperations,
	setColumns,
	setTotalPages,
	updateSettings,
	setSelectedOperationId,
	setSelectedOperationData,
	setTotalCount,
	setMaxItems,
} = operationSlice.actions;

export default operationSlice.reducer;
