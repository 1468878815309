import { useMemo } from 'react';
import { type ColDef } from 'ag-grid-community';
import { useTranslation } from 'react-i18next';
import { HeaderComponent, TextRenderer, EnumRenderer } from 'components/Table/components';
import { type ITransaction } from '../types';

export const useTransactionCols = (): Array<ColDef<ITransaction>> => {
	const { t } = useTranslation();

	return useMemo<Array<ColDef<ITransaction>>>(() => {
		return [
			{
				field: 'id',
				minWidth: 340,
				cellRenderer: TextRenderer,
				headerName: t('modules.withdrawals.fields.id'),
				headerComponent: HeaderComponent,
				headerClass: 'bg-gray-50 border-b border-b-gray-200',
				suppressMovable: true,
				cellEditor: {
					isFiltersHidden: true,
					isSortingHidden: true,
				},
			},
			{
				field: 'status',
				minWidth: 200,
				cellRenderer: EnumRenderer,
				headerName: t('modules.withdrawals.fields.status'),
				headerComponent: HeaderComponent,
				headerClass: 'bg-gray-50 border-b border-b-gray-200',
				suppressMovable: true,
				refData: {
					enumkey: 'TransactionStatus',
				},
				cellEditor: {
					isFiltersHidden: true,
					isSortingHidden: true,
				},
			},
			{
				field: 'type',
				minWidth: 180,
				cellRenderer: EnumRenderer,
				headerName: t('modules.withdrawals.fields.type'),
				headerComponent: HeaderComponent,
				headerClass: 'bg-gray-50 border-b border-b-gray-200',
				suppressMovable: true,
				refData: {
					enumkey: 'WithdrawalType',
				},
				cellEditor: {
					isFiltersHidden: true,
					isSortingHidden: true,
				},
			},
			{
				field: 'date',
				minWidth: 200,
				cellRenderer: TextRenderer,
				headerName: t('modules.withdrawals.fields.date'),
				headerComponent: HeaderComponent,
				headerClass: 'bg-gray-50 border-b border-b-gray-200',
				suppressMovable: true,
				cellEditor: {
					isFiltersHidden: true,
					isSortingHidden: true,
				},
			},
			{
				field: 'fromWallet',
				minWidth: 200,
				cellRenderer: TextRenderer,
				headerName: t('modules.withdrawals.fields.walletFrom'),
				headerComponent: HeaderComponent,
				headerClass: 'bg-gray-50 border-b border-b-gray-200',
				suppressMovable: true,
				cellEditor: {
					isFiltersHidden: true,
					isSortingHidden: true,
				},
			},
			{
				field: 'toWallet',
				minWidth: 200,
				cellRenderer: TextRenderer,
				headerName: t('modules.withdrawals.fields.walletTo'),
				headerComponent: HeaderComponent,
				headerClass: 'bg-gray-50 border-b border-b-gray-200',
				suppressMovable: true,
				cellEditor: {
					isFiltersHidden: true,
					isSortingHidden: true,
				},
			},
			{
				field: 'currency',
				minWidth: 160,
				cellRenderer: TextRenderer,
				headerName: t('modules.withdrawals.fields.currency'),
				headerComponent: HeaderComponent,
				headerClass: 'bg-gray-50 border-b border-b-gray-200',
				suppressMovable: true,
				cellEditor: {
					isFiltersHidden: true,
					isSortingHidden: true,
				},
			},
			{
				field: 'amount',
				minWidth: 220,
				cellRenderer: TextRenderer,
				headerName: t('modules.withdrawals.fields.amount'),
				headerComponent: HeaderComponent,
				headerClass: 'bg-gray-50 border-b border-b-gray-200',
				suppressMovable: true,
				cellEditor: {
					isFiltersHidden: true,
					isSortingHidden: true,
				},
			},
			{
				field: 'fee',
				minWidth: 220,
				cellRenderer: TextRenderer,
				headerName: t('modules.withdrawals.fields.fee'),
				headerComponent: HeaderComponent,
				headerClass: 'bg-gray-50 border-b border-b-gray-200',
				suppressMovable: true,
				cellEditor: {
					isFiltersHidden: true,
					isSortingHidden: true,
				},
			},

			{
				field: 'amountFrom',
				minWidth: 235,
				cellRenderer: TextRenderer,
				headerName: t('modules.withdrawals.fields.netAmount'),
				headerComponent: HeaderComponent,
				headerClass: 'bg-gray-50 border-b border-b-gray-200',
				suppressMovable: true,
				cellEditor: {
					isFiltersHidden: true,
					isSortingHidden: true,
				},
			},
			{
				field: 'balance',
				minWidth: 180,
				cellRenderer: TextRenderer,
				headerName: t('modules.withdrawals.fields.balance'),
				headerComponent: HeaderComponent,
				headerClass: 'bg-gray-50 border-b border-b-gray-200',
				suppressMovable: true,
				cellEditor: {
					isFiltersHidden: true,
					isSortingHidden: true,
				},
			},
		];
	}, [t]);
};
