import { type IPaginateResponse, Order } from 'shared/types';
import { type IMerchantUser, type ISettingsData } from './types';

export const initSettings: ISettingsData = {
	title: '',
	businessWebsite: '',
	companyEmail: '',
	companyPhone: '',
	companyAddress: '',
	ipCheckEnabled: false,
	twoStepIdentificationEnabled: false,
};

export const mockEmployee: IMerchantUser = {
	id: '4897419890',
	email: 'test@gmail.com',
	userName: 'test',
	roleName: 'Admin',
	roleValue: 'Admin',
	phoneNumber: '+380000000000',
	createdAt: '2024-07-22T11:24:15.454121Z',
	updatedAt: '2024-07-22T11:24:15.454121Z',
	createdBy: 'admin@gmail.com',
	updatedBy: 'admin@gmail.com',
	status: 10,
	canCreateAccount: true,
	accounts: '',
	accountRights: [
		{
			title: 'Account 1',
			rights: {
				account: {
					canOpen: true,
					canEdit: false,
					canOpenBalance: true,
				},
				payment: {
					canOpen: true,
					canCreate: false,
				},
				affiliate: {
					canOpen: true,
				},
			},
		},
		{
			title: 'Account 2',
			rights: {
				account: {
					canOpen: true,
					canEdit: true,
					canOpenBalance: false,
				},
				payment: {
					canOpen: true,
					canCreate: false,
				},
				affiliate: {
					canOpen: false,
				},
			},
		},
	],
};

export const mockEmployees: IPaginateResponse<IMerchantUser> = {
	content: [mockEmployee],
	itemsCount: 2,
	ordering: {
		orderDir: Order.ASC,
		orderBy: 'id',
	},
	paging: {
		pageNumber: 1,
		pageSize: 25,
	},
};
