import React, { type FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { useAppSelector } from 'shared/hooks';
import routes from 'shared/constants/routes';
import { exponentialToNumber } from 'shared/helpers';
import { AccountsTab } from 'modules/accounts';
import { FormRow } from 'components/UI';

const BasicDataTab: FC = () => {
	const { t } = useTranslation();
	const data = useAppSelector(
		state => state.withdrawals.selectedWithdrawal.mainData,
	);
	const enums = useAppSelector(state => state.app.enums);

	const processedDate = useMemo<string>(() => {
		if (!data?.processedAt) return '';
		return dayjs(data.processedAt).format('HH:mm:ss, DD.MM.YYYY');
	}, [data]);

	const createdDate = useMemo<string>(() => {
		if (!data?.createdAt) return '';
		return dayjs(data.createdAt).format('HH:mm:ss, DD.MM.YYYY');
	}, [data]);

	const status = useMemo<string>(() => {
		if (!enums || !data) return '';
		return (
			Object.values(enums.OperationStatus.values).find(
				({ id }) => id === data.status,
			)?.value ?? ''
		);
	}, [enums, data]);

	const type = useMemo<string>(() => {
		if (!enums || !data) return '';
		return (
			Object.values(enums.OperationType.values).find(
				({ id }) => id === data.type,
			)?.value ?? ''
		);
	}, [enums, data]);

	if (!data) return <></>;

	return (
		<>
			<div>
				<h4 className="text-gray-900 text-lg font-medium min-h-[38px]">
					{t('modules.withdrawals.settings')}
				</h4>
				<div className="grid grid-cols-2 gap-x-12">
					<FormRow label={t('modules.withdrawals.fields.id')}>
						<div className="w-full flex items-center h-[38px] justify-end">
							<div className="text-sm text-gray-500">{data.id}</div>
						</div>
					</FormRow>
					<FormRow label={t('modules.withdrawals.fields.number')}>
						<div className="w-full flex items-center h-[38px] justify-end">
							<div className="text-sm text-gray-500">{data.number}</div>
						</div>
					</FormRow>
					<FormRow label={t('modules.withdrawals.fields.type')}>
						<div className="w-full flex items-center h-[38px] justify-end">
							<div className="text-sm text-gray-500">{type}</div>
						</div>
					</FormRow>
					<FormRow label={t('modules.withdrawals.fields.status')}>
						<div className="w-full flex items-center h-[38px] justify-end">
							<div className="text-sm text-gray-500">{status}</div>
						</div>
					</FormRow>
					<FormRow label={t('modules.withdrawals.fields.client')}>
						<div className="w-full flex items-center h-[38px] justify-end">
							<Link
								data-test-id="client-withdrawal-client"
								className="text-main font-medium underline text-sm"
								to={routes.accounts.details(
									data.accountId,
									AccountsTab.BASIC_DATA,
								)}>
								{data.accountName}
							</Link>
						</div>
					</FormRow>
					<FormRow label={t('modules.withdrawals.fields.description')}>
						<div className="w-full flex items-center h-[38px] justify-end">
							<div className="text-sm text-gray-500">{data.description}</div>
						</div>
					</FormRow>
					<FormRow label={t('modules.withdrawals.fields.created')}>
						<div className="w-full flex items-center h-[38px] justify-end">
							<div className="text-sm text-gray-500">{createdDate}</div>
						</div>
					</FormRow>
					<FormRow label={t('modules.withdrawals.fields.processed')}>
						<div className="w-full flex items-center h-[38px] justify-end">
							<div className="text-sm text-gray-500">{processedDate}</div>
						</div>
					</FormRow>
				</div>
			</div>
			<div>
				<h4 className="text-gray-900 text-lg font-medium min-h-[38px] mt-12">
					{t('modules.withdrawals.mainInfo')}
				</h4>
				<div className="grid grid-cols-2 gap-x-12">
					<FormRow label={t('modules.withdrawals.fields.walletFrom')}>
						<div className="w-full flex items-center h-[38px] justify-end">
							<div className="text-sm text-gray-500">{data.fromWallet}</div>
						</div>
					</FormRow>
					<FormRow label={t('modules.withdrawals.fields.walletTo')}>
						<div className="w-full flex items-center h-[38px] justify-end">
							<div className="text-sm text-gray-500">{data.toWallet}</div>
						</div>
					</FormRow>

					<FormRow label={t('modules.withdrawals.fields.currency')}>
						<div className="w-full flex items-center h-[38px] justify-end">
							<div className="text-sm text-gray-500">{data.currency}</div>
						</div>
					</FormRow>
					<FormRow label={t('modules.withdrawals.fields.amount')}>
						<div className="w-full flex items-center h-[38px] justify-end">
							<div className="text-sm text-gray-500">
								{exponentialToNumber(data.amount)}
							</div>
						</div>
					</FormRow>
					<FormRow label={t('modules.withdrawals.fields.fee')}>
						<div className="w-full flex items-center h-[38px] justify-end">
							<div className="text-sm text-gray-500">{data.fee}</div>
						</div>
					</FormRow>
					<FormRow label={t('modules.withdrawals.fields.netAmount')}>
						<div className="w-full flex items-center h-[38px] justify-end">
							<div className="text-sm text-gray-500">
								{exponentialToNumber(data.netAmount)}
							</div>
						</div>
					</FormRow>
					<FormRow label={t('modules.withdrawals.fields.balance')}>
						<div className="w-full flex items-center h-[38px] justify-end">
							<div className="text-sm text-gray-500">{data.balance}</div>
						</div>
					</FormRow>
				</div>
			</div>
		</>
	);
};

export default BasicDataTab;
