import { type ITableSettings } from '../types';

export const settingsToSearchParams = (settings: ITableSettings): string => {
	const query = Object.entries(settings).reduce((accum: string, [key, value]) => {
		if (value === null || value === undefined || value === '') return accum;
		if (Array.isArray(value)) {
			const values = value.map(val => `${key}=${val as unknown as string}`).join('&');
			return (accum += `${values}&`);
		}

		return (accum += `${key}=${encodeURIComponent(value as string)}&`);
	}, '');
	return query;
};
