import { config } from 'shared/constants/config';
import type { IPaymentSystemIcon } from './types';

export const CMSList: Array<{ id: number; icon: string }> = [
	{
		id: 1,
		icon: `${config.storageUrl}/images/cs_cart.png`,
	},
	{
		id: 2,
		icon: `${config.storageUrl}/images/wp.png`,
	},
	{
		id: 3,
		icon: `${config.storageUrl}/images/pesta.png`,
	},
	{
		id: 4,
		icon: `${config.storageUrl}/images/php_shop.png`,
	},
	{
		id: 5,
		icon: `${config.storageUrl}/images/shopos.png`,
	},
	{
		id: 6,
		icon: `${config.storageUrl}/images/webasyst.png`,
	},
];

export const iconList: IPaymentSystemIcon[] = [
	{ id: 1, icon: `${config.storageUrl}/images/icon_apple_pay.png` },
	{ id: 2, icon: `${config.storageUrl}/images/icon_master_card.png` },
	{ id: 3, icon: `${config.storageUrl}/images/icon_visa.png` },
	{ id: 4, icon: `${config.storageUrl}/images/icon_bitcoin.png` },
	{ id: 5, icon: `${config.storageUrl}/images/icon_bitcoin_cash.png` },
	{ id: 7, icon: `${config.storageUrl}/images/icon_etherum.png` },
	{ id: 8, icon: `${config.storageUrl}/images/icon_litecoin.png` },
	{ id: 9, icon: `${config.storageUrl}/images/icon_usdt.png` },
	{ id: 10, icon: `${config.storageUrl}/images/icon_monero_xmr.png` },
	{ id: 11, icon: `${config.storageUrl}/images/icon_tron.png` },
	{ id: 12, icon: `${config.storageUrl}/images/icon_usd_coin.png` },
];
