import React, { type FC, type HTMLAttributes, useEffect } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { type ITab } from 'shared/types';
import { Button, Tooltip } from 'components/UI';
import { ReactComponent as SlashIcon } from 'assets/icons/slash.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { type Crumb } from './types';
import { useTranslation } from 'react-i18next';

interface IProps extends HTMLAttributes<HTMLDivElement> {
	title: string;
	previousPage: string;
	crumbs: Crumb[];
	tabs: ITab[];
	activeTab: ITab;
	onTabClick: (tab: ITab) => void;
	hideTabs?: boolean;
}
const PageHeader: FC<IProps> = ({ title, previousPage, crumbs, tabs, activeTab, hideTabs, onTabClick, ...rest }) => {
	const { t } = useTranslation();

	useEffect(() => {
		const active = tabs.find(tab => tab.id === activeTab.id) ?? activeTab;
		onTabClick(active);
		// eslint-disable-next-line
	}, [tabs]);

	return (
		<div {...rest}>
			<div className="flex items-center mb-6">
				{crumbs.map((crumb, idx) => (
					<div key={crumb.name} className="font-medium text-sm flex items-center">
						{crumb.link ? (
							<Link data-test-id={`client-crumb-${crumb.link}`} to={crumb.link} className="text-main mr-5">
								{crumb.name}
							</Link>
						) : (
							<span className="text-gray-500 ml-5">{crumb.name}</span>
						)}
						{idx !== crumbs.length - 1 && <SlashIcon />}
					</div>
				))}
			</div>

			<div className="flex justify-between items-center mb-6 min-h-[38px]">
				<div className="flex items-center">
					<h2 className="text-gray-900 text-xl font-bold leading-8">{title}</h2>
				</div>
				{activeTab?.action && !activeTab?.action.disabled && (
					<Button
						data-test-id="client-header-action"
						variant="create"
						onClick={activeTab.action.onClick}
						disabled={Boolean(activeTab?.action.disabled) || Boolean(activeTab?.action.disabledVia2fa)}>
						<PlusIcon className="mr-3" />
						{activeTab.action.name}
						{activeTab?.action.disabledVia2fa && <Tooltip title={t('ui.tooltip.2fa.title')} text={t('ui.tooltip.2fa.text')} />}
					</Button>
				)}
			</div>

			{!hideTabs && (
				<div className="flex items-center mb-6 border-b border-b-gray-200">
					{tabs.map(tab => (
						<div
							data-test-id={`client-header-tab-${tab.id}`}
							key={tab.id}
							onClick={onTabClick.bind(null, tab)}
							className={clsx(
								'px-5 pb-4 cursor-pointer font-medium text-sm border-b-2 border-b-transparent translate-y-px duration-100 hover:border-b-gray-500',
								activeTab.id === tab.id ? 'text-main !border-b-main' : 'text-gray-500',
							)}>
							{tab.text}
							{tab?.disabledVia2fa && <Tooltip title={t('ui.tooltip.2fa.title')} text={t('ui.tooltip.2fa.text')} />}
						</div>
					))}
				</div>
			)}
		</div>
	);
};

export default PageHeader;
