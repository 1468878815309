import React, { forwardRef, type ForwardRefRenderFunction, type InputHTMLAttributes, useCallback, useState } from 'react';
import copy from 'assets/icons/copy.svg';
import generate from 'assets/icons/generate.svg';
import clsx from 'clsx';
import { type Nullable } from 'shared/types';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
	wrapperClassName?: string;
	onGenerate?: () => void;
	isError?: boolean;
	errorText?: string;
}
const CopyInput: ForwardRefRenderFunction<HTMLInputElement, IProps> = (
	{ wrapperClassName, onGenerate, isError, errorText, ...props },
	ref,
) => {
	const { t } = useTranslation();

	const handleCopyClick = (): void => {
		if (!props.disabled && onGenerate) {
			onGenerate();
			return;
		}

		if (inputRef) {
			void navigator.clipboard.writeText(inputRef.value);
			toast.success(t('ui.copied'), {
				position: 'top-right',
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: 0,
				theme: 'light',
			});
		}
	};

	const [inputRef, setInputRef] = useState<Nullable<HTMLInputElement>>(null);
	const handleRef = useCallback(
		(element: Nullable<HTMLInputElement>) => {
			if (!element) return;
			setInputRef(element);
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-expect-error
			ref(inputRef);
		},
		[inputRef, ref],
	);
	return (
		<div className={clsx('relative', wrapperClassName)}>
			<input
				className={clsx(
					'outline-0 w-full py-2 px-3 rounded-lg border border-gray-300 disabled:bg-gray-200',
					isError && '!border-red-600 focus:shadow-none',
				)}
				ref={handleRef}
				type="text"
				{...props}
			/>
			<button onClick={handleCopyClick} className="absolute top-1/2 right-2 -translate-y-1/2">
				{props.disabled ?? !props.value ? <img src={copy} alt="copy" /> : <img src={generate} alt="generate" />}
			</button>
			{isError && <span className="absolute top-full left-0 translate-y-px text-xs text-red-600">{errorText}</span>}
		</div>
	);
};

export default forwardRef<HTMLInputElement, IProps>(CopyInput);
