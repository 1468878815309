import React, { type FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { config } from 'shared/constants/config';
import type { IPaymentSystem } from '../types';

const Commissions: FC = () => {
	const { t } = useTranslation();

	const systemList = useMemo<IPaymentSystem[]>(
		() => [
			{
				id: 1,
				icon: `${config.storageUrl}/images/icon_visa.png`,
				title: t('modules.home.commissions.system1Title'),
				payment: t('modules.home.commissions.system1Payment'),
				withdrawal: t('modules.home.commissions.system1Withdrawal'),
			},
			{
				id: 2,
				icon: `${config.storageUrl}/images/icon_visa.png`,
				title: t('modules.home.commissions.system2Title'),
				subTitle: t('modules.home.commissions.system2SubTitle'),
				payment: t('modules.home.commissions.system2Payment'),
				withdrawal: t('modules.home.commissions.system2Withdrawal'),
			},
			{
				id: 3,
				icon: `${config.storageUrl}/images/icon_master_card.png`,
				title: t('modules.home.commissions.system3Title'),
				payment: t('modules.home.commissions.system3Payment'),
				withdrawal: t('modules.home.commissions.system3Withdrawal'),
			},
			{
				id: 4,
				icon: `${config.storageUrl}/images/icon_master_card.png`,
				title: t('modules.home.commissions.system4Title'),
				subTitle: t('modules.home.commissions.system4SubTitle'),
				payment: t('modules.home.commissions.system4Payment'),
				withdrawal: t('modules.home.commissions.system4Withdrawal'),
			},
			{
				id: 5,
				icon: `${config.storageUrl}/images/icon_bitcoin.png`,
				title: t('modules.home.commissions.system5Title'),
				payment: t('modules.home.commissions.system5Payment'),
				withdrawal: t('modules.home.commissions.system5Withdrawal'),
			},
			{
				id: 6,
				icon: `${config.storageUrl}/images/icon_bitcoin_cash.png`,
				title: t('modules.home.commissions.system6Title'),
				payment: t('modules.home.commissions.system6Payment'),
				withdrawal: t('modules.home.commissions.system6Withdrawal'),
			},
			{
				id: 7,
				icon: `${config.storageUrl}/images/icon_etherum.png`,
				title: t('modules.home.commissions.system7Title'),
				payment: t('modules.home.commissions.system7Payment'),
				withdrawal: t('modules.home.commissions.system7Withdrawal'),
			},
			{
				id: 8,
				icon: `${config.storageUrl}/images/icon_litecoin.png`,
				title: t('modules.home.commissions.system8Title'),
				payment: t('modules.home.commissions.system8Payment'),
				withdrawal: t('modules.home.commissions.system8Withdrawal'),
			},
			{
				id: 9,
				icon: `${config.storageUrl}/images/icon_usdt.png`,
				title: t('modules.home.commissions.system9Title'),
				payment: t('modules.home.commissions.system9Payment'),
				withdrawal: t('modules.home.commissions.system9Withdrawal'),
			},
			{
				id: 10,
				icon: `${config.storageUrl}/images/icon_monero_xmr.png`,
				title: t('modules.home.commissions.system10Title'),
				payment: t('modules.home.commissions.system10Payment'),
				withdrawal: t('modules.home.commissions.system10Withdrawal'),
			},
			{
				id: 11,
				icon: `${config.storageUrl}/images/icon_tron.png`,
				title: t('modules.home.commissions.system11Title'),
				payment: t('modules.home.commissions.system11Payment'),
				withdrawal: t('modules.home.commissions.system11Withdrawal'),
			},
			{
				id: 12,
				icon: `${config.storageUrl}/images/icon_usd_coin.png`,
				title: t('modules.home.commissions.system12Title'),
				payment: t('modules.home.commissions.system12Payment'),
				withdrawal: t('modules.home.commissions.system12Withdrawal'),
			},
		],
		[t],
	);

	return (
		<div className="px-12 py-20 bg-gray-50">
			<div className="mb-8">
				<h2 className="text-[44px] text-gray-900 leading-[56px] font-extrabold mb-6 text-center">
					{t('modules.home.commissions.title')}
				</h2>
				<p className="text-2xl text-gray-600 text-center max-w-[820px] mx-auto ">{t('modules.home.commissions.description')}</p>
			</div>

			<div className="p-8 rounded-lg border border-gray-200 bg-white">
				{systemList.map(system => (
					<div key={system.id} className="flex items-center justify-between border-b border-b-gray-200 py-3">
						<div className="w-2/4">
							<div className="flex items-center">
								<img className="mr-6" src={system.icon} alt="system" />
								<span className="text-lg leading-6 text-gray-900">{system.title}</span>
							</div>
							{system.subTitle && <div className="text-xs leading-4 text-gray-600">{system.subTitle}</div>}
						</div>
						<div className="text-lg leading-6 w-1/4 text-gray-900">{system.payment}</div>
						<div className="text-lg leading-6 w-1/4 text-gray-900">{system.withdrawal}</div>
					</div>
				))}
				<div className="text-lg leading-6 text-gray-600 mt-4">* {t('modules.home.commissions.individual')}</div>
			</div>
		</div>
	);
};

export default Commissions;
