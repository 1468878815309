import React, { forwardRef, type ForwardRefRenderFunction, type InputHTMLAttributes, useCallback, useRef, useState } from 'react';
import clsx from 'clsx';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import { ReactComponent as EyeShowIcon } from 'assets/icons/eye-show.svg';
import { ReactComponent as EyeHideIcon } from 'assets/icons/eye-hide.svg';
import classes from './Input.module.scss';
import { PasswordInputType } from './types';

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
	transparent?: boolean;
	search?: boolean;
	wrapperClassName?: string;
	isError?: boolean;
	errorText?: string;
}

const Input: ForwardRefRenderFunction<HTMLInputElement, IProps> = (
	{ wrapperClassName, transparent, search, className, isError, errorText, ...rest },
	ref,
) => {
	const isPassword = useRef<boolean>(rest.type === 'password');
	const [type, setType] = useState<PasswordInputType>(rest.type === 'password' ? PasswordInputType.PASSWORD : PasswordInputType.TEXT);

	const toggleType = useCallback(() => {
		setType(prev => (prev === PasswordInputType.PASSWORD ? PasswordInputType.TEXT : PasswordInputType.PASSWORD));
	}, []);

	return (
		<div className={clsx('relative', wrapperClassName, rest.disabled && 'cursor-not-allowed')}>
			{search && <SearchIcon className="absolute top-2/4 -translate-y-2/4 left-[15px]" />}
			<input
				{...rest}
				ref={ref}
				type={isPassword.current ? type : rest.type}
				className={clsx(
					className,
					classes.input,
					'pr-4 cursor-pointer hover:bg-indigo-50 block py-2 w-full rounded-md border shadow-sm focus:border-indigo-500 duration-100 text-sm placeholder:text-gray-400 disabled:border-gray-300 disabled:pointer-events-none disabled:bg-gray-200',
					search ? 'pl-10' : 'pl-4',
					transparent ? 'border-transparent bg-transparent shadow-transparent' : ' border-gray-300 bg-white',
					isError && '!border-red-600 focus:shadow-none',
				)}
			/>
			{isPassword.current && (
				<div onClick={toggleType} className="absolute top-2/4 -translate-y-2/4 right-4 z-1 cursor-pointer">
					{type === PasswordInputType.PASSWORD ? (
						<EyeShowIcon className="h-4 fill-gray-700" />
					) : (
						<EyeHideIcon className="h-4 fill-gray-700" />
					)}
				</div>
			)}
			{isError && <span className="absolute top-full left-0 translate-y-px text-xs text-red-600">{errorText}</span>}
		</div>
	);
};

export default forwardRef<HTMLInputElement, IProps>(Input);
