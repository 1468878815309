import { axiosInstance } from 'shared/libs';
import { type ITableSettings, type IPaginateResponse } from 'shared/types';
import api from 'shared/constants/api';
import { settingsToSearchParams } from 'shared/helpers';
import {
	type IAccountsService,
	type IAccountsData,
	type IUpdateAccountsData,
	type IAccountsDetails,
	type IConnectionData,
	type IAccountWalletDetails,
	type IIntegrationData,
} from '../types';

class AccountService implements IAccountsService {
	public async getAccountsData(): Promise<IAccountsData[]> {
		return (await axiosInstance.get(api.ACCOUNTS.GET_ALL)).data;
	}

	public async createAccountsData(data: IAccountsDetails): Promise<IAccountsDetails> {
		return (await axiosInstance.post(api.ACCOUNTS.GET_ALL, data)).data;
	}

	public async getAccountDetails(id: string): Promise<IAccountsDetails> {
		return (await axiosInstance.get(api.ACCOUNTS.GET_ONE(id))).data;
	}

	public async setAccountDetails(id: string, data: IAccountsDetails): Promise<IAccountsDetails> {
		return (await axiosInstance.put(api.ACCOUNTS.GET_ONE(id), data)).data;
	}

	public async toggleAccountsStatus(id: string): Promise<void> {
		return (await axiosInstance.put(api.ACCOUNTS.UPDATE_STATUS(id))).data;
	}

	public async updateAccountsData(id: string, data: IUpdateAccountsData): Promise<IAccountsData> {
		return (await axiosInstance.put(api.ACCOUNTS.UPDATE_ONE(id), data)).data;
	}

	public async updateAccountsLogo(id: string, logo: Blob): Promise<IAccountsData> {
		const formData = new FormData();
		formData.append('logo', logo);
		return (await axiosInstance.put(api.ACCOUNTS.UPDATE_LOGO(id), formData)).data;
	}

	public async getAccountWallets(id: string, settings: ITableSettings): Promise<IPaginateResponse<IAccountWalletDetails>> {
		const query = settingsToSearchParams(settings);
		return (await axiosInstance.get<IPaginateResponse<IAccountWalletDetails>>(api.ACCOUNTS.GET_ACCOUNT_WALLETS(id) + `?${query}`)).data;
	}

	public async getAccountIntegration(id: string): Promise<IConnectionData> {
		return (await axiosInstance.get(api.ACCOUNTS.GET_ACCOUNT_INTEGRATION_SETTINGS(id))).data;
	}

	public async setAccountIntegration(id: string, data: IConnectionData): Promise<IConnectionData> {
		return (await axiosInstance.put(api.ACCOUNTS.GET_ACCOUNT_INTEGRATION_SETTINGS(id), data)).data;
	}

	public async generateKey(): Promise<string> {
		return (await axiosInstance.get<{ value: string }>(api.ACCOUNTS.GENERATE_KEY)).data.value;
	}

	public async getAvailableIntegrations(id: string): Promise<IIntegrationData[]> {
		return (await axiosInstance.get<IIntegrationData[]>(api.ACCOUNTS.GET_ACCOUNT_AVAILABLE_INTEGRATIONS(id))).data;
	}

	public async integrateWallet(merchantAccountId: string, paymentSystemAccountId: string): Promise<void> {
		return (await axiosInstance.post(api.ACCOUNTS.INTEGRATE_WALLET(merchantAccountId), { paymentSystemAccountId })).data;
	}
}

export default new AccountService();
