import React, { type FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, DatePicker, TimeInput } from 'components/UI';
import { type DateTimeRange, type Range } from '../types';
import dayjs, { type Dayjs } from 'dayjs';
import { type Nullable } from 'shared/types';
import { toast } from 'react-toastify';
import { customEventEmitter } from 'shared/utils';
import { type IUpdateTimeEvent } from 'components/UI/TimeInput/types';
import { useCustomEventListener } from 'shared/hooks';

interface IProps {
	onConfirm: (key: string, value: Range) => void;
	onClose: () => void;
	values: Range;
}

const calculateDate = (dateRange: Range): DateTimeRange => {
	const dateFrom = dayjs(dateRange.from);
	const dateTo = dayjs(dateRange.to);
	return {
		dateFrom: dateRange.from ? dateFrom.format('YYYY-MM-DD') : null,
		timeFrom: dateRange.from ? dateFrom.format('HH:mm:ss') : null,
		dateTo: dateRange.to ? dateTo.format('YYYY-MM-DD') : null,
		timeTo: dateRange.to ? dateTo.format('HH:mm:ss') : null,
	};
};

const RangeDateTimeSetting: FC<IProps> = ({ onConfirm, values, onClose }) => {
	const { t } = useTranslation();

	const [localValues, setLocalValues] = useState<DateTimeRange>(() => calculateDate(values));
	const [dateFrom, setDateFrom] = useState<Nullable<Date>>(null);
	const [dateTo, setDateTo] = useState<Nullable<Date>>(null);

	const handleClose = useCallback(() => {
		if (!values.from) {
			setDateFrom(null);
			customEventEmitter.trigger(customEventEmitter.events.RESET_TIME_FILTER, { position: 'from' });
		}

		if (!values.to) {
			setDateTo(null);
			customEventEmitter.trigger(customEventEmitter.events.RESET_TIME_FILTER, { position: 'to' });
		}

		setLocalValues(prev => ({
			dateFrom: values.from ? prev.dateFrom : null,
			timeFrom: values.from ? prev.timeFrom : null,
			dateTo: values.to ? prev.dateTo : null,
			timeTo: values.to ? prev.timeTo : null,
		}));
		onClose();
	}, [values, onClose]);
	const showDateError = useCallback((errorMessage: string) => {
		toast.error(errorMessage, {
			position: 'top-right',
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: 0,
			theme: 'light',
		});
	}, []);

	const handleConfirm = useCallback(() => {
		const diff = dayjs(dateFrom).diff(dayjs(dateTo));
		if (diff > 0) {
			showDateError(t('validation.dateMinMax'));
			return;
		}

		if (localValues.timeFrom && !localValues.dateFrom) {
			showDateError(t('validation.dateRequired'));
			return;
		}

		if (localValues.timeTo && !localValues.dateTo) {
			showDateError(t('validation.dateRequired'));
			return;
		}

		onConfirm('date', {
			from: dateFrom as unknown as number,
			to: dateTo as unknown as number,
		});
	}, [dateFrom, dateTo, onConfirm, localValues, showDateError]);

	const handleDateChange = useCallback(
		(type: string, value: Dayjs): void => {
			type.includes('date')
				? setLocalValues(prev => {
						const isStartDate = type.includes('From');
						let selected = dayjs(value);
						const hour = dayjs(isStartDate ? dateFrom : dateTo).hour();
						const minute = dayjs(isStartDate ? dateFrom : dateTo).minute();
						const second = dayjs(isStartDate ? dateFrom : dateTo).second();
						if (hour) selected = selected.hour(hour);
						if (minute) selected = selected.minute(minute);
						if (second) selected = selected.second(second);
						isStartDate ? setDateFrom(selected.toDate()) : setDateTo(selected.toDate());

						const selectedDate = value.format('YYYY-MM-DD');
						return { ...prev, [type]: selectedDate };
				  })
				: setLocalValues(prev => {
						if (value) {
							const isStartDate = type.includes('From');
							let selected = dayjs(isStartDate ? localValues.dateFrom : localValues.dateTo);
							const hour = value.hour();
							const minute = value.minute();
							const second = value.second();
							if (hour) selected = selected.hour(hour);
							if (minute) selected = selected.minute(minute);
							if (second) selected = selected.second(second);
							isStartDate ? setDateFrom(selected.toDate()) : setDateTo(selected.toDate());
						}

						return { ...prev, [type]: value };
				  });
		},
		[dateFrom, dateTo, localValues.dateFrom, localValues.dateTo],
	);

	useEffect(() => {
		setLocalValues(calculateDate(values));
	}, [values]);

	const handleResetDate = useCallback((e: Event) => {
		const {
			detail: { position },
		} = e as IUpdateTimeEvent;
		if (position === 'from') setDateFrom(null);
		if (position === 'to') setDateTo(null);
	}, []);

	const handleKeyboard = useCallback((event: KeyboardEvent) => {
		if (document.activeElement && event.code === 'Backspace') {
			const id = document.activeElement.getAttribute('data-test-id');
			if (!id) return;
			if (id.includes('from')) setLocalValues(prev => ({ ...prev, dateFrom: null }));
			if (id.includes('to')) setLocalValues(prev => ({ ...prev, dateTo: null }));
		}
	}, []);

	useEffect(() => {
		document.addEventListener('keydown', handleKeyboard);
		return () => {
			document.removeEventListener('keydown', handleKeyboard);
		};
	}, []);

	useCustomEventListener(customEventEmitter.events.RESET_TIME_FILTER, handleResetDate);

	return (
		<div>
			<div className="flex items-center py-4 pb-0">
				<span className="mr-2 text-sm block w-8">{t('ui.table.from').toLowerCase()}</span>
				<DatePicker
					testId="client-date-from"
					value={localValues.dateFrom ? dayjs(localValues.dateFrom).toDate() : null}
					onChange={date => {
						handleDateChange('dateFrom', date);
					}}
					wrapperClassName="w-[120px] mr-2"
				/>
				<TimeInput
					disabled={!localValues.dateFrom}
					data-test-id="client-time-from"
					id="from"
					onChange={date => {
						handleDateChange('timeFrom', date);
					}}
					wrapperClassName="w-[120px]"
				/>
			</div>
			<div className="flex items-center py-4">
				<span className="mr-2 text-sm block w-8">{t('ui.table.to').toLowerCase()}</span>
				<DatePicker
					testId="client-date-to"
					value={localValues.dateTo ? dayjs(localValues.dateTo).toDate() : null}
					onChange={date => {
						handleDateChange('dateTo', date);
					}}
					wrapperClassName="w-[120px] mr-2"
				/>
				<TimeInput
					disabled={!localValues.dateTo}
					data-test-id="client-time-to"
					id="to"
					onChange={date => {
						handleDateChange('timeTo', date);
					}}
					wrapperClassName="w-[120px]"
				/>
			</div>
			<div className="flex justify-between items-center">
				<Button data-test-id="client-datetime-cancel" variant="secondary" onClick={handleClose}>
					{t('ui.buttons.close')}
				</Button>
				<Button data-test-id="client-datetime-confirm" variant="primary" onClick={handleConfirm}>
					{t('ui.buttons.confirm')}
				</Button>
			</div>
		</div>
	);
};

export default RangeDateTimeSetting;
