import React, { type FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SettingsTab } from 'modules/settings';
import { removeHistoryRecord } from 'modules/app';
import { type NavigationItem, type Nullable } from 'shared/types';
import routes from 'shared/constants/routes';
import { useAppDispatch } from 'shared/hooks';
import { ExpandableList } from 'components/UI';
import { ReactComponent as Logo } from 'assets/icons/logo_black.svg';
import { type NavConfig } from '../types';
const Sidebar: FC = () => {
	const { t } = useTranslation();
	const location = useLocation();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const [selectedAcquirement, setSelectedAcquirement] = useState<Nullable<number>>(null);

	const handleItemClick = useCallback(
		(item: NavigationItem) => {
			navigate(item.value);
			dispatch(removeHistoryRecord());
		},
		[navigate],
	);

	const navigationConfig = useMemo<NavConfig>(
		() => ({
			items: [
				{
					id: 1,
					text: t('modules.layout.accounts'),
					value: routes.accounts.index,
				},
				{
					id: 2,
					text: t('modules.layout.operations'),
					value: routes.operations.index,
				},
				{
					id: 4,
					text: t('modules.layout.withdrawals'),
					value: routes.withdrawals.index,
				},
				{
					id: 3,
					text: t('modules.layout.settings'),
					value: routes.settings.index(SettingsTab.BASIC_DATA),
				},
			],
			title: t('modules.layout.clientCabinet'),
		}),
		[t],
	);

	useEffect(() => {
		const acquirement = navigationConfig.items.find(({ value }) => location.pathname.includes(value))?.id ?? null;
		setSelectedAcquirement(acquirement);
		// eslint-disable-next-line
	}, [location]);

	return (
		<div className="w-full max-w-[255px] bg-white px-2 pt-5 border-r border-r-gray-200">
			<Logo className="mb-6 pl-2" />
			<ExpandableList id="1" {...navigationConfig} selected={selectedAcquirement} onItemClick={handleItemClick} />
		</div>
	);
};

export default Sidebar;
