import { axiosInstance } from 'shared/libs';
import { type Response } from 'shared/types';
import { type Currency, type Enum, type IAppService, type Role, type Service } from '../types';
import api from 'shared/constants/api';

class AppService implements IAppService {
	public async getEnums(): Promise<Enum[]> {
		return (await axiosInstance.get<Enum[]>(api.APP.GET_ENUMS)).data;
	}

	public async getServices(): Promise<Service[]> {
		return (await axiosInstance.get<Response<Service[]>>(api.APP.GET_SERVICES)).data.content;
	}

	public async getCurrencies(): Promise<Currency[]> {
		return (await axiosInstance.get<Response<Currency[]>>(api.APP.GET_CURRENCIES)).data.content;
	}

	public async getRoles(): Promise<Role[]> {
		return (await axiosInstance.get<Response<Role[]>>(api.APP.GET_ROLES)).data.content;
	}
}

export default new AppService();
