import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import routes from 'shared/constants/routes';
import { type ITab } from 'shared/types';
import { SettingsTab } from '../types';

type ReturnType = {
	tabs: ITab[];
	activeTab: ITab;
	handleTabClick: (tab: ITab) => void;
};
export const useSettingsTabs = ({ tab }: { tab?: string }): ReturnType => {
	const navigate = useNavigate();
	const { t } = useTranslation();

	const tabs = useMemo<ITab[]>(
		() => [
			{
				id: 1,
				value: SettingsTab.BASIC_DATA,
				text: t('ui.tabs.basicData'),
			},
			{
				id: 2,
				value: SettingsTab.CHANGE_PASSWORD,
				text: t('ui.tabs.changePassword'),
			},
			{
				id: 3,
				value: SettingsTab.EMPLOYEES,
				text: t('ui.tabs.employees'),
			},
		],
		[t],
	);

	const activeTab = useMemo<ITab>(() => {
		return tabs.find(tb => tb.value === tab) ?? tabs[0];
	}, [tab, tabs]);

	const handleTabClick = useCallback(
		(tab: ITab) => {
			navigate(routes.settings.index(tab.value));
		},
		[navigate, activeTab],
	);

	return { tabs, activeTab, handleTabClick };
};
