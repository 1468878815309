import React, { type FC, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useValidation } from 'shared/hooks';
import { useForm } from 'react-hook-form';
import { Button, FormRow, Input } from 'components/UI';
import { type IChangePasswordData } from '../types';
import OperationService from '../service';
import { toast } from 'react-toastify';

const ChangePasswordTab: FC = () => {
	const { t } = useTranslation();

	const { passwordValidation, equalPasswordValidation } = useValidation();

	const {
		trigger,
		watch,
		setValue,
		clearErrors,
		register,
		handleSubmit,
		formState: { errors, isValid },
	} = useForm<IChangePasswordData>({ mode: 'all' });
	const watcher = watch();

	const clearForm = useCallback(() => {
		Object.entries(watcher).forEach(([key]) => {
			setValue(key as keyof IChangePasswordData, '', { shouldTouch: false });
		});
		clearErrors();
	}, [clearErrors, setValue, watcher]);

	const handleCancel = useCallback(() => {
		clearForm();
		clearErrors();
	}, [clearErrors, clearForm]);

	const handleConfirm = useCallback(
		async (data: IChangePasswordData) => {
			try {
				await OperationService.updatePassword(data);
				toast.success(t('modules.settings.password.successMessage'), {
					position: 'top-right',
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: 0,
					theme: 'light',
				});
			} finally {
				clearForm();
			}
		},
		[clearForm, t],
	);

	useEffect(() => {
		if (watcher.confirmNewPassword)
			setTimeout(() => {
				void trigger('confirmNewPassword');
			}, 10);
	}, [watcher.confirmNewPassword, watcher.newPassword]);

	return (
		<div>
			<div className="flex justify-between items-center py-2">
				<h4 className="text-gray-900 text-lg font-medium min-h-[38px]">
					{t('modules.clientDetails.settings')}
				</h4>
			</div>
			<FormRow label={`${t('modules.settings.password.current')} *`}>
				<Input
					data-test-id="client-settings-password-current"
					type={'password'}
					isError={Boolean(errors.oldPassword)}
					errorText={errors.oldPassword?.message}
					wrapperClassName="max-w-[512px] w-full"
					{...register('oldPassword', {
						required: {
							value: true,
							message: t('validation.required'),
						},
					})}
				/>
			</FormRow>
			<FormRow label={`${t('modules.settings.password.new')} *`}>
				<Input
					data-test-id="client-settings-password-new"
					type={'password'}
					isError={Boolean(errors.newPassword)}
					errorText={errors.newPassword?.message}
					wrapperClassName="max-w-[512px] w-full"
					{...register('newPassword', passwordValidation)}
				/>
			</FormRow>
			<FormRow label={`${t('modules.settings.password.repeat')} *`}>
				<Input
					data-test-id="client-settings-password-repeat"
					type={'password'}
					isError={Boolean(errors.confirmNewPassword)}
					errorText={errors.confirmNewPassword?.message}
					wrapperClassName="max-w-[512px] w-full"
					{...register('confirmNewPassword', {
						...equalPasswordValidation(
							watcher.newPassword,
							watcher.confirmNewPassword,
						),
					})}
				/>
			</FormRow>

			<div className="flex justify-between items-center mt-6">
				<Button
					data-test-id="client-settings-password-cancel"
					variant="secondary"
					onClick={handleCancel}>
					{t('ui.buttons.cancel')}
				</Button>
				<Button
					data-test-id="client-settings-password-confirm"
					variant="primary"
					onClick={handleSubmit(handleConfirm)}
					disabled={!isValid || !watcher.oldPassword}>
					{t('ui.buttons.confirm')}
				</Button>
			</div>
		</div>
	);
};

export default ChangePasswordTab;
