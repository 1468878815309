import { type IPaginateResponse, type ITableSettings } from 'shared/types';

interface IOperationService {
	getAllOperations: (settings: ITableSettings) => Promise<IPaginateResponse<IOperation>>;
	getOperationById: (id: string) => Promise<IOperationDetails>;
	exportToExel: (settings: ITableSettings) => Promise<Blob>;
}

interface IOperation {
	accountName: string;
	amount: number;
	actualAmount: number;
	balance: number;
	amountFrom: number;
	currency: string;
	fee: number;
	id: string;
	netAmount: number;
	exchangeRate: number;
	number: string;
	paymentSystem: string;
	wallet: string;
	processedAt: string;
	createdAt: string;
	status: number;
	type: number;
}

interface IOperationDetails extends IOperation {
	accountId: string;
	createdAt: string;
	description: string;
	id: string;
	toWalletAddress: string;
}

enum OperationTab {
	BASIC_DATA = 'main',
	AUDIT = 'audit',
}

export { OperationTab };

export type { IOperation, IOperationService, IOperationDetails };
