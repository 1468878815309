import { createAsyncThunk } from '@reduxjs/toolkit';
import { type ITableSettings } from 'shared/types';
import { setSelectedAccountWalletsItemsCount, setSelectedAccountWalletsRows, setSelectedAccountWalletsTotalPages } from './reducer';
import AccountService from '../service';
const LOAD_WALLETS_TYPE = 'accounts/wallets/load';

const LoadWalletsAction = createAsyncThunk(
	LOAD_WALLETS_TYPE,
	async ({ id, settings }: { id: string; settings: ITableSettings }, { rejectWithValue, dispatch }) => {
		try {
			const walletsData = await AccountService.getAccountWallets(id, settings);
			dispatch(setSelectedAccountWalletsRows(walletsData.content));
			dispatch(setSelectedAccountWalletsTotalPages(Math.ceil(walletsData.itemsCount / walletsData.paging.pageSize) || 1));
			dispatch(setSelectedAccountWalletsItemsCount(walletsData.itemsCount));
		} catch (e) {
			return rejectWithValue(e);
		}
	},
);

export { LoadWalletsAction };
