import React, { type FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useAppSelector, useValidation } from 'shared/hooks';
import { Button, FormRow, Input } from 'components/UI';
import { type IAddressData } from '../types';

interface IProps {
	onConfirm: (data: IAddressData) => Promise<void>;
	onCancel: () => void;
	destination: string;
}
const TypeAddressStep: FC<IProps> = ({ onConfirm, onCancel, destination }) => {
	const { t } = useTranslation();
	const { stringValidation } = useValidation();
	const selectedWallet = useAppSelector(state => state.withdrawals.new.selectedWallet);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const {
		setValue,
		register,
		handleSubmit,
		formState: { errors, isValid },
	} = useForm<IAddressData>({ mode: 'all', defaultValues: { destination } });

	const handleCancel = useCallback(() => {
		setValue('destination', '');
		setValue('operationNumber', '');
		setValue('description', '');
		onCancel();
	}, [setValue, onCancel]);

	const handleConfirm = useCallback(
		async (data: IAddressData) => {
			setIsLoading(true);
			await onConfirm(data);
			setIsLoading(false);
		},
		[onConfirm],
	);

	if (!selectedWallet) return <></>;

	return (
		<div className="pb-8">
			<h4 className="text-gray-900 text-lg font-medium min-h-[38px]">{t('modules.clientDetails.settings')}</h4>
			<FormRow label={`${t('modules.withdrawals.new.fields.account')}*`}>
				<Input
					placeholder={t('modules.withdrawals.new.placeholders.account')}
					isError={Boolean(errors.destination)}
					errorText={errors.destination?.message}
					wrapperClassName="max-w-[512px] w-full"
					{...register('destination', {
						required: {
							value: true,
							message: t('validation.required'),
						},
						pattern: {
							value: new RegExp(selectedWallet.regex),
							message: t('validation.address'),
						},
					})}
				/>
			</FormRow>
			<FormRow label={`${t('modules.withdrawals.new.fields.number')}`}>
				<Input
					placeholder={t('modules.withdrawals.new.placeholders.number')}
					isError={Boolean(errors.operationNumber)}
					errorText={errors.operationNumber?.message}
					wrapperClassName="max-w-[512px] w-full"
					{...register('operationNumber', { ...stringValidation, required: false })}
				/>
			</FormRow>
			<FormRow label={`${t('modules.withdrawals.new.fields.description')}`}>
				<Input
					placeholder={t('modules.withdrawals.new.placeholders.description')}
					isError={Boolean(errors.description)}
					errorText={errors.description?.message}
					wrapperClassName="max-w-[512px] w-full"
					{...register('description', { ...stringValidation, required: false })}
				/>
			</FormRow>
			<div className="flex justify-between items-center mt-6">
				<Button variant="secondary" onClick={handleCancel}>
					{t('ui.buttons.cancel')}
				</Button>
				<Button isLoading={isLoading} variant="primary" onClick={handleSubmit(handleConfirm)} disabled={!isValid}>
					{t('ui.buttons.confirm')}
				</Button>
			</div>
		</div>
	);
};

export default TypeAddressStep;
