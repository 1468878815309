import React, { type FC, useMemo } from 'react';
import { ReactComponent as SafetyIcon } from 'assets/icons/safety.svg';
import { ReactComponent as ComissionIcon } from 'assets/icons/comission.svg';
import { ReactComponent as TransferIcon } from 'assets/icons/transfer.svg';
import { ReactComponent as CabinetIcon } from 'assets/icons/cabinet.svg';
import { ReactComponent as AffiliateIcon } from 'assets/icons/affiliate.svg';
import { ReactComponent as BandWidthIcon } from 'assets/icons/bandwidth.svg';
import ServiceCard from './ServiceCard';
import type { IServiceCard } from '../types';
import { useTranslation } from 'react-i18next';

const Services: FC = () => {
	const { t } = useTranslation();

	const serviceCards: IServiceCard[] = useMemo(
		() => [
			{
				id: 1,
				title: t('modules.home.services.card1Title'),
				description: t('modules.home.services.card1Description'),
				link: '#',
				icon: <SafetyIcon />,
			},
			{
				id: 2,
				title: t('modules.home.services.card2Title'),
				description: t('modules.home.services.card2Description'),
				link: '#',
				icon: <ComissionIcon />,
			},
			{
				id: 3,
				title: t('modules.home.services.card3Title'),
				description: t('modules.home.services.card3Description'),
				link: '#',
				icon: <TransferIcon />,
			},
			{
				id: 4,
				title: t('modules.home.services.card4Title'),
				description: t('modules.home.services.card4Description'),
				link: '#',
				icon: <CabinetIcon />,
			},
			{
				id: 5,
				title: t('modules.home.services.card5Title'),
				description: t('modules.home.services.card5Description'),
				link: '#',
				icon: <AffiliateIcon />,
			},
			{
				id: 6,
				title: t('modules.home.services.card6Title'),
				description: t('modules.home.services.card6Description'),
				link: '#',
				icon: <BandWidthIcon />,
			},
		],
		[t],
	);
	return (
		<div className="px-12 py-20 bg-gray-50">
			<div className="mb-14">
				<h2 className="text-[44px] text-gray-900 leading-[56px] font-extrabold mb-6 text-center">
					{t('modules.home.services.title')}
				</h2>
				<p className="text-2xl text-gray-600 text-center max-w-[755px] mx-auto ">{t('modules.home.services.description')}</p>
			</div>
			<div className="grid grid-cols-3 gap-x-6 gap-y-12">
				{serviceCards.map(card => (
					<ServiceCard key={card.id} {...card} />
				))}
			</div>
		</div>
	);
};

export default Services;
