import React, { type ButtonHTMLAttributes, forwardRef, type ForwardRefRenderFunction } from 'react';
import { type ButtonTypeConfig, type Variant } from './types';
import clsx from 'clsx';
import { Spinner } from 'components/UI/index';

const styleConfig: ButtonTypeConfig = {
	primary:
		'text-sm text-dark-700 font-medium rounded-md border border-blue-100 bg-blue-100 hover:bg-blue-200 text-main shadow-sm disabled:bg-gray-300 disabled:border-gray-300 disabled:text-gray-500',
	secondary: 'bg-white rounded-md shadow-sm border border-gray-300 text-sm text-dark-700 font-medium hover:bg-indigo-50',
	create: 'bg-main rounded-md shadow-sm border border-gray-300 text-sm text-white font-medium hover:bg-blue-600',
};

interface IProps extends ButtonHTMLAttributes<HTMLButtonElement> {
	variant: Variant;
	isLoading?: boolean;
	spinnerColor?: string;
}
const Button: ForwardRefRenderFunction<HTMLButtonElement, IProps> = (
	{ spinnerColor, isLoading, variant, type = 'button', children, className, ...rest },
	ref,
) => {
	return (
		<button
			ref={ref}
			{...rest}
			className={clsx(
				className,
				styleConfig[variant],
				isLoading && 'pointer-events-none',
				'disabled:cursor-not-allowed disabled:text-gray-400 disabled:bg-gray-300 inline-flex items-center px-3 py-2 relative duration-200',
			)}>
			{isLoading ? <span className="opacity-0">Loading...</span> : children}
			{isLoading && (
				<div className="absolute inset-0 flex justify-center items-center">
					<Spinner size="xs" color={spinnerColor ?? '#0E92EA'} />
				</div>
			)}
		</button>
	);
};

export default forwardRef<HTMLButtonElement, IProps>(Button);
