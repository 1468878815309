import React, { type FC, useCallback } from 'react';
import ReactPaginate from 'react-paginate';
import clsx from 'clsx';
import { ReactComponent as ArrowIcon } from 'assets/icons/arrow.svg';
import { type ClickEvent } from './types';

interface IProps {
	page: number;
	totalPages: number;
	onPageChange: (page: number) => void;
	simple?: boolean;
}
const Pagination: FC<IProps> = ({ page, totalPages, onPageChange, simple }) => {
	const handlePageClick = useCallback(
		(event: ClickEvent) => {
			onPageChange(event.selected + 1);
		},
		[onPageChange],
	);

	return (
		<div className="flex justify-center min-h-[36px]">
			<ReactPaginate
				breakLabel="..."
				nextLabel={<ArrowIcon className="-rotate-90" />}
				previousLabel={<ArrowIcon className="rotate-90" />}
				onPageChange={handlePageClick}
				forcePage={page - 1}
				pageRangeDisplayed={simple ? 0 : 3}
				marginPagesDisplayed={1}
				pageCount={totalPages}
				containerClassName="flex justify-center"
				activeClassName="!block border-indigo-500 !bg-indigo-50"
				activeLinkClassName="text-indigo-500"
				breakClassName={clsx(
					simple && 'hidden',
					'border border-gray-300 cursor-pointer py-2 px-4 bg-white hover:bg-indigo-50 duration-100 flex justify-center items-center',
				)}
				breakLinkClassName="text-gray-500 text-sm font-medium m-0 p-0"
				pageClassName={clsx(simple && 'hidden', 'cursor-pointer border border-gray-300  bg-white hover:bg-indigo-50 duration-100')}
				pageLinkClassName="text-gray-500 text-sm font-medium py-2 px-4 block w-full h-full m-0 p-0"
				previousClassName="cursor-pointer border rounded-l-md bg-white hover:bg-indigo-50 duration-100 border-gray-300 flex justify-center items-center"
				previousLinkClassName="px-4 py-2 m-0 h-full flex justify-center items-center"
				nextClassName="cursor-pointer border rounded-r-md bg-white hover:bg-indigo-50 duration-100 border-gray-300 flex justify-center items-center"
				nextLinkClassName="px-4 py-2 m-0 h-full flex justify-center items-center"
			/>
		</div>
	);
};

export default Pagination;
