import React, { forwardRef, type ForwardRefRenderFunction, type InputHTMLAttributes } from 'react';
import clsx from 'clsx';
import classes from './Switch.module.css';

const Switch: ForwardRefRenderFunction<HTMLInputElement, InputHTMLAttributes<HTMLInputElement>> = (props, ref) => {
	return (
		<div className={clsx(props.disabled && 'cursor-not-allowed', 'flex items-center')}>
			<input
				{...props}
				ref={ref}
				className={clsx(
					'cursor-pointer relative w-9 h-5 transition-all duration-200 ease-in-out bg-gray-200 rounded-full shadow-inner outline-none appearance-none disabled:pointer-events-none disabled:cursor-not-allowed',
					classes.input,
				)}
				type="checkbox"
			/>
		</div>
	);
};

export default forwardRef<HTMLInputElement, InputHTMLAttributes<HTMLInputElement>>(Switch);
