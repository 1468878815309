import { events } from './constants';

const handlers: Record<string, (e: Event) => void> = {};
const trigger = (name: string, detail = {}): void => {
	const event = new CustomEvent(name, { detail });
	document.dispatchEvent(event);
};

const on = (name: string, listener: (e: Event) => void): void => {
	const handler = (e: Event): void => {
		listener(e);
	};

	handlers[name] = handler;
	document.addEventListener(name, handler);
};

const off = (name: string, listener: (e: Event) => void): void => {
	const handler = handlers[name];
	if (!handler) return;
	document.removeEventListener(name, handler);
};

export default {
	on,
	off,
	trigger,
	events,
};
