import React, { type FC, useCallback, useEffect, useMemo } from 'react';
import { type ColDef } from 'ag-grid-community';
import { CustomizableTable } from 'components';
import { HeaderComponent, TextRenderer, EnumRenderer } from 'components/Table/components';
import { useAppDispatch, useAppSelector, useDebounce } from 'shared/hooks';
import { DEBOUNCE_DELAY } from 'shared/constants/delays';
import { useTranslation } from 'react-i18next';
import { type ITableSettings, type ValueOf } from 'shared/types';
import { LoadWalletsAction } from '../store/actions';
import { setSelectedAccountWalletsCols, updateSelectedAccountWalletsSettings } from '../store/reducer';
import { type IAccountWalletDetails } from '../types';

const WalletsTab: FC = () => {
	const dispatch = useAppDispatch();
	const { t } = useTranslation();

	const { enums, services } = useAppSelector(state => state.app);
	const {
		selectedAccount: {
			id,
			wallets: { rows, columns, settings, isLoading, totalPages, itemsCount },
		},
	} = useAppSelector(state => state.accounts);

	const debouncedSettings = useDebounce<ITableSettings>(settings, DEBOUNCE_DELAY);

	const handleSettingUpdate = useCallback(
		({ key, value }: { key: keyof ITableSettings; value: ValueOf<ITableSettings> }) => {
			dispatch(updateSelectedAccountWalletsSettings({ key, value }));
		},
		[dispatch],
	);

	const handleColumnsUpdate = useCallback(
		(cols: Array<ColDef<IAccountWalletDetails>>) => {
			dispatch(setSelectedAccountWalletsCols(cols));
		},
		[dispatch],
	);

	const initialCols = useMemo<Array<ColDef<IAccountWalletDetails>>>(() => {
		if (!enums) return [];
		return [
			{
				field: 'title',
				minWidth: 240,
				cellRenderer: TextRenderer,
				headerName: t('modules.wallets.walletName'),
				headerComponent: HeaderComponent,
				headerClass: 'bg-gray-50 border-b border-b-gray-200',
				suppressMovable: true,
				cellEditor: {
					value: settings.Title ?? '',
					orderBy: settings.OrderBy,
					orderDir: settings.OrderDir,
				},
			},
			{
				field: 'status',
				minWidth: 235,
				cellRenderer: EnumRenderer,
				headerName: t('modules.wallets.status'),
				headerComponent: HeaderComponent,
				headerClass: 'bg-gray-50 border-b border-b-gray-200',
				suppressMovable: true,
				refData: {
					enumkey: 'WalletStatus',
				},
				cellEditor: {
					filterOptions: Object.values(enums.WalletStatus.values),
					value: settings.Status ?? '',
					orderBy: settings.OrderBy,
					orderDir: settings.OrderDir,
				},
			},
			{
				field: 'balanceAmount',
				minWidth: 235,
				cellRenderer: TextRenderer,
				headerName: t('modules.wallets.balance'),
				headerComponent: HeaderComponent,
				headerClass: 'bg-gray-50 border-b border-b-gray-200',
				suppressMovable: true,
				cellEditor: {
					value: {
						from: settings.BalanceAmountFrom ?? '',
						to: settings.BalanceAmountTo ?? '',
					},
					orderBy: settings.OrderBy,
					orderDir: settings.OrderDir,
				},
			},
			{
				field: 'totalAmount',
				minWidth: 235,
				cellRenderer: TextRenderer,
				headerName: t('modules.wallets.totalBalance'),
				headerComponent: HeaderComponent,
				headerClass: 'bg-gray-50 border-b border-b-gray-200',
				suppressMovable: true,
				cellEditor: {
					value: {
						from: settings.TotalBalanceAmountFrom ?? '',
						to: settings.TotalBalanceAmountTo ?? '',
					},
					orderBy: settings.OrderBy,
					orderDir: settings.OrderDir,
				},
			},
			{
				field: 'holdAmount',
				minWidth: 255,
				cellRenderer: TextRenderer,
				headerName: t('modules.wallets.blockedBalance'),
				headerComponent: HeaderComponent,
				headerClass: 'bg-gray-50 border-b border-b-gray-200',
				suppressMovable: true,
				cellEditor: {
					value: {
						from: settings.HoldBalanceAmountFrom ?? '',
						to: settings.HoldBalanceAmountTo ?? '',
					},
					orderBy: settings.OrderBy,
					orderDir: settings.OrderDir,
				},
			},
			{
				field: 'services',
				minWidth: 240,
				cellRenderer: TextRenderer,
				headerName: t('modules.wallets.service'),
				headerComponent: HeaderComponent,
				headerClass: 'bg-gray-50 border-b border-b-gray-200',
				suppressMovable: true,
				cellEditor: {
					filterOptions: services.map(({ id, name }) => ({ id, value: name })),
					value: settings.Services ?? '',
					orderBy: settings.OrderBy,
					orderDir: settings.OrderDir,
				},
			},
		];
	}, [services, enums, settings, t]);

	useEffect(() => {
		if (!id) return;
		void dispatch(LoadWalletsAction({ settings, id }));
		// eslint-disable-next-line
	}, [debouncedSettings, id]);

	return (
		<div>
			<CustomizableTable
				itemsFound={Number(itemsCount)}
				columns={columns}
				settings={settings}
				isLoading={isLoading}
				onSettingUpdate={handleSettingUpdate}
				onColumnsUpdate={handleColumnsUpdate}
				rows={rows}
				totalPages={totalPages}
				initialCols={initialCols}
			/>
		</div>
	);
};

export default WalletsTab;
