import React, { type FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import routes from 'shared/constants/routes';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { WithdrawalTab } from './types';

import { PageHeader } from 'components';
import type { Crumb } from 'components/PageHeader/types';
import BasicDataTab from './components/BasicDataTab';
import AuditTab from './components/AuditTab';
import { loadWithdrawalByIdAction } from './store/actions';
import { useWithdrawalTabs } from './hooks/useWithdrawalTab';
import Transactions from './components/Transactions';

const Withdrawal: FC = () => {
	const { t } = useTranslation();
	const { id, tab } = useParams();
	const dispatch = useAppDispatch();
	const { tabs, activeTab, handleTabClick } = useWithdrawalTabs({ id, tab });

	const { mainData } = useAppSelector(state => state.withdrawals.selectedWithdrawal);

	const crumbs = useMemo<Crumb[]>(
		() => [{ name: t('modules.withdrawals.title'), link: routes.withdrawals.index }, { name: mainData?.id ?? '' }],
		[t, mainData],
	);

	useEffect(() => {
		if (!id) return;
		void dispatch(loadWithdrawalByIdAction(id));
	}, [id]);

	return (
		<div className="pb-6">
			<PageHeader
				previousPage={routes.withdrawals.index}
				activeTab={activeTab}
				onTabClick={handleTabClick}
				crumbs={crumbs}
				tabs={tabs}
				title={t('modules.withdrawals.withdrawalDetails')}
				className="mb-6"
			/>
			<div>{activeTab.value === WithdrawalTab.BASIC_DATA && <BasicDataTab />}</div>
			<div>{activeTab.value === WithdrawalTab.TRANSACTIONS && <Transactions />}</div>
			<div>{activeTab.value === WithdrawalTab.AUDIT && <AuditTab />}</div>
		</div>
	);
};

export default Withdrawal;
