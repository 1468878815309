import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import {
	createRoutesFromChildren,
	matchRoutes,
	useLocation,
	useNavigationType,
} from 'react-router-dom';
import { config } from 'shared/constants/config';

if (config.env !== 'development') {
	Sentry.init({
		dsn: 'https://85d15fa2f9728d4399f152b68787c505@o4508436281950208.ingest.us.sentry.io/4508436357316608',
		integrations: [
			// See docs for support of different versions of variation of react router
			// https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
			Sentry.reactRouterV6BrowserTracingIntegration({
				useEffect,
				useLocation,
				useNavigationType,
				createRoutesFromChildren,
				matchRoutes,
			}),
			Sentry.replayIntegration(),
			Sentry.browserTracingIntegration(),
		],

		// Set tracesSampleRate to 1.0 to capture 100%
		// of transactions for tracing.
		// Learn more at
		// https://docs.sentry.io/platforms/javascript/configuration/options/#traces-sample-rate
		tracesSampleRate: 1.0,

		// Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
		tracePropagationTargets: ['localhost', 'https://admin-api.obmenka.dev'],

		// Capture Replay for 10% of all sessions,
		// plus for 100% of sessions with an error
		// Learn more at
		// https://docs.sentry.io/platforms/javascript/session-replay/configuration/#general-integration-configuration
		replaysSessionSampleRate: 0.1,
		replaysOnErrorSampleRate: 1.0,
	});
}
