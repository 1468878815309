import React, { type ChangeEvent, type MouseEvent as RMouseEvent, type FC, useCallback, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Spinner, Input } from 'components/UI/index';
import { type Nullable } from 'shared/types';
import { ReactComponent as ArrowIcon } from 'assets/icons/arrow.svg';
import { type Option } from './types';
import classes from './Select.module.scss';

interface IProps {
	options: Option[];
	value: Nullable<Option>;
	onChange: (newValue: Option) => void;
	wrapperClassName?: string;
	placeholder?: string;
	className?: string;
	optionClassName?: string;
	disabled?: boolean;
	isLoading?: boolean;
	search?: string;
	onInput?: (value: string) => void;
	testId?: string;
}

const Select: FC<IProps> = ({
	options,
	value,
	placeholder,
	onChange,
	className,
	optionClassName,
	wrapperClassName,
	disabled,
	search,
	onInput,
	isLoading,
	testId,
}) => {
	const { t } = useTranslation();

	const [expanded, setExpanded] = useState<boolean>(false);
	const selectRef = useRef<HTMLDivElement>(null);

	const toggleExpanded = useCallback(() => {
		setExpanded(prev => !prev);
	}, []);

	const handleClickOut = useCallback((e: MouseEvent) => {
		if (!e.composedPath().includes(selectRef.current as Element)) {
			setExpanded(false);
		}
	}, []);

	const handleChange = useCallback(
		(option: Option) => {
			setExpanded(false);
			onChange(option);
		},
		[onChange],
	);
	const handleSearchChange = useCallback(
		(e: ChangeEvent<HTMLInputElement>) => {
			if (!onInput) return;
			onInput(e.target.value);
		},
		[onInput],
	);
	const handleSearchClick = useCallback(
		(e: RMouseEvent) => {
			if (expanded) e.stopPropagation();
		},
		[expanded],
	);

	useEffect(() => {
		document.addEventListener('click', handleClickOut);
		return () => {
			document.removeEventListener('click', handleClickOut);
		};
		// eslint-disable-next-line
	}, []);

	return (
		<div className={clsx('relative', disabled && 'cursor-not-allowed', wrapperClassName)} ref={selectRef}>
			<div
				onClick={toggleExpanded}
				data-test-id={`client-${testId ?? ''}-select`}
				className={clsx(
					className,
					'bg-white justify-between hover:bg-indigo-50 duration-100 rounded-md border border-gray-300 px-4 py-1.5 flex items-center cursor-pointer',
					disabled && '!border-gray-300 !pointer-events-none !bg-gray-200',
					expanded && '!border-indigo-500',
					expanded && classes.select,
				)}>
				{value ? (
					<span className="mr-2 text-gray-700 text-sm font-medium leading-tight">{value.label}</span>
				) : (
					<span className="text-gray-400 text-sm leading-tight">{placeholder}</span>
				)}

				<ArrowIcon className={clsx('z-10', expanded && '-rotate-90', 'duration-100', expanded && classes.expandedSvg)} />
				{onInput && (
					<Input
						data-test-id={`client-${testId ?? ''}-search`}
						onClick={handleSearchClick}
						value={search}
						onInput={handleSearchChange}
						wrapperClassName="!absolute top-0 left-0 bottom-0 right-0 w-full z-0"
					/>
				)}
			</div>
			{expanded && isLoading && (
				<div className="absolute z-10 py-2 flex justify-center top-full left-0 translate-y-1 bg-white w-full rounded-md border border-gray-300">
					<Spinner color="#0E92EA" size="xs" />
				</div>
			)}
			{expanded && !isLoading && (
				<div
					className={clsx(
						options.length > 8 && classes.scrollable,
						'absolute z-20 top-full left-0 translate-y-1 bg-white w-full rounded-md border border-gray-300 max-h-80',
					)}>
					{options.map(option => (
						<div
							key={option.value}
							data-test-id={`client-${testId ?? ''}-option-${option.value}`}
							onClick={handleChange.bind(null, option)}
							className={clsx(
								optionClassName,
								'text-center text-sm font-medium py-1 cursor-pointer hover:bg-indigo-100 duration-100',
								option.value === value?.value && 'bg-indigo-50',
							)}>
							{option.label}
						</div>
					))}
					{options.length === 0 && (
						<div className="flex justify-center text-sm font-medium py-1">{t('ui.select.emptyOptions')}</div>
					)}
				</div>
			)}
		</div>
	);
};

export default Select;
