type NavigationItem = {
	id: number;
	text: string;
	value: string;
	disabledVia2fa?: boolean;
};
type ValueOf<T> = T[keyof T];

enum Order {
	ASC = 'Asc',
	DESC = 'Desc',
	EMPTY = '',
}

type Response<T> = {
	content: T;
};

type Error = {
	statusCode: number;
	title: string;
	errors: Record<string, string[]>;
};

type Nullable<T> = T | null;

type IFilter = Record<string, string | number | number[] | null | boolean | undefined>;
interface ITableSettings extends IFilter {
	OrderBy?: string;
	Keyword?: string;
	OrderDir?: Order;
	PageNumber?: number;
	PageSize?: number;
	isFiltersHidden?: boolean;
	isSortingHidden?: boolean;
}

type UpdateTableData = {
	key: keyof ITableSettings;
	value: ValueOf<ITableSettings>;
};

interface ITab extends NavigationItem {
	action?: {
		disabled?: boolean;
		disabledVia2fa?: boolean;
		name: string;
		onClick: () => void;
	};
}

interface IPaginateResponse<T> {
	content: T[];
	itemsCount: number;
	maxRecordsForReport?: number;
	ordering: {
		orderBy: keyof T;
		orderDir: Order;
	};
	paging: {
		pageNumber: number;
		pageSize: number;
	};
}

interface ITableWallet {
	id: string;
	title: string;
	status: boolean;
	balance: number;
	exchangeBalance: number;
	totalBalance: number;
	blockedBalance: number;
	service: string;
}

interface IAuditLog {
	utcTimestamp: string;
	message: string;
}

export { Order };
export type {
	UpdateTableData,
	ITableSettings,
	NavigationItem,
	ValueOf,
	Response,
	Nullable,
	ITab,
	IPaginateResponse,
	ITableWallet,
	IAuditLog,
	Error,
};
