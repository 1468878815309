import React, { type FC } from 'react';
import { Modal } from 'components';
import { Button } from 'components/UI';
import { ReactComponent as CheckIcon } from 'assets/icons/check.svg';
import { useTranslation } from 'react-i18next';

interface IProps {
	onClose: () => void;
	show: boolean;
}
const SuccessTwoStepModal: FC<IProps> = ({ onClose, show }) => {
	const { t } = useTranslation();

	return (
		<Modal show={show} onClose={onClose}>
			<div className="flex flex-col items-center">
				<CheckIcon className="mb-3" />
				<h4 className="text-2xl leading-6 font-medium text-gray-900 mb-2">{t('modules.settings.twoStep.successTitle')}</h4>
				<p className="text-sm leading-5 font-normal text-gray-500 mb-5 max-w-[450px] text-center">
					{t('modules.settings.twoStep.successSubTitle')}
				</p>
				<div className="flex ml-auto">
					<Button data-test-id="client-withdrawal-create-close" variant="create" onClick={onClose}>
						{t('ui.buttons.close')}
					</Button>
				</div>
			</div>
		</Modal>
	);
};

export default SuccessTwoStepModal;
