import React, { type FC } from 'react';
import { CMSList } from '../constants';
import { Button } from 'components/UI';
import { useTranslation } from 'react-i18next';

const CmsModules: FC = () => {
	const { t } = useTranslation();

	return (
		<div className="px-12 py-20 bg-white">
			<div className="mb-8">
				<h2 className="text-[44px] text-gray-900 leading-[56px] font-extrabold mb-6 text-center">{t('modules.home.cms.title')}</h2>
				<p className="text-2xl text-gray-600 text-center max-w-[820px] mx-auto ">{t('modules.home.cms.description')}</p>
			</div>

			<div className="flex items-stretch justify-center mb-8">
				{CMSList.map(cms => (
					<div key={cms.id} className="px-10 py-8 border border-gray-200 rounded-lg flex items-center mr-6">
						<img src={cms.icon} alt="cms" />
					</div>
				))}
			</div>
			<div className="flex justify-center">
				<Button variant="create">{t('modules.home.cms.all')}</Button>
			</div>
		</div>
	);
};

export default CmsModules;
