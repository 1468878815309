import React, { type FC, type Dispatch } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Checkbox } from 'components/UI';

interface IProps {
	selectedItems: number[];
	setSelectedItems: Dispatch<React.SetStateAction<number[]>>;
	options?: Array<{
		id: number;
		value: string;
	}>;
	onClose: () => void;
	onConfirm: (key: string) => void;
	settingKey: string;
}
const SelectSettings: FC<IProps> = ({ settingKey, selectedItems, setSelectedItems, options, onClose, onConfirm }) => {
	const { t } = useTranslation();

	return (
		<div className="pt-2">
			<div className="max-h-[250px] overflow-y-auto scrollable">
				<Checkbox
					data-test-id={`client-select-${settingKey}-all`}
					label={t('ui.table.all')}
					wrapperClassName="mb-4"
					checked={selectedItems.length === options?.length}
					onChange={() => {
						setSelectedItems(selectedItems.length === options?.length ? [] : options?.map(opt => opt.id) ?? []);
					}}
				/>
				{options?.map(option => (
					<Checkbox
						data-test-id={`client-select-${settingKey}-${option.id}`}
						key={option.id}
						label={option.value}
						wrapperClassName="mb-4"
						checked={selectedItems.includes(option.id)}
						onChange={() => {
							setSelectedItems(prev =>
								prev.includes(option.id)
									? prev.length === option.id
										? prev
										: prev.filter(it => it !== option.id)
									: [...prev, option.id],
							);
						}}
					/>
				))}
			</div>
			<div className="flex justify-between items-center">
				<Button data-test-id={`client-select-${settingKey}-cancel`} variant="secondary" onClick={onClose} className="mr-4">
					{t('ui.buttons.close')}
				</Button>
				<Button
					data-test-id={`client-select-${settingKey}-confirm`}
					variant="primary"
					onClick={onConfirm.bind(null, settingKey)}
					disabled={!selectedItems.length}>
					{t('ui.buttons.confirm')}
				</Button>
			</div>
		</div>
	);
};

export default SelectSettings;
