import React, { type FC, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { FullscreenLoader } from 'components/UI';
import { useAuth } from './hooks/useAuth';

const RedirectCallback: FC = () => {
	const location = useLocation();
	const { redirectCallback } = useAuth();

	useEffect(() => {
		void redirectCallback();
		// eslint-disable-next-line
	}, [location.search]);

	return <FullscreenLoader />;
};

export default RedirectCallback;
