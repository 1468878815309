import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { localStorageKeys, localStorageService } from 'shared/utils/localStorage';
import { defaultLanguage } from 'modules/app';
import en from './en/translation.json';
import ru from './ru/translation.json';
import uk from './uk/translation.json';

void i18n
	.use(initReactI18next) // Passes i18n down to react-i18next
	.init({
		resources: { ...en, ...ru, ...uk },
		lng: localStorageService.get<string>(localStorageKeys.LANGUAGE) ?? defaultLanguage,

		interpolation: {
			escapeValue: false, // React already safes from xss
		},
	});

export default i18n;
