import { type IChangePasswordData, type ISettingsData, type ISettingsService, type ITwoStepData } from '../types';
import axiosInstance from 'shared/libs/axios';
import api from 'shared/constants/api';

class SettingsService implements ISettingsService {
	async getSettings(): Promise<ISettingsData> {
		return (await axiosInstance.get(api.SETTINGS.GET_SETTINGS)).data;
	}

	async setSettings(settings: ISettingsData): Promise<ISettingsData> {
		return (await axiosInstance.put<ISettingsData>(api.SETTINGS.UPDATE_SETTINGS, settings)).data;
	}

	async updatePassword(data: IChangePasswordData): Promise<void> {
		return (await axiosInstance.put(api.SETTINGS.UPDATE_PASSWORD, data)).data;
	}

	async getTwoStepData(enableTwoFactor: boolean): Promise<ITwoStepData> {
		return (await axiosInstance.put(api.SETTINGS.UPDATE_TWO_FACTOR, { enableTwoFactor })).data;
	}

	async confirmTwoStep(code: string): Promise<{ recoveryCodes: string[] }> {
		return (await axiosInstance.post(api.SETTINGS.CONFIRM_TWO_FACTOR, { code })).data;
	}

	async getRecoveryCodes(): Promise<{ recoveryCodes: string[] }> {
		return (await axiosInstance.post(api.SETTINGS.GET_RECOVERY_CODES, {})).data;
	}

	async toggleIpChecked(ipCheckEnabled: boolean): Promise<void> {
		return (await axiosInstance.put(api.SETTINGS.TOGGLE_IP_CHECKED, { ipCheckEnabled })).data;
	}
}
export default new SettingsService();
