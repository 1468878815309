import { useRef, useEffect } from 'react';
import { customEventEmitter } from 'shared/utils';

export const useCustomEventListener = (eventName: string, handler: (e: Event) => void): void => {
	const savedHandler = useRef<(e: Event) => void>();

	useEffect(() => {
		savedHandler.current = handler;
	}, [handler]);

	useEffect(() => {
		const listener = (e: Event): void => {
			savedHandler.current?.(e);
		};

		customEventEmitter.on(eventName, listener);
		return () => {
			customEventEmitter.off(eventName, listener);
		};
	}, [eventName]);
};
