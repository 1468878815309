import { SettingsTab } from '../../modules/settings';

const accountsPrefix = '/accounts';
const operationsPrefix = '/operations';
const settingsPrefix = '/settings';
const withdrawalsPrefix = '/withdrawals';

const routes = {
	home: '/',
	contacts: '/contacts',
	doesntExist: '*',
	auth: {
		redirect: '/auth-redirect',
		renewToken: '/auth-renew-token',
	},
	accounts: {
		index: accountsPrefix,
		details: (accountId: string, tab?: string) => `${accountsPrefix}/${accountId}${tab ? `/${tab}` : ''}`,
		wallet: (id: string) => `${window.location.href}/${id}`,
		create: `${accountsPrefix}/create`,
		createWalletConnection: (id: string) => `${accountsPrefix}/${id}/wallets/create`,
	},
	operations: {
		index: operationsPrefix,
		operation: (id: string, tab?: string) => `${operationsPrefix}/${id}${tab ? `/${tab}` : ''}`,
	},
	settings: {
		index: (tab: string) => `${settingsPrefix}/${tab}`,
		employee: (id: string) => `${settingsPrefix}/${SettingsTab.EMPLOYEES}/${id}/main`,
	},
	withdrawals: {
		index: withdrawalsPrefix,
		withdrawal: (id: string, tab?: string) => `${withdrawalsPrefix}/${id}${tab ? `/${tab}` : ''}`,
		create: `${withdrawalsPrefix}/create`,
	},
};

export default routes;
