import React, { type FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Button, FormRow, Input } from 'components/UI';
import { useAppSelector, useValidation } from 'shared/hooks';
import { type Nullable } from 'shared/types';

interface IProps {
	onConfirm: (amount: number) => Promise<void>;
	onCancel: () => void;
	amount: Nullable<number>;
}
const EnterSumStep: FC<IProps> = ({ onConfirm, onCancel, amount }) => {
	const { t } = useTranslation();
	const { numberValidation } = useValidation();
	const wallet = useAppSelector(state => state.withdrawals.new.selectedWallet);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const {
		setValue,
		register,
		handleSubmit,
		formState: { errors, isValid },
	} = useForm<{ amount: string }>({ mode: 'all', defaultValues: { amount: amount ? String(amount) : '' } });

	const handleCancel = useCallback(() => {
		setValue('amount', '');
		onCancel();
	}, [setValue, onCancel]);

	const handleConfirm = useCallback(
		async (data: { amount: string }) => {
			setIsLoading(true);
			await onConfirm(Number(data.amount));
			setIsLoading(false);
		},
		[onConfirm],
	);

	return (
		<div>
			<h4 className="text-gray-900 text-lg font-medium min-h-[38px]">{t('modules.clientDetails.settings')}</h4>
			<FormRow label={`${t('modules.withdrawals.new.fields.amount')}(${wallet?.currency ?? ''})*`}>
				<Input
					placeholder={t('modules.withdrawals.new.placeholders.amount')}
					isError={Boolean(errors.amount)}
					errorText={errors.amount?.message}
					wrapperClassName="max-w-[512px] w-full"
					{...register('amount', numberValidation)}
				/>
			</FormRow>
			<div className="flex justify-between items-center mt-6">
				<Button variant="secondary" onClick={handleCancel}>
					{t('ui.buttons.cancel')}
				</Button>
				<Button isLoading={isLoading} variant="primary" onClick={handleSubmit(handleConfirm)} disabled={!isValid}>
					{t('ui.buttons.confirm')}
				</Button>
			</div>
		</div>
	);
};

export default EnterSumStep;
