const LANGUAGE = 'LOCALSTORAGE_LANGUAGE';
const ACCESS_TOKEN = 'accessToken';
const ROUT_BEFORE_AUTH = 'routBeforeAuth';
const FIRST_LOGIN_SHOWN = 'FIRST_LOGIN_SHOWN';
export const localStorageKeys = {
	LANGUAGE,
	ACCESS_TOKEN,
	ROUT_BEFORE_AUTH,
	FIRST_LOGIN_SHOWN,
};
