import { type UpdateTableData, type ITableSettings, type IPaginateResponse, type Nullable } from 'shared/types';

enum AccountsTab {
	BASIC_DATA = 'main',
	WALLETS = 'wallets',
	CONNECTION = 'connection-type',
}

interface IAccountsService {
	getAccountsData: () => Promise<IAccountsData[]>;
	createAccountsData: (data: IAccountsDetails) => Promise<IAccountsDetails>;
	getAccountDetails: (id: string) => Promise<IAccountsDetails>;
	setAccountDetails: (id: string, data: IAccountsDetails) => Promise<IAccountsDetails>;
	toggleAccountsStatus: (id: string) => Promise<void>;
	updateAccountsData: (id: string, data: IUpdateAccountsData) => Promise<IAccountsData>;
	updateAccountsLogo: (id: string, logo: Blob) => Promise<IAccountsData>;
	getAccountWallets: (id: string, settings: ITableSettings) => Promise<IPaginateResponse<IAccountWalletDetails>>;
	getAccountIntegration: (id: string) => Promise<IConnectionData>;
	setAccountIntegration: (id: string, data: IConnectionData) => Promise<IConnectionData>;
	generateKey: () => Promise<string>;
	getAvailableIntegrations: (id: string) => Promise<IIntegrationData[]>;
	integrateWallet: (merchantAccountId: string, psAccountId: string) => Promise<void>;
}

interface IAccountWallet {
	title: string;
	status: number;
	currency: string;
	balance: number;
	logoUri: string;
}

interface IAccountWalletDetails {
	title: string;
	status: number;
	currency: string;
	balanceAmount: number;
	exchangeAmount: number;
	holdAmount: number;
	totalAmount: number;
	logoUri: string;
	services: string[];
}

type IUpdateAccountsData = Omit<IAccountsData, keyof IAccountsData>;

interface IAccountsData {
	id: string;
	businessId: string;
	title: string;
	status: number;
	logoUri: string;
	wallets: IAccountWallet[];
}

interface IAccountsDetails {
	logoUri: string;
	id: string;
	businessId: string;
	title: string;
	status: number;
	description: string;
	site: string;
}

type ColumnItem = {
	name: string;
	field: string;
	selected: boolean;
};

interface IConnectionData {
	paymentWayType: Nullable<string>;
	productSecret: string;
	testSecret: string;
	ipAddresses: string[];
}

interface IIntegrationData {
	accountId: string;
	logoUri: string;
	title: string;
	paymentMaxAmount: number;
	paymentMinAmount: number;
	paymentFee: number;
	paymentFixedFee: number;
	withdrawalMaxAmount: number;
	withdrawalMinAmount: number;
	withdrawalFee: number;
	withdrawalFixedFee: number;
}

interface IUpdateTableEvent extends Event {
	detail: UpdateTableData;
}

export {
	type IAccountsDetails,
	type IAccountsService,
	AccountsTab,
	type ColumnItem,
	type IUpdateTableEvent,
	type IConnectionData,
	type IAccountsData,
	type IUpdateAccountsData,
	type IAccountWalletDetails,
	type IIntegrationData,
};
