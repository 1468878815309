import { Button } from 'components/UI';
import React, { type FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { Modal, PageHeader } from 'components';
import check from 'assets/icons/check.svg';
import telegram from 'assets/icons/telegram.svg';
import AccountService from '../service';
import { integrateSelectedAccountWallet, setSelectedAccountWalletsAvailableIntegrations } from '../store/reducer';
import { type IIntegrationData } from '../types';
import routes from 'shared/constants/routes';
import { useAccountTabs } from '../hooks/useAccountTabs';
import type { Crumb } from 'components/PageHeader/types';
import { type Nullable } from 'shared/types';
import { config } from 'shared/constants/config';

const RequestConnection: FC = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { t } = useTranslation();
	const { id, tab } = useParams();
	const [loadingId, setLoadingId] = useState<Nullable<string>>(null);

	const availableIntegrations = useAppSelector(state => state.accounts.selectedAccount.wallets.availableIntegrations);

	const { tabs, activeTab, handleTabClick } = useAccountTabs({ id, tab });
	const { mainData } = useAppSelector(state => state.accounts.selectedAccount);

	const crumbs = useMemo<Crumb[]>(
		() => [
			{ name: t('modules.accounts.clientAccounts'), link: routes.accounts.index },
			{ name: mainData?.title ?? t('modules.wallets.connect') },
		],
		[t, mainData],
	);
	const [successModalOpened, setSuccessModalOpened] = useState<boolean>(false);

	const handleOpenConfirmationModal = useCallback(() => {
		setSuccessModalOpened(true);
	}, []);

	const handleCloseSuccessModalOpened = useCallback(() => {
		setSuccessModalOpened(false);
	}, []);

	const requestConnection = useCallback(
		async (accountId: string) => {
			if (!id) return;
			setLoadingId(accountId);
			await AccountService.integrateWallet(id, accountId);
			handleOpenConfirmationModal();
			dispatch(integrateSelectedAccountWallet(accountId));
			setLoadingId(null);
		},
		[dispatch, handleOpenConfirmationModal, id],
	);

	const goToTelegram = useCallback(() => {
		handleCloseSuccessModalOpened();
		window.open(config.telegramUrl, '_blank');
	}, [handleCloseSuccessModalOpened]);

	const goBack = useCallback(() => {
		// Dispatch(removeHistoryRecord());
		navigate(-1);
	}, [dispatch, navigate]);

	useEffect(() => {
		if (!id) return;
		void AccountService.getAvailableIntegrations(id).then(data => {
			dispatch(setSelectedAccountWalletsAvailableIntegrations(data));
		});
	}, [id]);

	const walletKeys = useMemo<string[]>(
		() => [
			'paymentMinAmount',
			'withdrawalMinAmount',
			'paymentMaxAmount',
			'withdrawalMaxAmount',
			'paymentFee',
			'withdrawalFee',
			'paymentFixedFee',
			'withdrawalFixedFee',
		],
		[],
	);

	return (
		<div>
			<PageHeader
				previousPage={routes.accounts.index}
				activeTab={activeTab}
				onTabClick={handleTabClick}
				crumbs={crumbs}
				tabs={tabs}
				title={t('modules.clientDetails.title')}
				className="mb-6"
			/>
			<Modal show={successModalOpened} onClose={handleCloseSuccessModalOpened}>
				<div className="max-w-[344px]">
					<div className="w-full">
						<img src={check} alt="success" className="mx-auto mb-3" />
					</div>
					<h3 className="text-center text-gray-900 font-medium mb-3">{t('ui.modal.requestSent')}</h3>
					<p className="text-center text-gray-500 font-sm text-xs mb-6">{t('modules.wallets.requestTelegram')}</p>
				</div>
				<div className="flex justify-between items-center">
					<Button
						data-test-id="admin-accounts-request-telegram"
						variant="create"
						onClick={goToTelegram}
						className="w-1/2 flex justify-center mr-3">
						<img src={telegram} alt="telegram" className="mr-2" />
						{t('ui.buttons.telegram')}
					</Button>
					<Button
						data-test-id="admin-accounts-request-close"
						variant="secondary"
						onClick={handleCloseSuccessModalOpened}
						className="w-1/2 flex justify-center">
						{t('ui.buttons.close')}
					</Button>
				</div>
			</Modal>

			<div className="mb-6">
				<button data-test-id="admin-accounts-request-backToTable" className="text-main font-medium text-sm" onClick={goBack}>
					{t('ui.table.backToTable')}
				</button>
			</div>

			{availableIntegrations.map(item => {
				return (
					<div key={item.title} className="mb-4 bg-gray-50 rounded-lg border border-gray-200 shadow-md overflow-hidden">
						<div className="flex items-center justify-between px-6 py-4 border-b">
							<div className="flex items-center">
								<img src={item.logoUri} className="w-9 h-6 rounded-full object-contain" alt="logo" />
								<div className="ml-3 text-sm">
									<div className="font-medium text-gray-900">{item.title}</div>
								</div>
							</div>
							<Button
								variant="secondary"
								isLoading={loadingId === item.accountId}
								onClick={() => {
									void requestConnection(item.accountId);
								}}
								data-test-id="admin-accounts-request-connect">
								{t('modules.wallets.requestConnection')}
							</Button>
						</div>

						<div className="grid grid-cols-2 bg-white overflow-auto py-4 px-6 gap-2">
							{walletKeys.map((k: string) => {
								const key = k as keyof IIntegrationData;
								const dontRenderKeys: string[] = ['accountId', 'logoUri', 'title'];
								const percentKeys: string[] = ['withdrawalFee', 'paymentFee'];
								return (
									!dontRenderKeys.includes(key) && (
										<div key={key} className="grid grid-cols-2">
											<div className="mr-6">{t(`modules.wallets.${key}`)}</div>
											<div className="text-gray-700">
												{typeof item[key] === 'number' ? Number(item[key]).toFixed(2) : item[key]}
												{percentKeys.includes(key) && '%'}
											</div>
										</div>
									)
								);
							})}
						</div>
					</div>
				);
			})}
		</div>
	);
};

export default RequestConnection;
