import React, { type FC, useCallback, useEffect } from 'react';
import { type ColDef } from 'ag-grid-community';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ExportIcon } from 'assets/icons/export.svg';
import { useAppDispatch, useAppSelector, useDebounce } from 'shared/hooks';
import { type ITableSettings, type ValueOf } from 'shared/types';
import { DEBOUNCE_DELAY } from 'shared/constants/delays';
import { CustomizableTable } from 'components';
import { setColumns, updateSettings } from './store/reducer';
import { loadOperationsAction } from './store/actions';
import { useColumns } from './hooks/useColumns';
import { type IOperation } from './types';
import OperationService from './service';

const Operations: FC = () => {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const { settings, columns, operations, isLoading, totalPages, itemsCount } = useAppSelector(state => state.operations);
	const initialCols = useColumns();

	const debouncedSettings = useDebounce<ITableSettings>(settings, DEBOUNCE_DELAY);

	const handleSettingUpdate = useCallback(
		(data: { key: keyof ITableSettings; value: ValueOf<ITableSettings> }) => {
			dispatch(updateSettings(data));
		},
		[dispatch],
	);

	const handleColumnsUpdate = useCallback(
		(columns: Array<ColDef<IOperation>>) => {
			dispatch(setColumns(columns));
		},
		[dispatch],
	);
	const handleExport = useCallback(async () => {
		const file = await OperationService.exportToExel(settings);
		const blob = new Blob([file]);
		const link = document.createElement('a');
		link.href = window.URL.createObjectURL(blob);
		link.download = 'export.xlsx';
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	}, [settings]);

	useEffect(() => {
		void dispatch(loadOperationsAction(settings));
	}, [debouncedSettings]);

	return (
		<div className="pb-10">
			<h1 className="text-gray-900 text-2xl mb-6 font-semibold">{t('modules.operations.title')}</h1>
			<CustomizableTable
				action={{
					text: (
						<div className="flex items-center">
							<ExportIcon className="mr-2" />
							{t('modules.operations.export')}
						</div>
					),
					onClick: handleExport,
					buttonType: 'secondary',
				}}
				itemsFound={Number(itemsCount)}
				columns={columns}
				initialCols={initialCols}
				settings={settings}
				isLoading={isLoading}
				onSettingUpdate={handleSettingUpdate}
				onColumnsUpdate={handleColumnsUpdate}
				rows={operations}
				totalPages={totalPages}
				navigateOnRowClick
				simplePagination
			/>
		</div>
	);
};

export default Operations;
