import React, { type FC, type ReactNode, useCallback, type MouseEvent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Button } from 'components/UI/index';

interface IProps {
	show: boolean;
	children: ReactNode;
	onClose: () => void;
	onConfirm: () => void;
	disableConfirm?: boolean;
}
const RightPanel: FC<IProps> = ({ children, show, onClose, onConfirm, disableConfirm }) => {
	const { t } = useTranslation();

	const handleClickOut = useCallback(
		(e: MouseEvent) => {
			if (e.target === e.currentTarget) onClose();
		},
		[onClose],
	);

	useEffect(() => {
		show ? document.body.classList.add('overflow-hidden') : document.body.classList.remove('overflow-hidden');
	}, [show]);

	return (
		<div onClick={handleClickOut} className={clsx('fixed inset-0 z-50 flex justify-end bg-gray-900/40', !show && 'hidden')}>
			<div className="min-w-[352px] bg-white rounded-l-md flex flex-col h-full justify-between py-10 px-8">
				<div>{children}</div>
				<div className="flex justify-between items-center">
					<Button data-test-id="client-columns-cancel" variant="secondary" onClick={onClose}>
						{t('ui.buttons.cancel')}
					</Button>
					<Button data-test-id="client-columns-confirm" variant="primary" onClick={onConfirm} disabled={disableConfirm}>
						{t('ui.buttons.confirm')}
					</Button>
				</div>
			</div>
		</div>
	);
};

export default RightPanel;
