import React, { type FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector, useValidation } from 'shared/hooks';
import { type Nullable } from 'shared/types';
import { Button, CopyInput, FormRow, Input, Select } from 'components/UI';
import { type Option } from 'components/UI/Select/types';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';
import { type IConnectionData } from '../types';
import clsx from 'clsx';
import AccountService from '../service';
import { setSelectedConnectionData } from '../store/reducer';

const ConnectionTab: FC = () => {
	const { t } = useTranslation();
	const { connectionData: selectedAccountConnectionData, id } = useAppSelector(state => state.accounts.selectedAccount);
	const { stringValidation, ipValidation } = useValidation();
	const dispatch = useAppDispatch();
	const enums = useAppSelector(state => state.app.enums);

	const [isEditing, setIsEditing] = useState<boolean>(false);

	const {
		trigger,
		watch,
		setValue,
		setError,
		clearErrors,
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<IConnectionData>({ mode: 'all', defaultValues: selectedAccountConnectionData, reValidateMode: 'onBlur' });
	const watcher = watch();

	const validateDuplicates = useCallback(() => {
		if (watcher.ipAddresses) {
			watcher.ipAddresses.forEach((address, idx) => {
				if (watcher.ipAddresses.indexOf(address) !== watcher.ipAddresses.lastIndexOf(address)) {
					if (!address) return;
					if (Array.isArray(errors.ipAddresses) && errors.ipAddresses[idx]?.type === 'duplicate') return;
					setError(`ipAddresses.${idx}`, { type: 'duplicate', message: t('validation.duplicate') });
					setError(`ipAddresses.${idx}`, { type: 'duplicate', message: t('validation.duplicate') });
				} else if (Array.isArray(errors.ipAddresses) && errors.ipAddresses[idx]?.type === 'duplicate') {
					clearErrors(`ipAddresses.${idx}`);
					void trigger('ipAddresses');
				}
			});
		}
	}, [watcher.ipAddresses, errors.ipAddresses, setError, t, clearErrors, trigger]);

	useEffect(validateDuplicates, [watcher, errors]);

	const [selectedType, setSelectedType] = useState<Nullable<Option>>(null);

	const typeOptions = useMemo<Option[]>(() => {
		if (!enums) return [];
		const options = Object.values(enums.PaymentWayType.values).map(({ id, value }) => ({ value: id, label: value }));
		return [{ value: 0, label: t('ui.select.default') }, ...options];
	}, [enums, t]);

	const handleTypeChange = useCallback(
		(type: Option) => {
			setValue('paymentWayType', type.value ? String(type.value) : null);
			setSelectedType(type);
		},
		[setValue],
	);

	const fillForm = useCallback(() => {
		if (!selectedAccountConnectionData) return;
		Object.entries(selectedAccountConnectionData).forEach(([key, value]) => {
			setValue(key as keyof IConnectionData, value, { shouldTouch: false });
		});
		clearErrors();
	}, [selectedAccountConnectionData, clearErrors, setValue]);

	const toggleEditing = useCallback(() => {
		setIsEditing(prev => !prev);
	}, []);

	const handleCancel = useCallback(() => {
		fillForm();
		toggleEditing();
		clearErrors();
	}, [clearErrors, fillForm, toggleEditing]);

	const handleAddIpRow = useCallback(() => {
		setValue('ipAddresses', [...watcher.ipAddresses, ''], { shouldTouch: false });
	}, [setValue, watcher.ipAddresses]);

	const handleRemoveIpRow = useCallback(
		(idx: number) => {
			setValue(
				'ipAddresses',
				watcher.ipAddresses.filter((_, id) => idx !== id),
				{ shouldTouch: false },
			);
			clearErrors(`ipAddresses.${idx}`);
			void trigger('ipAddresses');
		},
		[setValue, watcher.ipAddresses, clearErrors, trigger],
	);

	const handleConfirm = useCallback(
		async (data: IConnectionData) => {
			try {
				if (!id) return;
				const updated = await AccountService.setAccountIntegration(id, {
					...data,
					ipAddresses: data.ipAddresses.filter(ip => ip),
					paymentWayType: Number(data.paymentWayType) ? data.paymentWayType : null,
				});
				dispatch(setSelectedConnectionData(updated));
			} catch (e) {
				fillForm();
			} finally {
				toggleEditing();
			}
		},
		[dispatch, fillForm, id, toggleEditing],
	);

	const handleGenerateKey = useCallback(
		async (field: keyof IConnectionData) => {
			const key = await AccountService.generateKey();
			setValue(field, key);
			await trigger(field);
		},
		[setValue, trigger],
	);

	useEffect(fillForm, [fillForm]);

	useEffect(() => {
		if (!id) return;
		void AccountService.getAccountIntegration(id).then(data => dispatch(setSelectedConnectionData(data)));
	}, [id]);

	useEffect(() => {
		if (selectedType && String(selectedType.value) === watcher.paymentWayType) return;
		const selected = typeOptions.find(option => option.value === watcher.paymentWayType);
		if (selected) setSelectedType(selected);
		else setSelectedType(typeOptions[0]);
	}, [typeOptions, watcher.paymentWayType]);

	return (
		<div>
			<div className="flex justify-between items-center py-2">
				<h4 className="text-gray-900 text-lg font-medium min-h-[38px]">{t('modules.clientDetails.settings')}</h4>
				{!isEditing && (
					<Button data-test-id="client-account-connections-edit" variant="secondary" onClick={toggleEditing}>
						<EditIcon className="mr-2.5" />
						{t('ui.buttons.edit')}
					</Button>
				)}
			</div>
			<FormRow label={t('modules.clientDetails.connection.type')}>
				<Select
					testId="account-connections-status"
					placeholder={t('modules.clientDetails.placeholders.type')}
					disabled={!isEditing}
					options={typeOptions}
					value={selectedType}
					onChange={handleTypeChange}
					optionClassName="h-[38px] flex items-center justify-center"
					wrapperClassName="max-w-[512px] w-full"
					className="h-[38px]"
				/>
			</FormRow>

			<FormRow label={t('modules.clientDetails.connection.secret')}>
				<CopyInput
					data-test-id="client-account-connections-secret"
					disabled={!isEditing}
					wrapperClassName="max-w-[512px] w-full"
					isError={Boolean(errors.productSecret)}
					errorText={errors.productSecret?.message}
					onGenerate={() => {
						void handleGenerateKey('productSecret');
					}}
					{...register('productSecret', stringValidation)}
				/>
			</FormRow>
			<FormRow label={t('modules.clientDetails.connection.testSecret')}>
				<CopyInput
					data-test-id="client-account-connections-testSecret"
					disabled={!isEditing}
					wrapperClassName="max-w-[512px] w-full"
					isError={Boolean(errors.testSecret)}
					errorText={errors.testSecret?.message}
					onGenerate={() => {
						void handleGenerateKey('testSecret');
					}}
					{...register('testSecret', stringValidation)}
				/>
			</FormRow>

			<FormRow label={t('modules.clientDetails.connection.ip')}>
				<div className="flex flex-col items-start max-w-[512px] w-full">
					{watcher.ipAddresses.map((address, idx, array) => (
						<div key={idx} className={clsx('relative max-w-[512px] w-full', idx !== array.length - 1 && 'mb-4')}>
							<Input
								data-test-id={`client-account-connections-ip-${idx}`}
								placeholder={t('modules.clientDetails.placeholders.ip')}
								disabled={!isEditing}
								isError={errors.ipAddresses && Boolean(errors.ipAddresses[idx])}
								errorText={errors.ipAddresses ? errors.ipAddresses[idx]?.message : ''}
								{...register(`ipAddresses.${idx}`, ipValidation)}
							/>
							{isEditing && (
								<TrashIcon
									data-test-id={`client-account-connections-ip-${idx}-remove`}
									onClick={() => {
										handleRemoveIpRow(idx);
									}}
									className="absolute top-1/2 -translate-y-1/2 right-2 cursor-pointer"
								/>
							)}
						</div>
					))}
					{isEditing && (
						<Button data-test-id="client-account-connections-ip-add" variant="create" className="mt-4" onClick={handleAddIpRow}>
							{t('ui.buttons.add')}
						</Button>
					)}
				</div>
			</FormRow>

			{isEditing && (
				<div className="flex justify-between items-center mt-6">
					<Button data-test-id="client-account-connections-cancel" variant="secondary" onClick={handleCancel}>
						{t('ui.buttons.cancel')}
					</Button>
					<Button
						data-test-id="client-account-connections-confirm"
						variant="primary"
						onClick={handleSubmit(handleConfirm)}
						disabled={Boolean(Object.keys(errors).length)}>
						{t('ui.buttons.confirm')}
					</Button>
				</div>
			)}
		</div>
	);
};

export default ConnectionTab;
