import React, { type FC, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { AuditLog } from 'components';
import { LoadAuditLogsAction } from '../store/actions';

const AuditTab: FC = () => {
	const dispatch = useAppDispatch();
	const { id, logs } = useAppSelector(state => state.withdrawals.selectedWithdrawal);

	useEffect(() => {
		if (!id) return;
		void dispatch(LoadAuditLogsAction(id));
	}, [dispatch, id]);

	return (
		<div>
			{logs.map(log => (
				<AuditLog key={log.utcTimestamp} log={log} className="mb-2" />
			))}
		</div>
	);
};

export default AuditTab;
