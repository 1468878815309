import React, { type FC } from 'react';
import { type ICellRendererParams } from 'ag-grid-community';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

const BooleanRenderer: FC<ICellRendererParams<unknown>> = ({ value }) => {
	const { t } = useTranslation();

	return (
		<div className="h-full flex py-5">
			<span
				className={clsx(
					'py-0.5 px-2.5 rounded-xl font-medium text-xs ',
					value ? 'text-green-800 bg-green-100' : 'text-red-800 bg-red-100',
				)}>
				{value ? t('ui.table.enabled') : t('ui.table.disabled')}
			</span>
		</div>
	);
};

export default BooleanRenderer;
