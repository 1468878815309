import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import 'shared/styles/global.css';
import { store } from 'store/store';
import { Provider } from 'react-redux';
import './instrument';

const root = ReactDOM.createRoot(document.getElementById('root') as Element);
root.render(
	<Provider store={store}>
		<App />
	</Provider>,
);
