import { type RegisterOptions } from 'react-hook-form';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const useValidation = (): {
	numberValidation: RegisterOptions;
	stringValidation: RegisterOptions;
	urlValidation: RegisterOptions;
	emailValidation: RegisterOptions;
	passwordValidation: RegisterOptions;
	equalPasswordValidation: (first: string, second: string) => RegisterOptions;
	phoneNumberValidation: RegisterOptions;
	ipValidation: RegisterOptions;
	min: (number: number) => RegisterOptions;
	max: (number: number) => RegisterOptions;
} => {
	const { t } = useTranslation();

	const stringValidation = useMemo<RegisterOptions>(
		() => ({
			required: {
				value: true,
				message: t('validation.required'),
			},
			minLength: {
				value: 3,
				message: t('validation.minLength'),
			},
		}),
		[t],
	);

	const numberValidation = useMemo<RegisterOptions>(
		() => ({
			required: {
				value: true,
				message: t('validation.required'),
			},
			pattern: {
				value: /^(0|[1-9]\d*)(\.\d+)?$/,
				message: t('validation.number'),
			},
		}),
		[t],
	);

	const urlValidation = useMemo<RegisterOptions>(
		() => ({
			required: {
				value: true,
				message: t('validation.required'),
			},
			pattern: {
				value: /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?[a-z0-9\u00a1-\uffff]\.)+(?:[a-z\u00a1-\uffff]{2,}\.?))(?::\d{2,5})?(?:[/?#]\S*)?$/i,
				message: t('validation.url'),
			},
		}),
		[t],
	);

	const emailValidation = useMemo<RegisterOptions>(
		() => ({
			required: {
				value: true,
				message: t('validation.required'),
			},
			pattern: {
				value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
				message: t('validation.email'),
			},
		}),
		[t],
	);

	const passwordValidation = useMemo<RegisterOptions>(
		() => ({
			required: {
				value: true,
				message: t('validation.required'),
			},
			minLength: {
				value: 6,
				message: t('validation.min6Length'),
			},
			validate: (value: string) => {
				if (!value) return true;
				const specialChars = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
				if (!/\d/.test(value)) return t('validation.passwordNumber');
				if (!specialChars.test(value)) return t('validation.passwordChar');
				return true;
			},
		}),
		[t],
	);

	const equalPasswordValidation = useCallback(
		(first: string, second: string): RegisterOptions => {
			return {
				required: {
					value: true,
					message: t('validation.required'),
				},
				validate: () => {
					if (first === second || !second || !first) return true;
					return t('validation.equalPassword');
				},
			};
		},
		[t],
	);

	const min = useCallback(
		(number: number): RegisterOptions => {
			return {
				pattern: {
					value: /^(0|[1-9]\d*)(\.\d+)?$/,
					message: t('validation.number'),
				},
				min: {
					value: number,
					message: t('validation.maxNumber'),
				},
			};
		},
		[t],
	);

	const max = useCallback(
		(number: number): RegisterOptions => {
			return {
				pattern: {
					value: /^(0|[1-9]\d*)(\.\d+)?$/,
					message: t('validation.number'),
				},
				max: {
					value: number,
					message: t('validation.minNumber'),
				},
			};
		},
		[t],
	);

	const phoneNumberValidation = useMemo<RegisterOptions>(
		() => ({
			required: {
				value: true,
				message: t('validation.required'),
			},
			pattern: {
				value: /^\+[0-9]{12}/,
				message: t('validation.phoneNumber'),
			},
		}),
		[t],
	);
	const ipValidation = useMemo<RegisterOptions>(
		() => ({
			pattern: {
				value: /(?:^(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}$)|(?:^(?:(?:[a-fA-F\d]{1,4}:){7}(?:[a-fA-F\d]{1,4}|:)|(?:[a-fA-F\d]{1,4}:){6}(?:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|:[a-fA-F\d]{1,4}|:)|(?:[a-fA-F\d]{1,4}:){5}(?::(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,2}|:)|(?:[a-fA-F\d]{1,4}:){4}(?:(?::[a-fA-F\d]{1,4}){0,1}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,3}|:)|(?:[a-fA-F\d]{1,4}:){3}(?:(?::[a-fA-F\d]{1,4}){0,2}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,4}|:)|(?:[a-fA-F\d]{1,4}:){2}(?:(?::[a-fA-F\d]{1,4}){0,3}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,5}|:)|(?:[a-fA-F\d]{1,4}:){1}(?:(?::[a-fA-F\d]{1,4}){0,4}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,6}|:)|(?::(?:(?::[a-fA-F\d]{1,4}){0,5}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,7}|:)))(?:%[0-9a-zA-Z]{1,})?$)/gm,
				message: t('validation.ip'),
			},
		}),
		[t],
	);

	return {
		stringValidation,
		numberValidation,
		urlValidation,
		emailValidation,
		passwordValidation,
		phoneNumberValidation,
		min,
		max,
		ipValidation,
		equalPasswordValidation,
	};
};
