import React, { type FC, useMemo } from 'react';
import routes from 'shared/constants/routes';
import { PageHeader } from 'components';
import { useSettingsTabs } from './hooks/useSettingsTabs';
import { useParams } from 'react-router-dom';
import type { Crumb } from 'components/PageHeader/types';
import { useTranslation } from 'react-i18next';
import { SettingsTab } from './types';
import BasicDataTab from './components/BasicDataTab';
import ChangePasswordTab from './components/ChangePasswordTab';
import EmployeesTab from './components/EmployeesTab';
import SelectedEmployeeTab from './components/SelectedEmployeeTab';

const Settings: FC = () => {
	const { t } = useTranslation();

	const { tab, userId } = useParams();

	const { tabs, activeTab, handleTabClick } = useSettingsTabs({ tab });
	const crumbs = useMemo<Crumb[]>(
		() => [
			{ name: t('modules.settings.crumbTitle'), link: routes.home },
			{ name: t('modules.settings.title') },
		],
		[t],
	);
	return (
		<div className="pb-10">
			<PageHeader
				previousPage={routes.accounts.index}
				activeTab={activeTab}
				onTabClick={handleTabClick}
				crumbs={crumbs}
				tabs={tabs}
				title={t('modules.settings.pageTitle')}
				className="mb-6"
			/>

			{activeTab.value === SettingsTab.BASIC_DATA && <BasicDataTab />}
			{activeTab.value === SettingsTab.CHANGE_PASSWORD && <ChangePasswordTab />}
			{activeTab.value === SettingsTab.EMPLOYEES && (
				<>{userId ? <SelectedEmployeeTab /> : <EmployeesTab />}</>
			)}
		</div>
	);
};

export default Settings;
