export const exponentialToNumber = (n: number | null): string | number => {
	if (typeof n !== 'number') return '';
	const sign = Number(n) < 0 ? '-' : '';
	const toStr = n.toString();
	if (!/e/i.test(toStr)) {
		return n;
	}

	const [lead, decimal, pow] = n
		.toString()
		.replace(/^-/, '')
		.replace(/^([0-9]+)(e.*)/, '$1.$2')
		.split(/e|\./);
	return Number(pow) < 0
		? sign + '0.' + '0'.repeat(Math.max(Math.abs(Number(pow)) - 1 || 0, 0)) + lead + decimal
		: sign +
				lead +
				(Number(pow) >= decimal.length
					? decimal + '0'.repeat(Math.max(Number(pow) - decimal.length || 0, 0))
					: decimal.slice(0, Number(pow)) + '.' + decimal.slice(Number(pow)));
};
