import { type ILocaStorageService } from './types';
import { localStorageKeys } from './constants';

class LocalStorageService implements ILocaStorageService {
	public get<T>(key: string): T | null {
		const item = localStorage.getItem(key);
		try {
			return item ? JSON.parse(item) : null;
		} catch (e) {
			return item as T;
		}
	}

	public set(key: string, item: unknown): void {
		localStorage.setItem(key, JSON.stringify(item));
	}
}

const localStorageService = new LocalStorageService();

export { localStorageService, localStorageKeys };
