import axios, { type AxiosRequestHeaders } from 'axios';
import { toast } from 'react-toastify';
import { localStorageKeys, localStorageService } from 'shared/utils/localStorage';
import { customEventEmitter } from 'shared/utils';
import { type Error } from 'shared/types';

const axiosInstance = axios.create({
	baseURL: process.env.REACT_APP_BACKEND_URL,
});

axiosInstance.interceptors.request.use(
	config => {
		if (!config.headers) {
			// eslint-disable-next-line
			config.headers = {} as AxiosRequestHeaders;
		}

		config.headers['Accept-Language'] = localStorageService.get<string>(localStorageKeys.LANGUAGE);
		const accessToken: string | null = localStorageService.get<string>(localStorageKeys.ACCESS_TOKEN);

		if (accessToken) {
			config.headers.Authorization = `Bearer ${accessToken}`;
		}

		for (const key in config.data) {
			if (typeof config.data[key] !== 'string') continue;
			if (!config.data[key]) config.data[key] = null;
		}

		return config;
	},
	error => {
		void Promise.reject(error);
	},
);

axiosInstance.interceptors.response.use(
	response => {
		if (response.headers['x-client-isinactive'] === 'True') {
			customEventEmitter.trigger(customEventEmitter.events.FIRST_LOGIN);
		}

		return response;
	},
	async error => {
		const originalRequest = error.config;

		if (error.response.status === 401 && !originalRequest._retry) {
			if (location.pathname === '/auth-redirect') return await Promise.reject(error);
			customEventEmitter.trigger(customEventEmitter.events.UNAUTHORIZED_ERROR);
		}

		if (error.response.status === 403) {
			customEventEmitter.trigger(customEventEmitter.events.WRONG_USER_ERROR);
		}

		if (error.response.status === 404) {
			if (!error.request.responseURL.includes('blocked-external-user')) {
				customEventEmitter.trigger(customEventEmitter.events.DOESNT_EXIST_ERROR);
			}

			return await Promise.reject(error);
		}

		const errorResponse: Error = error.response.data;
		Object.entries(errorResponse.errors).forEach(([, value]) => {
			value.forEach(errorMsg =>
				toast.error(errorMsg, {
					position: 'top-right',
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: 0,
					theme: 'light',
				}),
			);
		});

		return await Promise.reject(error);
	},
);

export default axiosInstance;
