import { createAsyncThunk } from '@reduxjs/toolkit';
import type { IAuditLog, ITableSettings } from 'shared/types';
import {
	setWithdrawals,
	setTotalPages,
	setSelectedWithdrawalId,
	setSelectedWithdrawalData,
	setSelectedWithdrawalAuditLogs,
	setSelectedWithdrawalTransactions,
	setSelectedWithdrawalTransactionsTotalPages,
	setNewWithdrawalAccounts,
	setNewWithdrawalAmount,
	setNewWithdrawalFee,
	setNewWithdrawalTotalAmount,
} from './reducer';
import WithdrawalService from '../service';
const LOAD_WITHDRAWALS = 'withdrawals/load-all';
const LOAD_WITHDRAWAL = 'withdrawals/load-one';
const LOAD_WITHDRAWAL_ACCOUNTS = 'withdrawals/load-accounts';
const LOAD_WITHDRAWAL_TRANSACTIONS = 'withdrawals/load-transactions';
const CHECK_WITHDRAWAL_AMOUNT = 'withdrawals/check-amount';
const LOAD_LOGS_TYPE = 'operations/load-logs';

const loadWithdrawalsAction = createAsyncThunk(LOAD_WITHDRAWALS, async (settings: ITableSettings, { rejectWithValue, dispatch }) => {
	try {
		const {
			content,
			itemsCount,
			paging: { pageSize },
		} = await WithdrawalService.getAllWithdrawals(settings);

		dispatch(setWithdrawals(content));
		dispatch(setTotalPages(Math.ceil(itemsCount / pageSize) || 1));
	} catch (e) {
		return rejectWithValue(e);
	}
});

const loadWithdrawalAccountsAction = createAsyncThunk(LOAD_WITHDRAWAL_ACCOUNTS, async (_, { rejectWithValue, dispatch }) => {
	try {
		const accounts = await WithdrawalService.getAvailableAccounts();
		dispatch(setNewWithdrawalAccounts(accounts));
	} catch (e) {
		return rejectWithValue(e);
	}
});

const loadWithdrawalByIdAction = createAsyncThunk(LOAD_WITHDRAWAL, async (id: string, { rejectWithValue, dispatch }) => {
	try {
		const details = await WithdrawalService.getWithdrawalById(id);
		dispatch(setSelectedWithdrawalId(id));
		dispatch(setSelectedWithdrawalData(details));
	} catch (e) {
		return rejectWithValue(e);
	}
});

const loadWithdrawalTransactionsAction = createAsyncThunk(
	LOAD_WITHDRAWAL_TRANSACTIONS,
	async ({ settings, id }: { settings: ITableSettings; id: string }, { rejectWithValue, dispatch }) => {
		try {
			const {
				content,
				itemsCount,
				paging: { pageSize },
			} = await WithdrawalService.getWithdrawalTransactions(settings, id);

			dispatch(setSelectedWithdrawalTransactions(content));
			dispatch(setSelectedWithdrawalTransactionsTotalPages(Math.ceil(itemsCount / pageSize) || 1));
		} catch (e) {
			return rejectWithValue(e);
		}
	},
);

const checkWithdrawalAmountAction = createAsyncThunk(
	CHECK_WITHDRAWAL_AMOUNT,
	async ({ amount, walletId }: { walletId: string; amount: number }, { rejectWithValue, dispatch }) => {
		try {
			const { amount: checkedAmount, totalAmount, fee } = await WithdrawalService.checkAmount(walletId, amount);

			dispatch(setNewWithdrawalAmount(checkedAmount));
			dispatch(setNewWithdrawalFee(fee));
			dispatch(setNewWithdrawalTotalAmount(totalAmount));
		} catch (e) {
			return rejectWithValue(e);
		}
	},
);

const LoadAuditLogsAction = createAsyncThunk(LOAD_LOGS_TYPE, async (id: string, { rejectWithValue, dispatch }) => {
	try {
		const logs: IAuditLog[] = [];
		dispatch(setSelectedWithdrawalAuditLogs(logs));
	} catch (e) {
		return rejectWithValue(e);
	}
});

export {
	loadWithdrawalsAction,
	loadWithdrawalByIdAction,
	LoadAuditLogsAction,
	loadWithdrawalTransactionsAction,
	loadWithdrawalAccountsAction,
	checkWithdrawalAmountAction,
};
