import React, { type FC, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as PlaceholderIcon } from 'assets/icons/placeholder.svg';
import clsx from 'clsx';
import { useAuth } from 'modules/auth';

const Header: FC = () => {
	const { logout } = useAuth();
	const { t } = useTranslation();
	const [menuOpened, setMenuOpened] = useState<boolean>(false);
	const menuRef = useRef<HTMLDivElement>(null);

	const handleOpenMenu = useCallback(() => {
		setMenuOpened(prev => !prev);
	}, []);
	const handleCloseMenu = useCallback(() => {
		setMenuOpened(false);
	}, []);

	const handleClickOut = useCallback(
		(e: MouseEvent) => {
			if (!e.composedPath().includes(menuRef.current as Element)) {
				handleCloseMenu();
			}
		},
		[handleCloseMenu],
	);

	useEffect(() => {
		document.addEventListener('click', handleClickOut);
		return () => {
			document.removeEventListener('click', handleClickOut);
		};
		// eslint-disable-next-line
	}, []);

	return (
		<div className="p-4 bg-white border-b border-b-gray-200 shadow flex justify-end">
			<div
				onClick={handleOpenMenu}
				ref={menuRef}
				data-test-id="client-menu"
				className={clsx(
					'w-10 h-10 rounded-full relative cursor-pointer p-0.5 flex justify-center items-center bg-white',
					menuOpened && 'border-2 border-main',
				)}>
				<div className={clsx('w-8 h-8 rounded-full overflow-hidden flex justify-center items-center')}>
					<PlaceholderIcon />
				</div>
				{menuOpened && (
					<div className="absolute top-full w-[224px] right-0 shadow bg-white rounded-md overflow-hidden">
						<div
							data-test-id="client-logout"
							onClick={logout}
							className="text-sm text-gray-700 whitespace-nowrap cursor-pointer hover:bg-gray-50 px-4 py-2 duration-200">
							{t('modules.layout.logout')}
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default Header;
