import React, { type FC } from 'react';
import type { ITab } from 'shared/types';
import { type WithdrawalStep } from '../types';
import clsx from 'clsx';
import { ReactComponent as CheckIcon } from 'assets/icons/white-check.svg';

interface IProps {
	steps: ITab[];
	activeStep: WithdrawalStep;
}
const NewWithdrawalSteps: FC<IProps> = ({ steps, activeStep }) => {
	return (
		<div className="px-6 py-4 rounded-md border border-gray-200 flex items-center justify-between">
			{steps.map(step => (
				<div className="flex items-center" key={step.id}>
					<div
						className={clsx(
							'w-10 h-10 rounded-full border border-2 border-gray-300 flex justify-center items-center mr-4',
							activeStep === step.id && 'border-main',
							activeStep > step.id && 'border-main bg-main',
						)}>
						<span
							className={clsx(
								'text-sm font-medium text-gray-500',
								activeStep === step.id && 'text-main',
								activeStep > step.id && 'hidden',
							)}>
							0{step.id}
						</span>
						{activeStep > step.id && <CheckIcon />}
					</div>
					<div
						className={clsx(
							'max-w-[170px] text-sm font-medium text-gray-500',
							activeStep === step.id && 'text-main',
							activeStep > step.id && 'text-gray-900',
						)}>
						{step.text}
					</div>
				</div>
			))}
		</div>
	);
};

export default NewWithdrawalSteps;
