import React, {
	type ChangeEvent,
	forwardRef,
	type ForwardRefRenderFunction,
	type InputHTMLAttributes,
	type SyntheticEvent,
	useCallback,
	useState,
} from 'react';
import { Button } from '../index';
import { type Nullable } from 'shared/types';
import { useTranslation } from 'react-i18next';

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
	onUpload: (logo: Blob) => void;
	uploadedUrl?: string;
}
const ImageInput: ForwardRefRenderFunction<HTMLInputElement, IProps> = ({ value, uploadedUrl, onUpload, ...props }, ref) => {
	const { t } = useTranslation();

	const [image, setImage] = useState<Nullable<File>>(null);

	const onFileChange = useCallback(
		async (event: ChangeEvent<HTMLInputElement>) => {
			if (!event.target.files) return;
			const [file] = event.target.files;
			setImage(file);
			await file.arrayBuffer().then(arrayBuffer => {
				const blob = new Blob([new Uint8Array(arrayBuffer)], { type: file.type });
				onUpload(blob);
			});
		},
		[onUpload],
	);
	return (
		<div className="flex items-center">
			{image && <img src={URL.createObjectURL(image)} alt="" className="max-w-[60px] h-auto mr-2" />}
			{!image && uploadedUrl && (
				<img
					src={`${uploadedUrl}?cache=${Date.now()}`}
					alt=""
					className="max-w-[60px] h-auto"
					onLoad={(event: SyntheticEvent) => {
						event.currentTarget.classList.add('mr-2');
					}}
				/>
			)}
			<div className="relative">
				<input
					data-test-id="client-file-input"
					ref={ref}
					type="file"
					accept="image/jpg, image/jpeg, image/png"
					multiple={false}
					className="absolute block w-full h-full inset-0 opacity-0 z-20  cursor-pointer"
					{...props}
					onChange={onFileChange}
				/>
				<Button variant="secondary" className="cursor-pointer">
					{image ? t('ui.input.change') : t('ui.input.upload')}
				</Button>
			</div>
		</div>
	);
};

export default forwardRef<HTMLInputElement, IProps>(ImageInput);
