import React, { type FC, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import type { ColDef } from 'ag-grid-community';
import { useAppDispatch, useAppSelector, useDebounce } from 'shared/hooks';
import type { ITableSettings, ValueOf } from 'shared/types';
import { DEBOUNCE_DELAY } from 'shared/constants/delays';
import routes from 'shared/constants/routes';
import { CustomizableTable } from 'components';
import { Button, Tooltip } from 'components/UI';
import { ReactComponent as ExportIcon } from 'assets/icons/export.svg';
import { setColumns, updateSettings } from './store/reducer';
import { type IWithdrawal } from './types';
import { useWithdrawalsCols } from './hooks/useWithdrawalsCols';
import { loadWithdrawalsAction } from './store/actions';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { useNavigate } from 'react-router-dom';
import WithdrawalService from './service';

const Withdrawals: FC = () => {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { settings, columns, withdrawals, isLoading, totalPages } = useAppSelector(state => state.withdrawals);
	const initialCols = useWithdrawalsCols();
	const { has2fa } = useAppSelector(state => state.app);

	const debouncedSettings = useDebounce<ITableSettings>(settings, DEBOUNCE_DELAY);

	const handleSettingUpdate = useCallback(
		(data: { key: keyof ITableSettings; value: ValueOf<ITableSettings> }) => {
			dispatch(updateSettings(data));
		},
		[dispatch],
	);

	const handleColumnsUpdate = useCallback(
		(columns: Array<ColDef<IWithdrawal>>) => {
			dispatch(setColumns(columns));
		},
		[dispatch],
	);
	const handleExport = useCallback(async () => {
		const file = await WithdrawalService.exportToExel(settings);
		const blob = new Blob([file]);
		const link = document.createElement('a');
		link.href = window.URL.createObjectURL(blob);
		link.download = 'export.xlsx';
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	}, [settings]);

	const handleCreateWithdrawal = useCallback(async () => {
		navigate(routes.withdrawals.create);
	}, [navigate]);

	useEffect(() => {
		void dispatch(loadWithdrawalsAction(settings));
	}, [debouncedSettings]);

	return (
		<div className="pb-10">
			<div className="flex justify-between items-center mb-6">
				<h1 className="text-gray-900 text-2xl font-semibold">{t('modules.withdrawals.title')}</h1>
				<Button disabled={!has2fa} variant="create" onClick={handleCreateWithdrawal}>
					<PlusIcon className="mr-3" />
					<span>{t('modules.withdrawals.create')}</span>
					{!has2fa && <Tooltip title={t('ui.tooltip.2fa.title')} text={t('ui.tooltip.2fa.text')} />}
				</Button>
			</div>
			<CustomizableTable
				action={{
					text: (
						<div className="flex items-center">
							<ExportIcon className="mr-2" />
							{t('modules.operations.export')}
						</div>
					),
					onClick: handleExport,
					buttonType: 'secondary',
				}}
				columns={columns}
				initialCols={initialCols}
				settings={settings}
				isLoading={isLoading}
				onSettingUpdate={handleSettingUpdate}
				onColumnsUpdate={handleColumnsUpdate}
				rows={withdrawals}
				totalPages={totalPages}
				navigateOnRowClick
			/>
		</div>
	);
};

export default Withdrawals;
