export const config = {
	apiUrl: process.env.REACT_APP_BACKEND_URL ?? '',
	env: process.env.REACT_APP_ENV ?? '',
	oidcAuthority: process.env.REACT_APP_OIDC_AUTHORITY ?? '',
	oidcClientId: process.env.REACT_APP_OIDC_CLIENT_ID ?? '',
	oidcRedirectUri: process.env.REACT_APP_OIDC_REDIRECT_URI ?? '',
	oidcScope: process.env.REACT_APP_OIDC_SCOPE ?? '',
	oidcLogoutRedirect: process.env.REACT_APP_OIDC_POST_LOGOUT_REDIRECT_URI ?? '',
	telegramUrl: 'https://t.me/Obmenka_demo',
	storageUrl: process.env.REACT_APP_STORAGE_URL ?? '',
	oidcRenewTokenUrl: process.env.REACT_APP_OIDC_TOKEN_RENEW_URL ?? '',
};
