import { createAsyncThunk } from '@reduxjs/toolkit';
import { type ITableSettings } from 'shared/types';
import OperationService from '../service';
import { setMaxItems, setOperations, setSelectedOperationData, setSelectedOperationId, setTotalCount, setTotalPages } from './reducer';

const LOAD_OPERATIONS = 'operations/load';
const LOAD_OPERATION_BY_ID = 'operations/load-by-id';

const loadOperationsAction = createAsyncThunk(LOAD_OPERATIONS, async (settings: ITableSettings, { rejectWithValue, dispatch }) => {
	try {
		const {
			content,
			itemsCount,
			maxRecordsForReport,
			paging: { pageSize },
		} = await OperationService.getAllOperations(settings);

		dispatch(setOperations(content));
		dispatch(setTotalPages(Math.ceil(itemsCount / pageSize) || 1));
		dispatch(setTotalCount(itemsCount));
		if (maxRecordsForReport) dispatch(setMaxItems(maxRecordsForReport));
	} catch (e) {
		return rejectWithValue(e);
	}
});

const loadOperationByIdAction = createAsyncThunk(LOAD_OPERATION_BY_ID, async (id: string, { rejectWithValue, dispatch }) => {
	try {
		const details = await OperationService.getOperationById(id);
		dispatch(setSelectedOperationId(id));
		dispatch(setSelectedOperationData(details));
	} catch (e) {
		return rejectWithValue(e);
	}
});

export { loadOperationsAction, loadOperationByIdAction };
