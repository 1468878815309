import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import routes from 'shared/constants/routes';
import { type ITab } from 'shared/types';
import { WithdrawalTab } from '../types';

type ReturnType = {
	tabs: ITab[];
	activeTab: ITab;
	handleTabClick: (tab: ITab) => void;
};
export const useWithdrawalTabs = ({ id, tab }: { id?: string; tab?: string }): ReturnType => {
	const navigate = useNavigate();
	const { t } = useTranslation();

	const tabs = useMemo<ITab[]>(
		() => [
			{
				id: 1,
				value: WithdrawalTab.BASIC_DATA,
				text: t('ui.tabs.basicData'),
			},
			{
				id: 2,
				value: WithdrawalTab.TRANSACTIONS,
				text: t('ui.tabs.transactions'),
			},
		],
		[t],
	);

	const activeTab = useMemo<ITab>(() => {
		return tabs.find(tb => tb.value === tab) ?? tabs[0];
	}, [tab, tabs]);

	const handleTabClick = useCallback(
		(tab: ITab) => {
			if (!id || tab.value === activeTab.value) return;
			navigate(routes.withdrawals.withdrawal(id, tab.value));
		},
		[id, navigate, activeTab],
	);

	return { tabs, activeTab, handleTabClick };
};
