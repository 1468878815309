import React, { type FC, type InputHTMLAttributes } from 'react';
import clsx from 'clsx';

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
	label: string;
	wrapperClassName?: string;
}
const Checkbox: FC<IProps> = ({ label, wrapperClassName, disabled, ...rest }) => (
	<div className={clsx('flex items-center w-fit', wrapperClassName, disabled ? 'cursor-not-allowed' : 'cursor-pointer')}>
		<label className={clsx('text-sm font-medium text-gray-700 flex items-center cursor-pointer', disabled && 'pointer-events-none')}>
			<div className="w-4 h-4 mr-3 border border-gray-300 rounded text-blue-600 relative">
				<input {...rest} type="checkbox" className="w-full h-full opacity-0 peer cursor-pointer" />
				<div
					className={clsx(
						'peer-checked:flex hidden bg-main rounded border-main absolute -inset-px justify-center items-center',
						disabled && 'bg-gray-500',
					)}>
					<svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path
							d="M9.20741 0.792787C9.39488 0.980314 9.50019 1.23462 9.50019 1.49979C9.50019 1.76495 9.39488 2.01926 9.20741 2.20679L4.20741 7.20679C4.01988 7.39426 3.76557 7.49957 3.50041 7.49957C3.23524 7.49957 2.98094 7.39426 2.79341 7.20679L0.793407 5.20679C0.611249 5.01818 0.510455 4.76558 0.512733 4.50339C0.515012 4.24119 0.62018 3.99038 0.805589 3.80497C0.990997 3.61956 1.24181 3.51439 1.50401 3.51211C1.7662 3.50983 2.0188 3.61063 2.20741 3.79279L3.50041 5.08579L7.79341 0.792787C7.98094 0.605316 8.23524 0.5 8.50041 0.5C8.76557 0.5 9.01988 0.605316 9.20741 0.792787Z"
							fill="white"
						/>
					</svg>
				</div>
			</div>

			{label}
		</label>
	</div>
);

export default Checkbox;
