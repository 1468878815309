import React, { type FC, type HTMLAttributes, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import clsx from 'clsx';
import { type IAuditLog } from 'shared/types';
import { Button } from 'components/UI';

interface IProps extends HTMLAttributes<HTMLDivElement> {
	log: IAuditLog;
}
const AuditLog: FC<IProps> = ({ log: { utcTimestamp, message }, className }) => {
	const { t } = useTranslation();
	const [expanded, setExpanded] = useState<boolean>(false);

	const toggleExpanded = useCallback(() => {
		setExpanded(prev => !prev);
	}, []);

	return (
		<div className={clsx('bg-gray-50 rounded-lg p-6', className)}>
			<div className="flex justify-between items-center">
				<h4 className="font-medium text-sm text-gray-900">{dayjs(utcTimestamp).format('HH:mm:ss, DD.MM.YYYY')}</h4>
				{!expanded && (
					<Button data-test-id="client-audit-view" onClick={toggleExpanded} variant="secondary">
						{t('ui.buttons.view')}
					</Button>
				)}
			</div>
			<div className={clsx('flex justify-between items-end overflow-hidden', expanded ? 'mt-2' : 'h-0')}>
				<pre className="text-gray-500 text-sm font-normal" style={{ fontFamily: 'Inter, sans-serif' }}>
					{JSON.stringify(JSON.parse(message), null, 2)}
				</pre>
				<Button data-test-id="client-audit-hide" onClick={toggleExpanded} variant="secondary">
					{t('ui.buttons.hide')}
				</Button>
			</div>
		</div>
	);
};

export default AuditLog;
