import React, { type FC, type ReactNode } from 'react';
import clsx from 'clsx';

interface IProps {
	children: ReactNode;
	label: string;
	wrapperClassName?: string;
}
const FormRow: FC<IProps> = ({ children, label, wrapperClassName }) => {
	return (
		<div className="py-5 border-b border-b-gray-200">
			<div className={clsx(wrapperClassName, 'max-w-[900px] flex justify-between items-center')}>
				<label className="text-gray-700 text-sm font-medium">{label}</label>
				{children}
			</div>
		</div>
	);
};

export default FormRow;
