import React, { type FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import routes from 'shared/constants/routes';
import { useOperationsTabs } from './hooks/useOperationsTab';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { OperationTab } from './types';

import { PageHeader } from 'components';
import type { Crumb } from 'components/PageHeader/types';
import BasicDataTab from './components/BasicDataTab';
import { loadOperationByIdAction } from './store/actions';

const Oparetion: FC = () => {
	const { t } = useTranslation();
	const { id, tab } = useParams();
	const dispatch = useAppDispatch();
	const { tabs, activeTab, handleTabClick } = useOperationsTabs({ id, tab });

	const { mainData } = useAppSelector(state => state.operations.selectedOperation);

	const crumbs = useMemo<Crumb[]>(
		() => [{ name: t('modules.operations.title'), link: routes.operations.index }, { name: mainData?.id ?? '' }],
		[t, mainData],
	);

	useEffect(() => {
		if (!id) return;
		void dispatch(loadOperationByIdAction(id));
	}, [id]);

	return (
		<div className="pb-6">
			<PageHeader
				previousPage={routes.operations.index}
				activeTab={activeTab}
				onTabClick={handleTabClick}
				crumbs={crumbs}
				tabs={tabs}
				title={t('modules.operations.operationDetails')}
				className="mb-6"
			/>
			<div>{activeTab.value === OperationTab.BASIC_DATA && <BasicDataTab />}</div>
		</div>
	);
};

export default Oparetion;
