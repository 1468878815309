import React, { type FC, useCallback, useState } from 'react';
import { Button, FormRow } from 'components/UI';
import { useTranslation } from 'react-i18next';
import { exponentialToNumber } from 'shared/helpers';
import { useAppSelector } from 'shared/hooks';

interface IProps {
	onConfirm: () => Promise<void>;
	onCancel: () => void;
}
const ConfirmStep: FC<IProps> = ({ onConfirm, onCancel }) => {
	const { t } = useTranslation();
	const { amount, address, selectedWallet, fee, totalAmount } = useAppSelector(
		state => state.withdrawals.new,
	);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const handleConfirm = useCallback(async () => {
		setIsLoading(true);
		await onConfirm();
		setIsLoading(false);
	}, [onConfirm]);

	return (
		<div>
			<FormRow label={t('modules.withdrawals.new.fields.currency')}>
				<div className="max-w-[512px] w-full flex items-center h-[38px]">
					<div className="text-sm text-gray-500">
						{selectedWallet?.currency}
					</div>
				</div>
			</FormRow>
			<FormRow label={t('modules.withdrawals.new.fields.account')}>
				<div className="max-w-[512px] w-full flex items-center h-[38px]">
					<div className="text-sm text-gray-500">{address?.destination}</div>
				</div>
			</FormRow>
			<FormRow label={t('modules.withdrawals.new.fields.amount')}>
				<div className="max-w-[512px] w-full flex items-center h-[38px]">
					<div className="text-sm text-gray-500">
						{exponentialToNumber(amount)}
					</div>
				</div>
			</FormRow>
			<FormRow label={t('modules.withdrawals.new.fields.fee')}>
				<div className="max-w-[512px] w-full flex items-center h-[38px]">
					<div className="text-sm text-gray-500">
						{exponentialToNumber(fee)}
					</div>
				</div>
			</FormRow>
			<FormRow label={t('modules.withdrawals.new.fields.netAmount')}>
				<div className="max-w-[512px] w-full flex items-center h-[38px]">
					<div className="text-sm text-gray-500">
						{exponentialToNumber(totalAmount)}
					</div>
				</div>
			</FormRow>
			<div className="flex justify-between items-center mt-6">
				<Button variant="secondary" onClick={onCancel}>
					{t('ui.buttons.cancel')}
				</Button>
				<Button isLoading={isLoading} variant="primary" onClick={handleConfirm}>
					{t('ui.buttons.confirm')}
				</Button>
			</div>
		</div>
	);
};

export default ConfirmStep;
