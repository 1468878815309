import { type ITableSettings, Order } from 'shared/types';

export const initialSettings: ITableSettings = {
	Keyword: '',
	OrderBy: '',
	OrderDir: Order.EMPTY,
	PageNumber: 1,
	PageSize: 25,
	Type: '',
};
