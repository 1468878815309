import React, { type FC, type SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { type Nullable } from 'shared/types';
import { Button, RadioInput } from 'components/UI';
import clsx from 'clsx';
import { type IWithdrawalAccount, type IWithdrawalAccountWallet } from '../types';

interface IProps {
	accounts: IWithdrawalAccount[];
	onConfirm: (wallet: IWithdrawalAccountWallet) => void;
	onCancel: () => void;
	wallet: Nullable<IWithdrawalAccountWallet>;
}
const ChooseAccountStep: FC<IProps> = ({ accounts, onConfirm, onCancel, wallet }) => {
	const { t } = useTranslation();
	const [selectedWallet, setSelectedWallet] = useState<Nullable<IWithdrawalAccountWallet>>(wallet);

	useEffect(() => {
		if (selectedWallet ?? !accounts.length) return;

		setSelectedWallet(accounts[0].wallets[0]);
	}, [accounts]);

	const handleConfirm = useCallback(() => {
		if (!selectedWallet) return;

		onConfirm(selectedWallet);
	}, [selectedWallet]);
	return (
		<div className="pb-8">
			<h1 className="text-gray-900 text-2xl font-semibold mb-5">{t('modules.accounts.title')}</h1>
			{accounts
				.filter(account => account.wallets.length)
				.map((account, index) => (
					<div key={account.businessId} className="mb-5">
						<div className="flex items-center w-full bg-white rounded-md border border-gray-200 py-4 px-6 mb-2">
							{account.logoUri && (
								<img
									src={`${account.logoUri}?cache=${Date.now()}`}
									onError={(event: SyntheticEvent) => {
										event.currentTarget.classList.add('hidden');
									}}
									className="mr-3 w-10 h-10 rounded-full"
									alt="logo"
								/>
							)}
							<div className="text-sm">
								<div className="font-medium">{account.title}</div>
								<div className="text-gray-500">ID {account.businessId}</div>
							</div>
						</div>
						<div className="flex items-center max-w-full overflow-x-auto">
							{account.wallets.map((wallet, idx) => {
								return (
									<div
										key={wallet.title}
										className={clsx('cursor-pointer', idx !== account.wallets.length - 1 && 'mr-4')}
										onClick={() => {
											setSelectedWallet(wallet);
										}}>
										<div className="flex bg-white rounded-lg border border-gray-200 py-3 px-2 min-w-[228px]">
											<RadioInput
												name="wallet"
												wrapperClassName="mr-2"
												onChange={() => {
													setSelectedWallet(wallet);
												}}
												checked={selectedWallet?.id === wallet.id}
											/>
											<div>
												<div className="flex items-center">
													{wallet.logoUri && (
														<img
															src={wallet.logoUri}
															className="w-6 h-6 rounded-full object-contain mr-2"
															alt="logo"
														/>
													)}
													<div className="text-gray-900 font-medium text-sm max-w-[250px] text-ellipsis  overflow-hidden">
														{wallet.title}, {wallet.currency}
													</div>
												</div>
												<div className="text-sm mt-2">
													<div className="text-gray-700 font-bold text-sm text-ellipsis max-w-[250px] overflow-hidden">
														{wallet.currency === 'BTC'
															? wallet.availableAmount
															: wallet.availableAmount.toFixed(2)}
													</div>
												</div>
											</div>
										</div>
									</div>
								);
							})}
						</div>
					</div>
				))}
			<div className="flex justify-between items-center mt-6">
				<Button variant="secondary" onClick={onCancel}>
					{t('ui.buttons.cancel')}
				</Button>
				<Button variant="primary" onClick={handleConfirm} disabled={!selectedWallet}>
					{t('ui.buttons.continue')}
				</Button>
			</div>
		</div>
	);
};

export default ChooseAccountStep;
