enum SettingsTab {
	BASIC_DATA = 'main',
	CHANGE_PASSWORD = 'change-password',
	EMPLOYEES = 'employees',
}

interface ISettingsService {
	getSettings: () => Promise<ISettingsData>;
	setSettings: (settings: ISettingsData) => Promise<ISettingsData>;
	updatePassword: (data: IChangePasswordData) => Promise<void>;
	getTwoStepData: (enableTwoFactor: boolean) => Promise<ITwoStepData>;
	confirmTwoStep: (code: string) => Promise<{ recoveryCodes: string[] }>;
	getRecoveryCodes: () => Promise<{ recoveryCodes: string[] }>;
	toggleIpChecked: (flag: boolean) => Promise<void>;
}

interface ISettingsData {
	title: string;
	businessWebsite: string;
	companyEmail: string;
	companyPhone: string;
	companyAddress: string;
	ipCheckEnabled: boolean;
	twoStepIdentificationEnabled: boolean;
}

interface ITwoStepData {
	qrCodeUri: string;
	sharedKey: string;
}

interface IChangePasswordData {
	oldPassword: string;
	newPassword: string;
	confirmNewPassword: string;
}

interface IMerchantUser {
	id: string;
	email: string;
	userName: string;
	roleName: string;
	roleValue: string;
	phoneNumber: string;
	password?: string;
	repeatPassword?: string;
	status: number;
	canCreateAccount: boolean;
	accounts: string;
	accountRights: Account[];
	createdAt: string;
	updatedAt: string;
	createdBy: string;
	updatedBy: string;
}

type Account = {
	title: string;
	rights: Rights;
};

type Rights = {
	account: {
		canOpen: boolean;
		canEdit: boolean;
		canOpenBalance: boolean;
	};
	payment: {
		canOpen: boolean;
		canCreate: boolean;
	};
	affiliate: {
		canOpen: boolean;
	};
};

enum AuthStep {
	REGISTER = 1,
	VERIFICATION = 2,
	COPY = 3,
}

export { SettingsTab, AuthStep };

export type { ISettingsData, IChangePasswordData, IMerchantUser, ISettingsService, ITwoStepData };
