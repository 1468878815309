import React, { forwardRef, type ForwardRefRenderFunction, type InputHTMLAttributes } from 'react';
import clsx from 'clsx';
import classes from './TextArea.module.scss';

interface IProps extends InputHTMLAttributes<HTMLTextAreaElement> {
	wrapperClassName?: string;
	isError?: boolean;
	errorText?: string;
}
const TextArea: ForwardRefRenderFunction<HTMLTextAreaElement, IProps> = (
	{ className, wrapperClassName, isError, errorText, ...rest },
	ref,
) => {
	return (
		<div className={clsx('relative', wrapperClassName, rest.disabled && 'cursor-not-allowed')}>
			<textarea
				ref={ref}
				{...rest}
				className={clsx(
					className,
					classes.input,
					'px-4 cursor-pointer min-h-[76px] hover:bg-indigo-50 block py-2 w-full rounded-md border shadow-sm focus:border-indigo-500 text-sm placeholder:text-gray-400 disabled:border-gray-300 disabled:pointer-events-none disabled:bg-gray-200',
					isError && '!border-red-600 focus:shadow-none',
				)}></textarea>
			{isError && <span className="absolute top-full left-0 translate-y-px text-xs text-red-600">{errorText}</span>}
		</div>
	);
};

export default forwardRef<HTMLTextAreaElement, IProps>(TextArea);
