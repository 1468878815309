import { useMemo } from 'react';
import { type ColDef } from 'ag-grid-community';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'shared/hooks';
import routes from 'shared/constants/routes';
import { HeaderComponent, TextRenderer } from 'components/Table/components';
import { type IMerchantUser } from '../types';

export const useEmployeesColumns = (): Array<ColDef<IMerchantUser>> => {
	const { t } = useTranslation();

	const { enums, roles } = useAppSelector(state => state.app);
	const settings = useAppSelector(state => state.settings.employees.settings);
	return useMemo<Array<ColDef<IMerchantUser>>>(() => {
		if (!enums) return [];
		return [
			{
				field: 'userName',
				minWidth: 136,
				cellRenderer: TextRenderer,
				headerName: t('modules.settings.employees.fields.name'),
				headerComponent: HeaderComponent,
				headerClass: 'bg-gray-50 border-b border-b-gray-200',
				suppressMovable: true,
				cellEditor: {
					linkCreator: (id: string) => routes.settings.employee(id),
					value: settings.UserName,
					orderBy: settings.OrderBy,
					orderDir: settings.OrderDir,
				},
			},
			{
				field: 'email',
				minWidth: 128,
				cellRenderer: TextRenderer,
				headerName: t('modules.settings.employees.fields.email'),
				headerComponent: HeaderComponent,
				headerClass: 'bg-gray-50 border-b border-b-gray-200',
				suppressMovable: true,
				cellEditor: {
					value: settings.Email,
					orderBy: settings.OrderBy,
					orderDir: settings.OrderDir,
				},
			},
			{
				field: 'phoneNumber',
				minWidth: 128,
				cellRenderer: TextRenderer,
				headerName: t('modules.settings.employees.fields.phone'),
				headerComponent: HeaderComponent,
				headerClass: 'bg-gray-50 border-b border-b-gray-200',
				suppressMovable: true,
				cellEditor: {
					value: settings.PhoneNumber,
					orderBy: settings.OrderBy,
					orderDir: settings.OrderDir,
				},
			},

			{
				field: 'roleName',
				minWidth: 128,
				cellRenderer: TextRenderer,
				headerName: t('modules.settings.employees.fields.role'),
				headerComponent: HeaderComponent,
				headerClass: 'bg-gray-50 border-b border-b-gray-200',
				suppressMovable: true,
				cellEditor: {
					filterOptions: roles.map(({ name, value }) => ({ id: name, value })),
					value: settings.RoleName,
					orderBy: settings.OrderBy,
					orderDir: settings.OrderDir,
				},
			},
		];
	}, [enums, roles, settings, t]);
};
