import type { IPaginateResponse, ITableSettings } from 'shared/types';
import { settingsToSearchParams } from 'shared/helpers';
import axiosInstance from 'shared/libs/axios';
import api from 'shared/constants/api';
import {
	type IWithdrawal,
	type IWithdrawalDetails,
	type IWithdrawalService,
	type ITransaction,
	type IWithdrawalAccount,
	type AmountResponse,
	type IPostWithdrawalBody,
} from '../types';

class WithdrawalService implements IWithdrawalService {
	async getAllWithdrawals(settings: ITableSettings): Promise<IPaginateResponse<IWithdrawal>> {
		const query = settingsToSearchParams(settings);
		return (await axiosInstance.get<IPaginateResponse<IWithdrawal>>(api.WITHDRAWALS.GET_ALL + `?${query}`)).data;
	}

	async getWithdrawalById(id: string): Promise<IWithdrawalDetails> {
		return (await axiosInstance.get(api.WITHDRAWALS.GET_ONE(id))).data;
	}

	async getWithdrawalTransactions(settings: ITableSettings, withdrawalId: string): Promise<IPaginateResponse<ITransaction>> {
		const query = settingsToSearchParams(settings);
		return (await axiosInstance.get<IPaginateResponse<ITransaction>>(`${api.WITHDRAWALS.GET_TRANSACTIONS(withdrawalId)}?${query}`))
			.data;
	}

	async getAvailableAccounts(): Promise<IWithdrawalAccount[]> {
		return (await axiosInstance.get(api.WITHDRAWALS.GET_AVAILABLE)).data;
	}

	async checkDestination(walletId: string, destination: string): Promise<void> {
		return (await axiosInstance.post(api.WITHDRAWALS.CHECK_DESTINATION(walletId), { destination })).data;
	}

	async checkAmount(walletId: string, amount: number): Promise<AmountResponse> {
		return (await axiosInstance.post(api.WITHDRAWALS.CHECK_AMOUNT(walletId), { amount })).data;
	}

	async postWithdrawal(walletId: string, data: IPostWithdrawalBody): Promise<void> {
		return (await axiosInstance.post(api.WITHDRAWALS.POST_WITHDRAWAL(walletId), data)).data;
	}

	async exportToExel(settings: ITableSettings): Promise<Blob> {
		const temp = structuredClone(settings);
		delete temp.OrderBy;
		delete temp.OrderDir;
		delete temp.PageNumber;
		delete temp.PageSize;
		delete temp.isFiltersHidden;
		delete temp.isSortingHidden;
		const query = settingsToSearchParams(temp);

		return (await axiosInstance.get(`${api.WITHDRAWALS.EXPORT}?${query}`, { responseType: 'blob' })).data;
	}
}

export default new WithdrawalService();
