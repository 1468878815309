import React, { type FC, useState } from 'react';

interface IProps {
	title: string;
	text: string;
}
const Tooltip: FC<IProps> = ({ title, text }) => {
	const [show, setShow] = useState(false);
	const handleShow = (): void => {
		setShow(true);
	};

	const handleHide = (): void => {
		setShow(false);
	};

	return (
		<div className="absolute inset-0 z-10" onMouseEnter={handleShow} onMouseLeave={handleHide}>
			{show && (
				<div className="absolute top-full right-0 translate-y-4 bg-white rounded-lg p-3 z-10">
					<div className="absolute top-0 left-3/4  w-4 h-4 bg-white -translate-y-1/3 rotate-45" />
					<h3 className="whitespace-nowrap text-left text-gray-900 font-bold text-xs mb-1">{title}</h3>
					<h4 className="whitespace-nowrap text-left text-xs text-gray-600 font-normal">{text}</h4>
				</div>
			)}
		</div>
	);
};

export default Tooltip;
