import React, { type FC, useCallback, useEffect } from 'react';
import { useAppDispatch, useAppSelector, useDebounce } from 'shared/hooks';
import type { ITableSettings, ValueOf } from 'shared/types';
import { DEBOUNCE_DELAY } from 'shared/constants/delays';
import type { ColDef } from 'ag-grid-community';
import { CustomizableTable } from 'components';
import { setSelectedWithdrawalTransactionsColumns, updateSelectedWithdrawalTransactionsSettings } from '../store/reducer';
import { type ITransaction } from '../types';
import { useTransactionCols } from '../hooks/useTransactionCols';
import { loadWithdrawalTransactionsAction } from '../store/actions';
import { useParams } from 'react-router-dom';

const Withdrawals: FC = () => {
	const { id } = useParams();
	const dispatch = useAppDispatch();
	const { settings, columns, rows, isLoading, totalPages } = useAppSelector(state => state.withdrawals.selectedWithdrawal.transactions);
	const initialCols = useTransactionCols();

	const debouncedSettings = useDebounce<ITableSettings>(settings, DEBOUNCE_DELAY);

	const handleSettingUpdate = useCallback(
		(data: { key: keyof ITableSettings; value: ValueOf<ITableSettings> }) => {
			dispatch(updateSelectedWithdrawalTransactionsSettings(data));
		},
		[dispatch],
	);

	const handleColumnsUpdate = useCallback(
		(columns: Array<ColDef<ITransaction>>) => {
			dispatch(setSelectedWithdrawalTransactionsColumns(columns));
		},
		[dispatch],
	);

	useEffect(() => {
		if (!id) return;
		void dispatch(loadWithdrawalTransactionsAction({ settings, id }));
	}, [debouncedSettings, id]);

	return (
		<div className="pb-10">
			<CustomizableTable
				columns={columns}
				initialCols={initialCols}
				settings={settings}
				isLoading={isLoading}
				onSettingUpdate={handleSettingUpdate}
				onColumnsUpdate={handleColumnsUpdate}
				rows={rows}
				totalPages={totalPages}
			/>
		</div>
	);
};

export default Withdrawals;
