import React, { type FC, useCallback, useEffect } from 'react';
import { useAppDispatch, useAppSelector, useDebounce } from 'shared/hooks';
import { setSelectedMerchantUsersCols, updateSelectedMerchantUsersSettings } from '../store/reducer';
import { type ITableSettings, type ValueOf } from 'shared/types';
import { DEBOUNCE_DELAY } from 'shared/constants/delays';
import { type ColDef } from 'ag-grid-community';
import { useParams } from 'react-router-dom';
import { type IMerchantUser } from '../types';
import { CustomizableTable } from 'components';
import { useEmployeesColumns } from '../hooks/useEmpoyeesColumns';
import { loadEmployeesAction } from '../store/actions';
import SelectedEmployeeTab from './SelectedEmployeeTab';

const EmployeesTab: FC = () => {
	const dispatch = useAppDispatch();
	const { userId } = useParams();

	const { settings, columns, rows, isLoading, totalPages } = useAppSelector(state => state.settings.employees);
	const debouncedSettings = useDebounce<ITableSettings>(settings, DEBOUNCE_DELAY);

	const initialCols = useEmployeesColumns();
	const handleSettingUpdate = useCallback(
		(data: { key: keyof ITableSettings; value: ValueOf<ITableSettings> }) => {
			dispatch(updateSelectedMerchantUsersSettings(data));
		},
		[dispatch],
	);

	const handleColumnsUpdate = useCallback(
		(columns: Array<ColDef<IMerchantUser>>) => {
			dispatch(setSelectedMerchantUsersCols(columns));
		},
		[dispatch],
	);

	useEffect(() => {
		void dispatch(loadEmployeesAction(settings));
	}, [debouncedSettings]);

	if (userId) return <SelectedEmployeeTab />;

	return (
		<div className="pb-10">
			<CustomizableTable
				columns={columns}
				initialCols={initialCols}
				settings={settings}
				isLoading={isLoading}
				onSettingUpdate={handleSettingUpdate}
				onColumnsUpdate={handleColumnsUpdate}
				rows={rows}
				totalPages={totalPages}
				navigateOnRowClick
			/>
		</div>
	);
};

export default EmployeesTab;
