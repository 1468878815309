import React, { type FC } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as LogoIcon } from 'assets/icons/logo_white.svg';
import { Button } from 'components/UI';
import routes from 'shared/constants/routes';
import { iconList } from '../constants';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'modules/auth';

const Header: FC = () => {
	const { t } = useTranslation();
	const { login, signup } = useAuth();

	return (
		<div>
			<div className="px-12 pt-3 bg-home-intro bg-no-repeat bg-cover bg-center">
				<div className="flex justify-between items-center">
					<LogoIcon />
					<div className="flex items-center">
						<Link data-test-id="client-home-contacts-link" to={routes.contacts} className="mr-4 text-white text-sm">
							{t('modules.home.header.contacts')}
						</Link>
						<Button
							data-test-id="client-home-register"
							onClick={signup}
							variant="primary"
							className="bg-indigo-800 text-white font-normal border-indigo-800 hover:bg-indigo-900 hover:border-indigo-900 border-2 mr-4">
							{t('modules.home.header.register')}
						</Button>
						<Button
							data-test-id="client-home-login"
							onClick={login}
							variant="primary"
							className="border-blue-200 bg-transparent text-white font-normal hover:bg-main border-2">
							{t('modules.home.header.login')}
						</Button>
					</div>
				</div>
				<div className="py-20">
					<h1 className="text-white text-[60px] leading-none font-extrabold mb-12">
						{t('modules.home.header.title1')} <br />
						{t('modules.home.header.title2')}
					</h1>
					<p className="text-2xl text-white mb-2">{t('modules.home.header.whyUs')}:</p>
					<ul className="list-disc pl-10 mb-12">
						<li className="text-2xl text-white mb-2">{t('modules.home.header.why1')}</li>
						<li className="text-2xl text-white mb-2">{t('modules.home.header.why2')}</li>
						<li className="text-2xl text-white mb-2">{t('modules.home.header.why3')}</li>
						<li className="text-2xl text-white mb-2">{t('modules.home.header.why4')}</li>
						<li className="text-2xl text-white mb-2">{t('modules.home.header.why5')}</li>
						<li className="text-2xl text-white mb-2">{t('modules.home.header.why6')}</li>
						<li className="text-2xl text-white">{t('modules.home.header.why7')}</li>
					</ul>
					<div className="flex items-center">
						{iconList.map(item => (
							<div key={item.id}>
								<img src={item.icon} alt="system" className="mr-5" />
							</div>
						))}
					</div>
				</div>
			</div>
			<div className="bg-indigo-800 px-12 py-8 flex justify-between">
				<div className="text-white text-2xl font-medium">
					<span className="text-5xl leading-none font-extrabold">2 237</span> {t('modules.home.header.connected')}
				</div>
				<div className="text-white text-2xl font-medium">
					<span className="text-5xl leading-none font-extrabold">1 154 762 067 </span> {t('modules.home.header.processed')}
				</div>
			</div>
		</div>
	);
};

export default Header;
