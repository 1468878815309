import React, { type FC, type HTMLAttributes, useCallback, useMemo, useState } from 'react';
import clsx from 'clsx';
import { type NavigationItem, type Nullable } from 'shared/types';
import { type ExpandableListItem } from './types';
import { ReactComponent as ArrowIcon } from 'assets/icons/arrow.svg';
import classes from './ExpandableList.module.scss';

interface IProps extends HTMLAttributes<HTMLDivElement> {
	items: ExpandableListItem[];
	selected: Nullable<number>;
	title: string;
	onItemClick: (item: NavigationItem) => void;
}

const ExpandableList: FC<IProps> = ({ id, items, title, selected, onItemClick, ...rest }) => {
	const [expanded, setExpanded] = useState<boolean>(false);

	const toggleExpanded = useCallback(() => {
		setExpanded(prev => !prev);
	}, []);

	const height = useMemo<number>(() => {
		return items.length * 44;
	}, [items]);

	return (
		<div {...rest}>
			<div
				data-test-id={`client-sidebar-list-${id ?? ''}`}
				onClick={toggleExpanded}
				className={clsx('cursor-pointer rounded-md flex justify-between items-center p-2 pr-4', expanded && 'bg-gray-100')}>
				<span className="text-sm text-gray-900 font-medium">{title}</span>
				<ArrowIcon className={clsx(expanded && '-rotate-90', expanded && classes.expandedSvg, 'duration-100')} />
			</div>

			<div className="overflow-hidden duration-200" style={{ height: expanded ? height : 0 }}>
				{items.map(item => (
					<div
						data-test-id={`client-sidebar-list-${id ?? ''}-item-${item.id}`}
						onClick={onItemClick.bind(null, item)}
						key={item.id}
						className={clsx(
							'cursor-pointer rounded-md text-sm text-gray-900 font-medium ml-4 mt-2 p-2 hover:bg-gray-50',
							item.id === selected && 'bg-gray-50',
						)}>
						{item.text}
					</div>
				))}
			</div>
		</div>
	);
};

export default ExpandableList;
