import React, { type FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'modules/auth';
import routes from 'shared/constants/routes';
import { localStorageKeys, localStorageService } from 'shared/utils/localStorage';
import Header from './components/Header';
import Footer from './components/Footer';
import Services from './components/Services';
import CmsModules from './components/CMSModules';
import Commissions from './components/Commissions';
import Safety from './components/Safety';

const Home: FC = () => {
	const { getUser } = useAuth();
	const navigate = useNavigate();
	useEffect(() => {
		void getUser().then(user => {
			if (user) {
				const rout = localStorageService.get<string>(localStorageKeys.ROUT_BEFORE_AUTH);
				navigate(rout ?? routes.accounts.index);
			}
		});
	}, []);
	return (
		<div>
			<Header />
			<Services />
			<CmsModules />
			<Commissions />
			<Safety />
			<div className="h-20 bg-gray-50"></div>
			<Footer />
		</div>
	);
};

export default Home;
