import { type IPaginateResponse, type ITableSettings } from 'shared/types';
import axiosInstance from 'shared/libs/axios';
import api from 'shared/constants/api';
import { type IOperation, type IOperationDetails, type IOperationService } from '../types';
import { settingsToSearchParams } from 'shared/helpers';

class OperationService implements IOperationService {
	async getAllOperations(settings: ITableSettings): Promise<IPaginateResponse<IOperation>> {
		const query = settingsToSearchParams(settings);
		return (await axiosInstance.get<IPaginateResponse<IOperation>>(api.OPERATIONS.GET_ALL + `?${query}`)).data;
	}

	async getOperationById(id: string): Promise<IOperationDetails> {
		return (await axiosInstance.get(api.OPERATIONS.GET_ONE(id))).data;
	}

	async exportToExel(settings: ITableSettings): Promise<Blob> {
		const temp = structuredClone(settings);
		delete temp.OrderBy;
		delete temp.OrderDir;
		delete temp.PageNumber;
		delete temp.PageSize;
		delete temp.isFiltersHidden;
		delete temp.isSortingHidden;
		const query = settingsToSearchParams(temp);

		return (await axiosInstance.get(`${api.OPERATIONS.EXPORT}?${query}`, { responseType: 'blob' })).data;
	}
}

export default new OperationService();
