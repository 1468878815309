import { type User } from 'oidc-client-ts';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
	localStorageKeys,
	localStorageService,
} from 'shared/utils/localStorage';
import { config } from 'shared/constants/config';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import routes from 'shared/constants/routes';
import { getUserManager } from '../service';
import { setHas2fa } from '../../app/store/reducer';

type ReturnType = {
	login: () => void;
	signup: () => void;
	logout: () => void;
	getUser: () => Promise<User | null>;
	redirectCallback: () => Promise<void>;
	updateTokens: () => Promise<void>;
	renewCallback: () => Promise<void>;
	user: User | null;
};
const publicRoutes = [routes.auth.redirect, routes.home, routes.contacts];
export const useAuth = (): ReturnType => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { isInited } = useAppSelector(state => state.app);

	const [user, setUser] = useState<User | null>(null);
	const userManager = getUserManager();

	const login = useCallback(async (): Promise<void> => {
		await userManager.signinRedirect();
	}, [userManager]);

	const signup = useCallback(async (): Promise<void> => {
		window.location.href = `https://auth.obmenka.dev/account/register?ReturnUrl=${
			window.location.origin + config.oidcRedirectUri
		}`;
	}, [userManager]);

	const logout = useCallback(async (): Promise<void> => {
		localStorageService.set(localStorageKeys.ACCESS_TOKEN, null);
		setUser(null);
		await userManager.removeUser();
		await userManager.signoutRedirect();
		navigate(routes.home);
	}, [navigate, userManager]);

	const checkUser = useCallback(async () => {
		if (publicRoutes.includes(location.pathname)) return;

		const user = await userManager.getUser();

		if (user) setUser(user);
		else await login();
	}, [login, userManager]);

	const redirectCallback = useCallback(async (): Promise<void> => {
		try {
			const user = await userManager.signinRedirectCallback();
			if (user.profile.sub === 'admin_user') {
				await logout();
				return;
			}

			localStorageService.set(localStorageKeys.ACCESS_TOKEN, user.access_token);
			setUser(user);
			const rout = localStorageService.get<string>(
				localStorageKeys.ROUT_BEFORE_AUTH,
			);
			navigate(rout ?? routes.accounts.index);
		} catch (e) {
			console.log(e);
			await login();
		}
	}, [navigate, userManager, login, logout]);

	const renewCallback = useCallback(async (): Promise<void> => {
		try {
			await userManager.signinSilentCallback();
		} catch (e) {
			const user = await userManager.getUser();
			if (!user) return;
			setUser(user);
			localStorageService.set(localStorageKeys.ACCESS_TOKEN, user.access_token);
			const rout = localStorageService.get<string>(
				localStorageKeys.ROUT_BEFORE_AUTH,
			);
			navigate(rout ?? routes.accounts.index);
		}
	}, [navigate, userManager]);

	const getUser = useCallback(async (): Promise<User | null> => {
		const newUser = await userManager.getUser();
		setUser(newUser);
		return newUser;
	}, [user, userManager]);

	const updateTokens = useCallback(async () => {
		try {
			const updatedUser = await userManager.signinSilent();
			await userManager.getUser();
			if (!updatedUser) {
				await userManager.signinRedirect();
				return;
			}

			setUser(updatedUser);
			localStorageService.set(
				localStorageKeys.ACCESS_TOKEN,
				updatedUser.access_token,
			);
			if (typeof updatedUser.profile.has_2fa === 'boolean') {
				dispatch(setHas2fa(updatedUser.profile.has_2fa));
			} else if (typeof updatedUser.profile.has_2fa === 'string') {
				dispatch(setHas2fa(updatedUser.profile.has_2fa === 'True'));
			} else {
				const [, flag] = updatedUser.profile.has_2fa as string[];
				dispatch(setHas2fa(flag === 'True'));
			}

			localStorageService.set(
				localStorageKeys.ACCESS_TOKEN,
				updatedUser?.access_token,
			);
			if (!updatedUser) await userManager.signinRedirect();
		} catch (error) {
			console.error('Error refreshing access token:', error);
		}
	}, [dispatch, userManager]);

	useEffect(() => {
		if (isInited) return;
		void checkUser();
		// eslint-disable-next-line
	}, [location.pathname, checkUser]);

	useEffect(() => {
		if (!user) return;
		if (typeof user.profile.has_2fa === 'boolean') {
			dispatch(setHas2fa(user.profile.has_2fa));
		} else if (typeof user.profile.has_2fa === 'string') {
			dispatch(setHas2fa(user.profile.has_2fa === 'True'));
		} else {
			const [, flag] = user.profile.has_2fa as string[];
			dispatch(setHas2fa(flag === 'True'));
		}
	}, [user]);

	return {
		login,
		redirectCallback,
		user,
		getUser,
		logout,
		signup,
		renewCallback,
		updateTokens,
	};
};
