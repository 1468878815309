import { UserManager, WebStorageStateStore } from 'oidc-client-ts';
import { config } from 'shared/constants/config';
import { localStorageKeys, localStorageService } from '../../../shared/utils/localStorage';

let sharedUserManager: UserManager | null = null;

export const getUserManager = (): UserManager => {
	if (!sharedUserManager) {
		sharedUserManager = new UserManager({
			authority: config.oidcAuthority,
			client_id: config.oidcClientId,
			response_type: 'code',
			automaticSilentRenew: false,
			redirect_uri: window.location.origin + config.oidcRedirectUri,
			post_logout_redirect_uri: config.oidcLogoutRedirect,
			scope: config.oidcScope,
			userStore: new WebStorageStateStore({ store: window.localStorage }),
			silent_redirect_uri: window.location.origin + config.oidcRenewTokenUrl,
			loadUserInfo: true,
		});
	}

	return sharedUserManager;
};

export const refreshAccessToken = async (): Promise<void> => {
	try {
		const userManager = getUserManager();
		const user = await userManager.signinSilent();
		localStorageService.set(localStorageKeys.ACCESS_TOKEN, user?.access_token);
		if (!user) await userManager.signinRedirect();
	} catch (error) {
		console.error('Error refreshing access token:', error);
	}
};
