import React, { type FC, useCallback, useEffect, useMemo, useState } from 'react';
import type { ITab } from 'shared/types';
import { type IAddressData, type IWithdrawalAccountWallet, WithdrawalStep } from '../types';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import routes from 'shared/constants/routes';
import NewWithdrawalSteps from './NewWithdrawalSteps';
import ChooseAccountStep from './ChooseAccountStep';
import EnterSumStep from './EnterSumStep';
import TypeAddressStep from './TypeAddressStep';
import ConfirmStep from './ConfirmStep';
import { setNewWithdrawalAccountWallet, setNewWithdrawalAddress, setNewWithdrawalAmount, setNewWithdrawalStep } from '../store/reducer';
import { checkWithdrawalAmountAction, loadWithdrawalAccountsAction } from '../store/actions';
import WithdrawalService from '../service';
import SuccessModal from './SuccessModal';

const CreateWithdrawal: FC = () => {
	const { t } = useTranslation();
	const { activeStep, availableAccounts, selectedWallet, amount, address, fee } = useAppSelector(state => state.withdrawals.new);
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const [showSuccess, setShowSuccess] = useState<boolean>(false);

	const handleCloseSuccess = useCallback(() => {
		setShowSuccess(false);
		dispatch(setNewWithdrawalStep(WithdrawalStep.WITHDRAWAL_ACCOUNT));
		dispatch(setNewWithdrawalAccountWallet(null));
		dispatch(setNewWithdrawalAmount(null));
		dispatch(setNewWithdrawalAddress(null));
	}, [dispatch]);

	const steps = useMemo<ITab[]>(
		() => [
			{
				id: 1,
				value: WithdrawalStep.WITHDRAWAL_ACCOUNT as unknown as string,
				text: t('modules.withdrawals.new.step.account'),
			},
			{
				id: 2,
				value: WithdrawalStep.WITHDRAWAL_SUM as unknown as string,
				text: t('modules.withdrawals.new.step.amount'),
			},
			{
				id: 3,
				value: WithdrawalStep.WITHDRAWAL_ADDRESS as unknown as string,
				text: t('modules.withdrawals.new.step.address'),
			},
			{
				id: 4,
				value: WithdrawalStep.WITHDRAWAL_CONFIRM as unknown as string,
				text: t('modules.withdrawals.new.step.confirm'),
			},
		],
		[t],
	);

	const handleConfirmAccount = useCallback(
		(wallet: IWithdrawalAccountWallet) => {
			dispatch(setNewWithdrawalStep(WithdrawalStep.WITHDRAWAL_SUM));
			dispatch(setNewWithdrawalAccountWallet(wallet));
		},
		[dispatch],
	);

	const handleConfirmAmount = useCallback(
		async (amount: number) => {
			if (!selectedWallet) return;
			try {
				await dispatch(checkWithdrawalAmountAction({ walletId: selectedWallet.id, amount }));
				dispatch(setNewWithdrawalStep(WithdrawalStep.WITHDRAWAL_ADDRESS));
			} catch (e) {
				console.log(e);
			}
		},
		[dispatch, selectedWallet],
	);

	const handleConfirmAddress = useCallback(
		async (data: IAddressData) => {
			if (!selectedWallet) return;

			try {
				dispatch(setNewWithdrawalAddress(data));
				await WithdrawalService.checkDestination(selectedWallet.id, data.destination);
				dispatch(setNewWithdrawalStep(WithdrawalStep.WITHDRAWAL_CONFIRM));
			} catch (e) {
				console.log(e);
			}
		},
		[dispatch, selectedWallet],
	);

	const handleConfirmWithdrawal = useCallback(async () => {
		if (!selectedWallet || !address?.destination || !amount) return;
		try {
			const { destination, description, operationNumber } = address;
			await WithdrawalService.postWithdrawal(selectedWallet.id, { amount, destination, fee, description, operationNumber });
			setShowSuccess(true);
		} catch (e) {
			console.log(e);
		}
	}, [amount, address, fee, selectedWallet]);

	const handleCancelWithdrawal = useCallback(() => {
		switch (activeStep) {
			case WithdrawalStep.WITHDRAWAL_ACCOUNT: {
				dispatch(setNewWithdrawalAccountWallet(null));
				navigate(routes.withdrawals.index);
				break;
			}

			case WithdrawalStep.WITHDRAWAL_SUM: {
				dispatch(setNewWithdrawalAmount(null));
				dispatch(setNewWithdrawalStep(WithdrawalStep.WITHDRAWAL_ACCOUNT));
				break;
			}

			case WithdrawalStep.WITHDRAWAL_ADDRESS: {
				dispatch(setNewWithdrawalAddress(null));
				dispatch(setNewWithdrawalStep(WithdrawalStep.WITHDRAWAL_SUM));
				break;
			}

			case WithdrawalStep.WITHDRAWAL_CONFIRM: {
				dispatch(setNewWithdrawalStep(WithdrawalStep.WITHDRAWAL_ADDRESS));
				break;
			}

			default:
				break;
		}
	}, [activeStep, dispatch, navigate]);

	useEffect(() => {
		void dispatch(loadWithdrawalAccountsAction());
	}, []);

	useEffect(
		() => () => {
			dispatch(setNewWithdrawalStep(WithdrawalStep.WITHDRAWAL_ACCOUNT));
			dispatch(setNewWithdrawalAccountWallet(null));
			dispatch(setNewWithdrawalAmount(null));
			dispatch(setNewWithdrawalAddress(null));
		},
		[],
	);

	return (
		<div>
			<div className="mb-6">
				<NewWithdrawalSteps activeStep={activeStep} steps={steps} />
			</div>
			{activeStep === WithdrawalStep.WITHDRAWAL_ACCOUNT && (
				<ChooseAccountStep
					wallet={selectedWallet}
					accounts={availableAccounts}
					onConfirm={handleConfirmAccount}
					onCancel={handleCancelWithdrawal}
				/>
			)}
			{activeStep === WithdrawalStep.WITHDRAWAL_SUM && (
				<EnterSumStep amount={amount} onConfirm={handleConfirmAmount} onCancel={handleCancelWithdrawal} />
			)}
			{activeStep === WithdrawalStep.WITHDRAWAL_ADDRESS && (
				<TypeAddressStep
					destination={address?.destination ?? ''}
					onConfirm={handleConfirmAddress}
					onCancel={handleCancelWithdrawal}
				/>
			)}
			{activeStep === WithdrawalStep.WITHDRAWAL_CONFIRM && (
				<ConfirmStep onConfirm={handleConfirmWithdrawal} onCancel={handleCancelWithdrawal} />
			)}

			<SuccessModal show={showSuccess} onClose={handleCloseSuccess} />
		</div>
	);
};

export default CreateWithdrawal;
