import React, { type FC, type ReactNode, useCallback, type MouseEvent, useEffect } from 'react';
import clsx from 'clsx';

interface IProps {
	show: boolean;
	children: ReactNode;
	onClose: () => void;
}
const Modal: FC<IProps> = ({ children, show, onClose }) => {
	const handleClickOut = useCallback(
		(e: MouseEvent) => {
			if (e.target === e.currentTarget) onClose();
		},
		[onClose],
	);

	useEffect(() => {
		show ? document.body.classList.add('overflow-hidden') : document.body.classList.remove('overflow-hidden');
	}, [show]);

	return (
		<div
			onClick={handleClickOut}
			className={clsx('fixed inset-0 z-10 flex justify-center items-center bg-gray-900/40', !show && 'hidden')}>
			<div className="min-w-[352px] bg-white rounded-lg flex flex-col h-fit justify-between p-6">
				<div>{children}</div>
			</div>
		</div>
	);
};

export default Modal;
