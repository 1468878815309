import React, { type FC, useCallback, useMemo } from 'react';
import dayjs, { type Dayjs } from 'dayjs';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import { type Moment } from 'moment/moment';
import { type Nullable } from 'shared/types';
import Input from '../Input';

interface IProps {
	wrapperClassName?: string;
	disabled?: boolean;
	value: Nullable<Date>;
	onChange: (date: Dayjs) => void;
	testId: string;
}
const DateTimePicker: FC<IProps> = ({ wrapperClassName, value, onChange, disabled, testId }) => {
	const handleChange = useCallback(
		(value: Moment | string) => {
			if (typeof value === 'string') return;
			const date = dayjs(value.toDate());
			const diff = dayjs(date).diff(dayjs(Date.now()));
			if (diff > 0) return;
			onChange(date);
		},
		[onChange],
	);

	const renderInput = useCallback(
		(props: any): JSX.Element => {
			const dateToShow = value ? dayjs(value).format('DD.MM.YYYY') : '';
			return (
				<Input
					readOnly={true}
					data-test-id={testId}
					{...props}
					value={dateToShow}
					disabled={disabled}
					placeholder="DD.MM.YY"
					style={{ padding: '9px 14px' }}
				/>
			);
		},
		[disabled, value, testId],
	);

	const datetime = useMemo<Date | undefined>(() => {
		if (!value) return;
		return dayjs(value).toDate();
	}, [value]);

	return (
		<div className={wrapperClassName}>
			<Datetime
				value={datetime}
				onChange={handleChange}
				renderInput={renderInput}
				strictParsing={false}
				dateFormat="dd.mm.yyyy"
				timeFormat={false}
			/>
		</div>
	);
};

export default DateTimePicker;
