import { createAsyncThunk } from '@reduxjs/toolkit';
import { setCurrencies, setEnums, setInited, setLoading, setServices } from './reducer';
import AppService from '../service';
import { type Enum, type TransformedEnum } from '../types';

const INIT_TYPE = 'app/init';
const initAppAction = createAsyncThunk(INIT_TYPE, async (data, { rejectWithValue, dispatch }) => {
	try {
		dispatch(setLoading(true));

		const enums = await AppService.getEnums();
		const transformArrayToMap = (list: Enum[], key: keyof Enum): TransformedEnum => {
			return list.reduce(
				(accum: object, item: Enum) => ({
					...accum,
					[item[key] as string]: {
						...item,
						values: item.values.reduce((accum, item) => {
							return { ...accum, [item.id]: item };
						}, {}),
					},
				}),
				{},
			);
		};

		dispatch(setEnums(transformArrayToMap(enums, 'name')));
		dispatch(setServices(await AppService.getServices()));
		dispatch(setCurrencies(await AppService.getCurrencies()));
		dispatch(setInited(true));
	} catch (e) {
		return rejectWithValue(e);
	} finally {
		dispatch(setLoading(false));
	}
});

export { initAppAction };
