import React, { type FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import routes from 'shared/constants/routes';
import { Button } from 'components/UI';
import { useTranslation } from 'react-i18next';

const NotFound: FC = () => {
	const navigate = useNavigate();
	const { t } = useTranslation();

	const goHome = useCallback(() => {
		navigate(routes.home);
	}, [navigate]);

	return (
		<section className="flex items-center h-full px-6 py-12 mx-auto">
			<div className="flex flex-col items-center max-w-sm mx-auto text-center">
				<h1 className="text-main text-[128px] mb-6 font-bold leading-none">404</h1>
				<h3 className="text-gray-900 mb-2 text-2xl font-semibold">{t('modules.404.notFound')}</h3>
				<p className="text-gray-500 text-base mb-16">{t('modules.404.notExist')}</p>

				<Button variant="primary" className="bg-main text-white hover:bg-indigo-600" onClick={goHome}>
					{t('modules.404.main')}
				</Button>
			</div>
		</section>
	);
};

export default NotFound;
