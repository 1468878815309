import React, { type FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { FormRow } from 'components/UI';
import { AccountsTab } from 'modules/accounts/types';
import routes from 'shared/constants/routes';
import { useAppSelector } from 'shared/hooks';

const BasicDataTab: FC = () => {
	const { t } = useTranslation();
	const data = useAppSelector(
		state => state.operations.selectedOperation.mainData,
	);
	const { enums } = useAppSelector(state => state.app);

	const createdDate = useMemo<string>(() => {
		if (!data?.createdAt) return '';
		return dayjs(data.createdAt).format('HH:mm:ss, DD.MM.YYYY');
	}, [data]);

	const processedDate = useMemo<string>(() => {
		if (!data?.processedAt) return '';
		return dayjs(data.processedAt).format('HH:mm:ss, DD.MM.YYYY');
	}, [data]);

	const status = useMemo<string>(() => {
		if (!enums || !data) return '';
		return (
			Object.values(enums.OperationStatus.values).find(
				({ id }) => id === data.status,
			)?.value ?? ''
		);
	}, [enums, data]);

	if (!data) return <></>;

	return (
		<>
			<div>
				<h4 className="text-gray-900 text-lg font-medium min-h-[38px]">
					{t('modules.operations.settings')}
				</h4>
				<div className="grid grid-cols-2 gap-x-12">
					<FormRow label={t('modules.operations.fields.operationId')}>
						<div className="w-full flex items-center h-[38px] justify-end">
							<div className="text-sm text-gray-500">{data.id}</div>
						</div>
					</FormRow>
					<FormRow label={t('modules.operations.fields.number')}>
						<div className="w-full flex items-center h-[38px] justify-end">
							<div className="text-sm text-gray-500">{data.number}</div>
						</div>
					</FormRow>
					<FormRow label={t('modules.operations.fields.paymentSystem')}>
						<div className="w-full flex items-center h-[38px] justify-end">
							<div className="text-sm text-gray-500">{data.paymentSystem}</div>
						</div>
					</FormRow>
					<FormRow label={t('modules.operations.fields.date')}>
						<div className="w-full flex items-center h-[38px] justify-end">
							<div className="text-sm text-gray-500">{processedDate}</div>
						</div>
					</FormRow>
					<FormRow label={t('modules.operations.fields.account')}>
						<div className="w-full flex items-center h-[38px] justify-end">
							<Link
								data-test-id="client-operations-account"
								className="text-main font-medium underline text-sm"
								to={routes.accounts.details(
									data.accountId,
									AccountsTab.BASIC_DATA,
								)}>
								{data.accountName}
							</Link>
						</div>
					</FormRow>

					<FormRow label={t('modules.operations.fields.status')}>
						<div className="w-full flex items-center h-[38px] justify-end">
							<div className="text-sm text-gray-500">{status}</div>
						</div>
					</FormRow>

					<FormRow label={t('modules.operations.fields.balance')}>
						<div className="w-full flex items-center h-[38px] justify-end">
							<div className="text-sm text-gray-500">{data.balance}</div>
						</div>
					</FormRow>
					<FormRow label={t('modules.operations.fields.currency')}>
						<div className="w-full flex items-center h-[38px] justify-end">
							<div className="text-sm text-gray-500">{data.currency}</div>
						</div>
					</FormRow>
					<FormRow label={t('modules.operations.fields.amount')}>
						<div className="w-full flex items-center h-[38px] justify-end">
							<div className="text-sm text-gray-500">{data.amount}</div>
						</div>
					</FormRow>
					<FormRow label={t('modules.operations.fields.actualAmount')}>
						<div className="w-full flex items-center h-[38px] justify-end">
							<div className="text-sm text-gray-500">{data.actualAmount}</div>
						</div>
					</FormRow>
					<FormRow label={t('modules.operations.fields.netAmount')}>
						<div className="w-full flex items-center h-[38px] justify-end">
							<div className="text-sm text-gray-500">{data.netAmount}</div>
						</div>
					</FormRow>
					<FormRow label={t('modules.operations.fields.fee')}>
						<div className="w-full flex items-center h-[38px] justify-end">
							<div className="text-sm text-gray-500">{data.fee}</div>
						</div>
					</FormRow>
					<FormRow label={t('modules.operations.fields.exchangeRate')}>
						<div className="w-full flex items-center h-[38px] justify-end">
							<div className="text-sm text-gray-500">{data.exchangeRate}</div>
						</div>
					</FormRow>

					<FormRow label={t('modules.operations.fields.description')}>
						<div className="w-full flex items-center h-[38px] justify-end">
							<div className="text-sm  text-gray-500">{data.description}</div>
						</div>
					</FormRow>

					<FormRow label={t('modules.operations.fields.created')}>
						<div className="w-full flex items-center h-[38px] justify-end">
							<div className="text-sm text-gray-500">{createdDate}</div>
						</div>
					</FormRow>
				</div>
			</div>
		</>
	);
};

export default BasicDataTab;
