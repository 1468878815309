import React, { type FC, useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { config } from 'shared/constants/config';
import { useValidation } from 'shared/hooks';
import { ReactComponent as TelegramIcon } from 'assets/icons/telegram_filled.svg';
import Header from './components/Header';
import Footer from './components/Footer';
import { Button, Input, TextArea } from 'components/UI';
import { type IFeedback } from './types';

const Contacts: FC = () => {
	const { stringValidation, emailValidation } = useValidation();
	const { t } = useTranslation();
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const {
		register,
		handleSubmit,
		formState: { errors, isValid },
	} = useForm<IFeedback>({ mode: 'all' });

	const handleConfirm = useCallback(() => {
		setIsLoading(true);
		setTimeout(() => {
			setIsLoading(false);
		}, 500);
	}, []);
	return (
		<div>
			<Header />
			<div className="px-12 py-20 bg-gray-50">
				<div className="bg-white border border-gray-200 rounded-lg px-12 py-20 ">
					<h1 className="text-[44px] font-extrabold text-gray-900 leading-[56px] mb-6">{t('modules.home.contacts.title')}</h1>
					<div className="flex justify-between">
						<div className="w-2/4 mr-12">
							<h3 className="text-[28px] font-extrabold text-gray-900 leading-[36px] mb-6">
								{t('modules.home.contacts.subTitle')}
							</h3>
							<p className="text-2xl text-gray-600 mb-6 font-medium">{t('modules.home.contacts.description')}</p>
							<a
								className="flex items-center text-main text-xl leading-7 font-medium mb-6"
								href="mailto:obmenka.acquiring@gmail.com">
								<TelegramIcon className="mr-4" />
								obmenka.acquiring@gmail.com
							</a>
							<a
								className="flex items-center text-main text-xl leading-7 font-medium"
								target="_blank"
								href={config.telegramUrl}
								rel="noreferrer">
								<TelegramIcon className="mr-4" />
								{t('modules.home.contacts.telegram')}
							</a>
						</div>
						<div className="w-2/4">
							<h3 className="text-[28px] font-extrabold text-gray-900 leading-[36px] mb-6">
								{t('modules.home.contacts.feedback')}
							</h3>
							<div className="mb-6">
								<label htmlFor="name" className="text-sm leading-5 font-medium mb-1 text-gray-700">
									{t('modules.home.contacts.name')}
								</label>
								<Input
									id="name"
									data-test-id="client-feedback-name"
									{...register('name', stringValidation)}
									isError={Boolean(errors.name)}
									errorText={errors.name?.message}
								/>
							</div>
							<div className="mb-6">
								<label htmlFor="email" className="text-sm leading-5 font-medium mb-1 text-gray-700">
									{t('modules.home.contacts.email')}
								</label>
								<Input
									id="email"
									data-test-id="client-feedback-name"
									{...register('email', emailValidation)}
									isError={Boolean(errors.email)}
									errorText={errors.email?.message}
								/>
							</div>
							<div className="mb-6">
								<label htmlFor="message" className="text-sm leading-5 font-medium mb-1 text-gray-700">
									{t('modules.home.contacts.message')}
								</label>
								<TextArea
									id="message"
									data-test-id="client-feedback--message"
									isError={Boolean(errors.message)}
									errorText={errors.message?.message ?? ''}
									{...register('message', stringValidation)}
								/>
							</div>

							<Button
								data-test-id="client-feedback-confirm"
								variant="primary"
								isLoading={isLoading}
								onClick={handleSubmit(handleConfirm)}
								disabled={!isValid}>
								{t('modules.home.contacts.send')}
							</Button>
						</div>
					</div>
				</div>
			</div>

			<Footer />
		</div>
	);
};

export default Contacts;
