import React, { type FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Modal } from 'components';
import { ReactComponent as CheckIcon } from 'assets/icons/check.svg';
import { Button } from 'components/UI';
import routes from 'shared/constants/routes';

interface IProps {
	show: boolean;
	onClose: () => void;
}
const SuccessModal: FC<IProps> = ({ show, onClose }) => {
	const navigate = useNavigate();
	const { t } = useTranslation();

	const handleCreateNewWithdrawal = (): void => {
		onClose();
		navigate(routes.withdrawals.create);
	};

	const handleCloseWithdrawal = (): void => {
		onClose();
		navigate(routes.withdrawals.index);
	};

	return (
		<Modal show={show} onClose={onClose}>
			<div className="flex flex-col items-center">
				<CheckIcon className="mb-3" />
				<h4 className="text-lg leading-6 font-medium text-gray-900 mb-2">{t('modules.withdrawals.new.success.title')}</h4>
				<p className="text-sm leading-5 font-normal text-gray-500 mb-5">{t('modules.withdrawals.new.success.subTitle')}</p>
				<div className="flex">
					<Button
						className="mr-3"
						data-test-id="client-withdrawal-create-new"
						variant="secondary"
						onClick={handleCreateNewWithdrawal}>
						{t('modules.withdrawals.new.success.new')}
					</Button>
					<Button data-test-id="client-withdrawal-create-close" variant="create" onClick={handleCloseWithdrawal}>
						{t('ui.buttons.close')}
					</Button>
				</div>
			</div>
		</Modal>
	);
};

export default SuccessModal;
