import React, { type FC } from 'react';
import { Button } from 'components/UI';
import { useTranslation } from 'react-i18next';
import { config } from '../../../shared/constants/config';

const Safety: FC = () => {
	const { t } = useTranslation();

	return (
		<div className="px-12 py-20 bg-white">
			<div className="mb-8">
				<h2 className="text-[44px] text-gray-900 leading-[56px] font-extrabold mb-6 text-center">
					{t('modules.home.safety.title')}
				</h2>
				<p className="text-2xl text-gray-600 text-center max-w-[820px] mx-auto ">{t('modules.home.safety.description')}</p>
			</div>

			<div className="mb-8 flex items-center justify-center">
				<div className="mr-12 px-4">
					<div className="flex justify-center">
						<img src={`${config.storageUrl}/images/icon_comodo_security.png`} alt="comodo" className="mb-2" />
					</div>
					<div className="text-base leading-6 text-gray-900 text-center">
						{t('modules.home.safety.comodo1')} <br /> {t('modules.home.safety.comodo2')}
					</div>
				</div>
				<div className="mr-12 px-4">
					<div className="flex justify-center">
						<img src={`${config.storageUrl}/images/icon_pci_security.png`} alt="pci" className="mb-2" />
					</div>
					<div className="text-base leading-6 text-gray-900 text-center">
						{t('modules.home.safety.psi1')} <br /> {t('modules.home.safety.psi2')}
					</div>
				</div>
				<div className="px-4">
					<div className="flex justify-center">
						<img src={`${config.storageUrl}/images/icon_ssh_security.png`} alt="ssh" className="mb-2" />
					</div>
					<div className="text-base leading-6 text-gray-900 text-center">
						{t('modules.home.safety.ssh1')} <br /> {t('modules.home.safety.ssh2')}
					</div>
				</div>
			</div>
			<div className="text-2xl text-gray-600 mb-8">
				<p className="mb-4 max-w-[1200px] mx-auto text-center">{t('modules.home.safety.text1')}</p>
				<p className="mb-4 max-w-[1200px] mx-auto text-center">{t('modules.home.safety.text2')}</p>
				<p className="max-w-[1200px] mx-auto text-center">{t('modules.home.safety.text3')}</p>
			</div>
			<div className="flex justify-center">
				<Button variant="create">{t('modules.home.safety.apply')}</Button>
			</div>
		</div>
	);
};

export default Safety;
