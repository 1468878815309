import React, { type FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { type Nullable } from 'shared/types';
import { removeHistoryRecord } from 'modules/app';
import routes from 'shared/constants/routes';
import { useAppDispatch, useAppSelector, useValidation } from 'shared/hooks';
import { Button, Checkbox, FormRow, Input, Select } from 'components/UI';
import { type Option } from 'components/UI/Select/types';
import { type IMerchantUser, SettingsTab } from '../types';
import { loadEmployeeAction } from '../store/actions';

const SelectedEmployeeTab: FC = () => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const { t } = useTranslation();
	const { stringValidation, emailValidation, passwordValidation, phoneNumberValidation } = useValidation();
	const roles = useAppSelector(state => state.app.roles);

	const { userId } = useParams();
	const user = useAppSelector(state => state.settings.employees.selected);
	const isCreating = useMemo<boolean>(() => userId === 'create', [userId]);
	const [isEditing, setIsEditing] = useState<boolean>(Boolean(isCreating));

	const toggleEditing = useCallback(() => {
		setIsEditing(prev => !prev);
	}, []);

	const {
		watch,
		setValue,
		register,
		clearErrors,
		handleSubmit,
		formState: { errors },
	} = useForm<IMerchantUser>({
		mode: 'all',
	});
	const watcher = watch();

	const fillForm = useCallback(() => {
		if (!user) return;
		Object.entries(user).forEach(([key, value]) => {
			setValue(key as keyof IMerchantUser, isCreating ? '' : value, { shouldTouch: false });
		});
		clearErrors();
	}, [user, clearErrors, setValue, isCreating]);

	const [selectedRole, setSelectedRole] = useState<Nullable<Option>>(null);

	const rolesOptions = useMemo<Option[]>(() => {
		return roles.map(({ name, value }) => ({ value: name, label: value }));
	}, [roles]);

	const handleRoleChange = useCallback(
		(status: Option) => {
			setValue('roleName', String(status.value));
			setSelectedRole(status);
		},
		[setValue],
	);

	const handleCancel = useCallback(() => {
		fillForm();
		toggleEditing();
		clearErrors();
		if (isCreating) navigate(-1);
	}, [clearErrors, fillForm, isCreating, navigate, toggleEditing]);

	const handleGoBack = useCallback(() => {
		dispatch(removeHistoryRecord());
	}, [dispatch]);

	const handleConfirm = useCallback(
		async (newUser: IMerchantUser) => {
			try {
				console.log('update user', newUser);
			} catch (e) {
				fillForm();
			} finally {
				toggleEditing();
			}
		},
		[fillForm, navigate],
	);

	useEffect(() => {
		if (!userId) {
			navigate(-1);
			return;
		}

		if (isCreating) return;

		void dispatch(loadEmployeeAction(userId));
	}, [isCreating, navigate, userId]);

	useEffect(fillForm, [fillForm]);

	useEffect(() => {
		if (selectedRole) return;
		const role: Nullable<Option> = rolesOptions.find(option => option.value === watcher.roleName) ?? null;
		setSelectedRole(role);
	}, [rolesOptions, selectedRole, watcher.roleName]);

	return (
		<div>
			<Link className="text-main font-medium text-sm mb-6" onClick={handleGoBack} to={routes.settings.index(SettingsTab.EMPLOYEES)}>
				{t('ui.table.backToTable')}
			</Link>
			<div className="flex justify-between items-center py-2">
				<h4 className="text-gray-900 text-lg font-medium min-h-[38px]">
					{isCreating ? t('modules.settings.employees.create') : t('modules.settings.employees.settings')}
				</h4>
				{!isEditing && (
					<Button data-test-id="client-employee-edit" variant="secondary" onClick={toggleEditing}>
						<EditIcon className="mr-2.5" />
						{t('ui.buttons.edit')}
					</Button>
				)}
			</div>
			<FormRow label={t('modules.settings.employees.fields.name')}>
				<Input
					disabled={!isEditing}
					isError={Boolean(errors.userName)}
					errorText={errors.userName?.message ?? ''}
					wrapperClassName="max-w-[512px] w-full"
					{...register('userName', stringValidation)}
				/>
			</FormRow>
			<FormRow label={t('modules.settings.employees.fields.email')}>
				<Input
					disabled={!isEditing}
					isError={Boolean(errors.email)}
					errorText={errors.email?.message ?? ''}
					wrapperClassName="max-w-[512px] w-full"
					{...register('email', emailValidation)}
				/>
			</FormRow>
			<FormRow label={t('modules.settings.employees.fields.phone')}>
				<Input
					disabled={!isEditing}
					isError={Boolean(errors.phoneNumber)}
					errorText={errors.phoneNumber?.message ?? ''}
					wrapperClassName="max-w-[512px] w-full"
					{...register('phoneNumber', phoneNumberValidation)}
				/>
			</FormRow>

			{isEditing && (
				<FormRow label={t('modules.settings.employees.fields.password')}>
					<Input
						type="password"
						disabled={!isEditing}
						isError={Boolean(errors.password)}
						errorText={errors.password?.message ?? ''}
						wrapperClassName="max-w-[512px] w-full"
						{...register('password', passwordValidation)}
					/>
				</FormRow>
			)}
			{isEditing && (
				<FormRow label={t('modules.settings.employees.fields.repeat')}>
					<Input
						type="password"
						disabled={!isEditing}
						isError={Boolean(errors.repeatPassword)}
						errorText={errors.repeatPassword?.message ?? ''}
						wrapperClassName="max-w-[512px] w-full"
						{...register('repeatPassword', passwordValidation)}
					/>
				</FormRow>
			)}
			<FormRow label={t('modules.settings.employees.fields.status')}>
				<Input
					disabled={!isEditing}
					isError={Boolean(errors.status)}
					errorText={errors.status?.message ?? ''}
					wrapperClassName="max-w-[512px] w-full"
					{...register('status', stringValidation)}
				/>
			</FormRow>

			<FormRow label={t('modules.settings.employees.fields.general')}>
				<div className="flex items-center w-full max-w-[512px]">
					<Checkbox
						data-test-id="client-merchants-canCreateAccount"
						disabled={!isEditing}
						label={t('modules.settings.employees.fields.canCreateAccount')}
						{...register('canCreateAccount')}
					/>
				</div>
			</FormRow>

			<FormRow label={t('modules.settings.employees.fields.role')}>
				<Select
					placeholder={t('modules.settings.employees.placeholders.role')}
					disabled={!isEditing}
					options={rolesOptions}
					value={selectedRole}
					onChange={handleRoleChange}
					optionClassName="h-[38px] flex items-center justify-center"
					wrapperClassName="max-w-[512px] w-full"
					className="h-[38px]"
				/>
			</FormRow>

			<FormRow label={t('modules.settings.employees.fields.accounts')}>
				<Input
					disabled={!isEditing}
					isError={Boolean(errors.accounts)}
					errorText={errors.accounts?.message ?? ''}
					wrapperClassName="max-w-[512px] w-full"
					{...register('accounts', stringValidation)}
				/>
			</FormRow>

			{watcher?.accountRights?.map((account, idx) => (
				<FormRow label={account.title} key={account.title} wrapperClassName="!items-start">
					<div className="w-full max-w-[512px]">
						<div className="mb-6">
							<span className="text-gray-700 text-sm leading-5 font-normal mb-2">
								{t('modules.settings.employees.fields.account')}
							</span>
							<Checkbox
								wrapperClassName="mb-2"
								defaultChecked={Boolean(watcher.accountRights?.length) && watcher.accountRights[idx].rights.account.canOpen}
								data-test-id="admin-merchants-canOpen"
								disabled={!isEditing}
								label={t('modules.settings.employees.fields.canOpenAccount')}
								{...register(`accountRights.${idx}.rights.account.canOpen`)}
							/>
							<Checkbox
								wrapperClassName="mb-2"
								defaultChecked={Boolean(watcher.accountRights?.length) && watcher.accountRights[idx].rights.account.canEdit}
								data-test-id="admin-merchants-canEdit"
								disabled={!isEditing}
								label={t('modules.settings.employees.fields.canEditAccount')}
								{...register(`accountRights.${idx}.rights.account.canEdit`)}
							/>
							<Checkbox
								defaultChecked={
									Boolean(watcher.accountRights?.length) && watcher.accountRights[idx].rights.account.canOpenBalance
								}
								data-test-id="admin-merchants-canOpenBalance"
								disabled={!isEditing}
								label={t('modules.settings.employees.fields.canOpenAccountBalance')}
								{...register(`accountRights.${idx}.rights.account.canOpenBalance`)}
							/>
						</div>
						<div className="mb-6">
							<span className="text-gray-700 text-sm leading-5 font-normal mb-2">
								{t('modules.settings.employees.fields.payments')}
							</span>
							<Checkbox
								wrapperClassName="mb-2"
								defaultChecked={Boolean(watcher.accountRights?.length) && watcher.accountRights[idx].rights.payment.canOpen}
								data-test-id="admin-merchants-canOpen"
								disabled={!isEditing}
								label={t('modules.settings.employees.fields.canOpenPayments')}
								{...register(`accountRights.${idx}.rights.payment.canOpen`)}
							/>
							<Checkbox
								wrapperClassName="mb-2"
								defaultChecked={
									Boolean(watcher.accountRights?.length) && watcher.accountRights[idx].rights.payment.canCreate
								}
								data-test-id="admin-merchants-canCreate"
								disabled={!isEditing}
								label={t('modules.settings.employees.fields.canCreatePayments')}
								{...register(`accountRights.${idx}.rights.payment.canCreate`)}
							/>
						</div>
						<div>
							<span className="text-gray-700 text-sm leading-5 font-normal mb-2">
								{t('modules.settings.employees.fields.affiliate')}
							</span>
							<Checkbox
								defaultChecked={
									Boolean(watcher.accountRights?.length) && watcher.accountRights[idx].rights.affiliate.canOpen
								}
								data-test-id="admin-merchants-canOpen"
								disabled={!isEditing}
								label={t('modules.settings.employees.fields.canOpenAffiliate')}
								{...register(`accountRights.${idx}.rights.affiliate.canOpen`)}
							/>
						</div>
					</div>
				</FormRow>
			))}

			{isEditing && (
				<div className="flex justify-between items-center mt-6">
					<Button variant="secondary" onClick={handleCancel}>
						{t('ui.buttons.cancel')}
					</Button>
					<Button
						variant="primary"
						onClick={handleSubmit(handleConfirm)}
						disabled={Boolean(Object.keys(errors).length) || !watcher.roleName}>
						{t('ui.buttons.confirm')}
					</Button>
				</div>
			)}
		</div>
	);
};

export default SelectedEmployeeTab;
