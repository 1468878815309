import React, { type FC, useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button, FormRow, Input, Switch } from 'components/UI';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { useAppDispatch, useAppSelector, useValidation } from 'shared/hooks';
import { type ISettingsData } from '../types';
import { setSettings } from '../store/reducer';
import TwoStepAuthModal from './TwoStepAuthModal';
import SettingsService from '../service';
import SuccessTwoStepModal from './SuccessTwoStepModal';
import WarningTwoStepModal from './WarningTwoStepModal';
import { setHas2fa } from '../../app/store/reducer';
import { customEventEmitter } from '../../../shared/utils';

const BasicDataTab: FC = () => {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();

	const settings = useAppSelector(state => state.settings.settings);
	const [isEditing, setIsEditing] = useState<boolean>(false);
	const [showAuthModal, setShowAuthModal] = useState<boolean>(false);
	const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
	const [showWarningModal, setShowWarningModal] = useState<boolean>(false);
	const {
		stringValidation,
		urlValidation,
		emailValidation,
		phoneNumberValidation,
	} = useValidation();

	const {
		trigger,
		watch,
		setValue,
		clearErrors,
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<ISettingsData>({ mode: 'all' });
	const watcher = watch();

	const fillForm = useCallback(() => {
		if (!settings) return;
		Object.entries(settings).forEach(([key, value]) => {
			setValue(key as keyof ISettingsData, value, { shouldTouch: false });
		});
		clearErrors();
	}, [settings, clearErrors, setValue]);

	const toggleEditing = useCallback(() => {
		setIsEditing(prev => !prev);
	}, []);

	const handleCancel = useCallback(() => {
		Object.entries(settings).forEach(([key, value]) => {
			if (key === 'ipCheckEnabled' || key === 'twoStepIdentificationEnabled')
				return;
			setValue(key as keyof ISettingsData, value, { shouldTouch: false });
		});
		toggleEditing();
		clearErrors();
	}, [clearErrors, setValue, settings, toggleEditing]);

	const handleConfirm = useCallback(
		async (data: ISettingsData) => {
			try {
				const updatedSettings = await SettingsService.setSettings(data);
				dispatch(
					setSettings({
						...updatedSettings,
						twoStepIdentificationEnabled: data.twoStepIdentificationEnabled,
					}),
				);
			} catch (e) {
				fillForm();
			} finally {
				toggleEditing();
			}
		},
		[dispatch, fillForm, settings?.twoStepIdentificationEnabled, toggleEditing],
	);

	const handleAuthChange = useCallback(() => {
		if (
			!settings.twoStepIdentificationEnabled &&
			watcher.twoStepIdentificationEnabled
		) {
			setShowAuthModal(true);
		}
	}, [
		settings.twoStepIdentificationEnabled,
		watcher.twoStepIdentificationEnabled,
	]);

	const handleAuthModalClose = useCallback(() => {
		setShowAuthModal(false);
		setValue('twoStepIdentificationEnabled', false);
		void trigger('twoStepIdentificationEnabled');
	}, [trigger, setValue]);

	const handleSuccessModalClose = useCallback(() => {
		setShowSuccessModal(false);
	}, []);

	const handleWarningModalClose = useCallback(() => {
		setShowWarningModal(false);
		setValue('twoStepIdentificationEnabled', true);
		void trigger('twoStepIdentificationEnabled');
	}, [trigger, setValue]);

	const handleWarningModalConfirm = useCallback(async () => {
		setShowWarningModal(false);
		setValue('twoStepIdentificationEnabled', false);
		void trigger('twoStepIdentificationEnabled');
		await SettingsService.getTwoStepData(false);
		dispatch(setHas2fa(false));
		customEventEmitter.trigger(customEventEmitter.events.UPDATE_TOKENS);
	}, [trigger, setValue, dispatch]);

	const handleAuthModalConfirm = useCallback(() => {
		setValue('twoStepIdentificationEnabled', true);
		setShowAuthModal(false);
		setShowSuccessModal(true);
		customEventEmitter.trigger(customEventEmitter.events.UPDATE_TOKENS);
	}, [setValue]);

	useEffect(() => {
		if (!Object.values(watcher).length) return;
		if (
			!watcher.twoStepIdentificationEnabled &&
			settings?.twoStepIdentificationEnabled
		) {
			setShowWarningModal(true);
		}
	}, [watcher.twoStepIdentificationEnabled]);

	useEffect(() => {
		if (!Object.values(watcher).length) return;
		void SettingsService.toggleIpChecked(watcher.ipCheckEnabled);
	}, [watcher.ipCheckEnabled]);

	useEffect(fillForm, [fillForm, settings]);

	useEffect(() => {
		void SettingsService.getSettings().then(settings =>
			dispatch(setSettings(settings)),
		);
	}, []);

	return (
		<div>
			<div className="flex justify-between items-center py-2">
				<h4 className="text-gray-900 text-lg font-medium min-h-[38px]">
					{t('modules.clientDetails.settings')}
				</h4>
				{!isEditing && (
					<Button
						data-test-id="client-settings-edit"
						variant="secondary"
						onClick={toggleEditing}>
						<EditIcon className="mr-2.5" />
						{t('ui.buttons.edit')}
					</Button>
				)}
			</div>

			<FormRow label={t('modules.settings.fields.name')}>
				<Input
					data-test-id="client-settings-name"
					placeholder={t('modules.settings.placeholders.name')}
					disabled={!isEditing}
					isError={Boolean(errors.title)}
					errorText={errors.title?.message}
					wrapperClassName="max-w-[512px] w-full"
					{...register('title', stringValidation)}
				/>
			</FormRow>
			<FormRow label={t('modules.settings.fields.website')}>
				<Input
					data-test-id="client-settings-website"
					placeholder={t('modules.settings.placeholders.website')}
					disabled={!isEditing}
					isError={Boolean(errors.businessWebsite)}
					errorText={errors.businessWebsite?.message}
					wrapperClassName="max-w-[512px] w-full"
					{...register('businessWebsite', urlValidation)}
				/>
			</FormRow>

			<FormRow label={t('modules.settings.fields.email')}>
				<Input
					data-test-id="client-settings-email"
					placeholder={t('modules.settings.placeholders.email')}
					disabled={!isEditing}
					isError={Boolean(errors.companyEmail)}
					errorText={errors.companyEmail?.message}
					wrapperClassName="max-w-[512px] w-full"
					{...register('companyEmail', emailValidation)}
				/>
			</FormRow>
			<FormRow label={t('modules.settings.fields.phone')}>
				<Input
					data-test-id="client-settings-phone"
					placeholder={t('modules.settings.placeholders.phone')}
					disabled={!isEditing}
					isError={Boolean(errors.companyPhone)}
					errorText={errors.companyPhone?.message}
					wrapperClassName="max-w-[512px] w-full"
					{...register('companyPhone', {
						...phoneNumberValidation,
						required: false,
					})}
				/>
			</FormRow>
			<FormRow label={t('modules.settings.fields.address')}>
				<Input
					data-test-id="client-settings-address"
					placeholder={t('modules.settings.placeholders.address')}
					disabled={!isEditing}
					isError={Boolean(errors.companyAddress)}
					errorText={errors.companyAddress?.message}
					wrapperClassName="max-w-[512px] w-full"
					{...register('companyAddress', {
						...stringValidation,
						required: false,
					})}
				/>
			</FormRow>

			{!isEditing && (
				<div>
					<h4 className="text-gray-900 text-lg font-medium min-h-[38px] mt-8">
						{t('modules.clientDetails.securitySettings')}
					</h4>

					<FormRow label={t('modules.settings.fields.checkIp')}>
						<div className="max-w-[512px] w-full flex items-center h-[38px]">
							<Switch
								data-test-id="client-settings-checkIp"
								{...register('ipCheckEnabled')}
							/>
						</div>
					</FormRow>
					<FormRow label={t('modules.settings.fields.twoStepAuth')}>
						<div className="max-w-[512px] w-full flex items-center h-[38px]">
							<Switch
								data-test-id="client-settings-twoStepAuth"
								checked={watcher.twoStepIdentificationEnabled}
								{...register('twoStepIdentificationEnabled')}
								onInput={handleAuthChange}
							/>
						</div>
					</FormRow>
				</div>
			)}

			{showAuthModal && (
				<TwoStepAuthModal
					enabled={watcher.twoStepIdentificationEnabled}
					onClose={handleAuthModalClose}
					onConfirm={handleAuthModalConfirm}
				/>
			)}

			{isEditing && (
				<div className="flex justify-between items-center mt-6">
					<Button
						data-test-id="client-settings-cancel"
						variant="secondary"
						onClick={handleCancel}>
						{t('ui.buttons.cancel')}
					</Button>
					<Button
						data-test-id="client-settings-confirm"
						variant="primary"
						onClick={handleSubmit(handleConfirm)}
						disabled={Boolean(Object.keys(errors).length)}>
						{t('ui.buttons.confirm')}
					</Button>
				</div>
			)}

			<SuccessTwoStepModal
				show={showSuccessModal}
				onClose={handleSuccessModalClose}
			/>
			<WarningTwoStepModal
				show={showWarningModal}
				onClose={handleWarningModalClose}
				onConfirm={handleWarningModalConfirm}
			/>
		</div>
	);
};

export default BasicDataTab;
