import React, { type FC, useCallback, useState } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimeField } from '@mui/x-date-pickers/TimeField';
import 'react-datetime/css/react-datetime.css';
import clsx from 'clsx';
import { type Dayjs } from 'dayjs';
import { useCustomEventListener } from 'shared/hooks';
import { customEventEmitter } from 'shared/utils';
import classes from './timeInput.module.scss';
import { type IUpdateTimeEvent } from './types';

interface ITimeInputProps {
	wrapperClassName?: string;
	id: string;
	disabled?: boolean;
	onChange: (time: Dayjs) => void;
}

const TimeInput: FC<ITimeInputProps> = ({ wrapperClassName, onChange, disabled, id }) => {
	const [pickerKey, setPickerKey] = useState<string>('pickerKey');

	const handleClear = useCallback(() => {
		// TODO: get rid of key rerender
		setPickerKey(Math.random().toString());
	}, []);

	const handleChange = useCallback(
		(v: string | null) => {
			const val = v as unknown as Dayjs;
			onChange(val);
		},
		[onChange],
	);

	const handleResetDate = useCallback(
		(e: Event) => {
			const {
				detail: { position },
			} = e as IUpdateTimeEvent;
			if (id !== position) return;
			handleClear();
		},
		[id, handleClear],
	);

	useCustomEventListener(customEventEmitter.events.RESET_TIME_FILTER, handleResetDate);

	return (
		<div className={clsx(wrapperClassName, classes.timeInputWrapper)}>
			<LocalizationProvider dateAdapter={AdapterDayjs}>
				<TimeField disabled={disabled} key={pickerKey} className={classes.timeInput} onChange={handleChange} format="HH:mm:ss" />
			</LocalizationProvider>
		</div>
	);
};

export default TimeInput;
