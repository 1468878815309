import React, { forwardRef, type ForwardRefRenderFunction, useCallback, useEffect } from 'react';
import {
	type ColDef,
	type ColumnEverythingChangedEvent,
	type GridOptions,
	type GridReadyEvent,
	type RowClickedEvent,
} from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { useNavigate } from 'react-router-dom';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { addHistoryRecord } from 'modules/app';
import { customEventEmitter } from 'shared/utils';
import { useAppDispatch } from 'shared/hooks';
import { TableSkeleton } from './components';
import './table.css';
import { useTranslation } from 'react-i18next';

interface IProps {
	rowData: unknown[];
	columnDefs: ColDef[];
	isLoading: boolean;
	navigateOnRowClick?: boolean;
}
const DefaultTable: ForwardRefRenderFunction<AgGridReact<unknown>, IProps> = (
	{ isLoading, rowData, columnDefs, navigateOnRowClick },
	ref,
) => {
	const { t } = useTranslation();

	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const gridOptions: GridOptions<unknown> = {
		rowHeight: 60,
		onGridReady: (event: GridReadyEvent) => {
			event.api.sizeColumnsToFit();
			window.onresize = () => {
				event.api.sizeColumnsToFit();
			};
		},
		onBodyScroll() {
			customEventEmitter.trigger(customEventEmitter.events.SCROLL_TABLE);
		},
		onColumnEverythingChanged(event: ColumnEverythingChangedEvent<unknown>) {
			event.api.sizeColumnsToFit();
		},
		onRowClicked(event: RowClickedEvent<unknown>) {
			if (!navigateOnRowClick) return;
			const data = event.data as { id?: string };
			if (!data.id) return;
			dispatch(addHistoryRecord(window.location.pathname));
			const path = location.pathname.split('/');
			const tab = path[path.length - 1];
			if (tab === 'employees') {
				navigate(data.id);
				return;
			}

			navigate(data.id + '/main');
		},
	};

	const handleMiddleMouseClick = useCallback(
		(event: MouseEvent) => {
			if (!rowData) return;
			if (event.which === 2) {
				const row = event.composedPath().find(element => (element as Element).classList?.contains('ag-row'));
				if (!row) return;
				const index = (row as Element).getAttribute('row-index');
				if (!index) return;
				const clickedRow = rowData[Number(index)] as { id?: string };
				if (!clickedRow?.id) return;
				event.preventDefault();

				const path = location.pathname.split('/');
				const tab = path[path.length - 1];
				if (tab === 'transactions') return;

				if (tab === 'employees') {
					window.open(`${window.location.href}/${clickedRow.id}`, '_blank');
					navigate(clickedRow.id);
					return;
				}

				window.open(`${window.location.href}/${clickedRow.id}/main`, '_blank');
			}
		},
		[location.pathname, rowData],
	);

	useEffect(() => {
		if (!rowData) return;
		document.body.addEventListener('mouseup', handleMiddleMouseClick);
		return () => {
			document.body.removeEventListener('mouseup', handleMiddleMouseClick);
		};
	}, [handleMiddleMouseClick]);

	return (
		<div className="ag-theme-alpine relative">
			<AgGridReact
				enableCellTextSelection
				gridOptions={gridOptions}
				rowData={rowData}
				ref={ref}
				columnDefs={columnDefs}
				domLayout="autoHeight"
				overlayNoRowsTemplate={`<span class='mb-3 text-gray-900 text-sm'>${t('ui.table.empty')}</span>`}
			/>
			{isLoading && (
				<div className="absolute top-12 bottom-0 right-0 left-0 z-1">
					<TableSkeleton />
				</div>
			)}
		</div>
	);
};

export default forwardRef<AgGridReact<unknown>, IProps>(DefaultTable);
