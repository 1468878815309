import React, { type FC, useCallback, useMemo } from 'react';
import { type ICellRendererParams } from 'ag-grid-community';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { addHistoryRecord } from 'modules/app';
import { useAppDispatch } from 'shared/hooks';
import { type Nullable } from 'shared/types';
import { type ICell } from '../types';
import dayjs from 'dayjs';

const TextRenderer: FC<ICellRendererParams<unknown>> = ({ value, colDef, data }) => {
	const dispatch = useAppDispatch();

	const textToDisplay = useMemo<string>(() => {
		return Array.isArray(value)
			? value.map((it: { id: string; name: string }, idx, arr) => (idx === arr.length - 1 ? it.name : `${it.name},`)).join(' ')
			: value;
	}, [value]);

	const id = useMemo<string>(() => {
		return (data as ICell)?.id ?? '';
	}, [data]);

	const link = useMemo<Nullable<string>>(() => {
		if (!colDef?.cellEditor.linkCreator) return null;
		const link: string = colDef?.cellEditor.linkCreator(id) ?? '';
		return link ?? null;
	}, [colDef, id]);

	const handleLinkClick = useCallback(() => {
		dispatch(addHistoryRecord(window.location.pathname));
	}, [dispatch]);

	const date = useMemo<string>(() => {
		if ((colDef?.field !== 'processedAt' && colDef?.field !== 'date' && colDef?.field !== 'createdAt') || !value) return '';
		return dayjs(value).format('DD.MM.YYYY, HH:mm');
	}, [colDef?.field, value]);

	return (
		<div className={clsx('text-sm h-full flex py-5 text-gray-500 font-normal')}>
			{link ? (
				<Link className="truncate" to={link} onClick={handleLinkClick}>
					{textToDisplay}
				</Link>
			) : (
				<span className="truncate">
					{colDef?.field === 'processedAt' || colDef?.field === 'date' || colDef?.field === 'createdAt' ? date : textToDisplay}
				</span>
			)}
		</div>
	);
};

export default TextRenderer;
