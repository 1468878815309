import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { type ColDef } from 'ag-grid-community';
import { type Nullable, type ITableSettings, type UpdateTableData } from 'shared/types';
import { initialSelectedAccount } from '../constants';
import {
	type IAccountsDetails,
	type IAccountsData,
	type IConnectionData,
	type IAccountWalletDetails,
	type IIntegrationData,
} from '../types';

interface PaymentState {
	billingData: Nullable<IAccountsData[]>;
	id: Nullable<string>;
	isLoading: boolean;
	selectedAccount: {
		id: Nullable<string>;
		mainData: Nullable<IAccountsDetails>;
		wallets: {
			availableIntegrations: IIntegrationData[];
			columns: Array<ColDef<IAccountWalletDetails>>;
			rows: IAccountWalletDetails[];
			settings: ITableSettings;
			totalPages: number;
			itemsCount: Nullable<number>;
			isLoading: boolean;
		};
		connectionData: IConnectionData;
	};
}

const initialState: PaymentState = {
	id: null,
	billingData: null,
	isLoading: false,
	selectedAccount: initialSelectedAccount,
};

const accountsSlice = createSlice({
	name: 'accounts',
	initialState,
	reducers: {
		setBillingData(state, { payload }: PayloadAction<Nullable<IAccountsData[]>>) {
			state.billingData = payload;
		},
		setSelectedMainData(state, { payload }: PayloadAction<Nullable<IAccountsDetails>>) {
			state.selectedAccount.mainData = payload;
		},
		setSelectedConnectionData(state, { payload }: PayloadAction<IConnectionData>) {
			state.selectedAccount.connectionData = payload;
		},
		setSelectedId(state, { payload }: PayloadAction<Nullable<string>>) {
			state.selectedAccount.id = payload;
		},
		setSelectedAccountWalletsRows(state, { payload }: PayloadAction<IAccountWalletDetails[]>) {
			state.selectedAccount.wallets.rows = payload;
		},
		setSelectedAccountWalletsCols(state, { payload }: PayloadAction<Array<ColDef<IAccountWalletDetails>>>) {
			state.selectedAccount.wallets.columns = payload;
		},
		updateSelectedAccountWalletsSettings(state, { payload }: PayloadAction<UpdateTableData>) {
			state.selectedAccount.wallets.settings[payload.key] = payload.value as never;
		},
		setSelectedAccountWalletsTotalPages(state, { payload }: PayloadAction<number>) {
			state.selectedAccount.wallets.totalPages = payload;
		},
		setSelectedAccountWalletsItemsCount(state, { payload }: PayloadAction<number>) {
			state.selectedAccount.wallets.itemsCount = payload;
		},
		setSelectedAccountWalletsAvailableIntegrations(state, { payload }: PayloadAction<IIntegrationData[]>) {
			state.selectedAccount.wallets.availableIntegrations = payload;
		},
		integrateSelectedAccountWallet(state, { payload }: PayloadAction<string>) {
			state.selectedAccount.wallets.availableIntegrations = state.selectedAccount.wallets.availableIntegrations.filter(
				integration => integration.accountId !== payload,
			);
		},
	},
});

export const {
	setBillingData,
	setSelectedId,
	setSelectedMainData,
	setSelectedAccountWalletsRows,
	setSelectedAccountWalletsCols,
	updateSelectedAccountWalletsSettings,
	setSelectedAccountWalletsTotalPages,
	setSelectedAccountWalletsItemsCount,
	setSelectedConnectionData,
	setSelectedAccountWalletsAvailableIntegrations,
	integrateSelectedAccountWallet,
} = accountsSlice.actions;

export default accountsSlice.reducer;
