import React, { type ChangeEvent, type FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Input } from 'components/UI';
import { type Range } from '../types';

interface IProps {
	onConfirm: (key: string, value: Range) => void;
	onClose: () => void;
	values: Range;
	testId: string;
	integer?: boolean;
}
const RangeSetting: FC<IProps> = ({ onConfirm, values, onClose, testId, integer }) => {
	const { t } = useTranslation();

	const [localValues, setLocalValues] = useState(values);
	const [isFromError, setIsFromError] = useState<boolean>(false);
	const [isToError, setIsToError] = useState<boolean>(false);
	const handleClose = useCallback(() => {
		setLocalValues(values);
		onClose();
		setIsFromError(false);
		setIsToError(false);
	}, [values, onClose]);

	const handleInputChange = useCallback(
		(e: ChangeEvent<HTMLInputElement>) => {
			setLocalValues(prev => {
				if (e.target.value.endsWith('.')) {
					if (e.target.id === 'from') setIsFromError(true);
					else setIsToError(true);
					return { ...prev, [e.target.id]: e.target.value };
				}

				const value = e.target.value.length && !isNaN(parseFloat(e.target.value)) ? e.target.value : null;
				if (e.target.id === 'from') {
					if (isNaN(Number(e.target.value))) setIsFromError(true);
					else setIsFromError(false);
					if (integer && !Number.isInteger(Number(e.target.value))) setIsFromError(true);
				}

				if (e.target.id === 'to') {
					if (isNaN(Number(e.target.value))) setIsToError(true);
					else setIsToError(false);
					if (integer && !Number.isInteger(Number(e.target.value))) setIsToError(true);
				}

				if (e.target.value.startsWith('.')) {
					if (e.target.id === 'from') setIsFromError(true);
					else setIsToError(true);
				}

				return { ...prev, [e.target.id]: value };
			});
		},
		[integer],
	);

	useEffect(() => {
		if (!localValues.from || !localValues.to || isNaN(localValues.from) || isNaN(localValues.to)) return;
		if (Number(localValues.from) > Number(localValues.to)) {
			setIsFromError(true);
			setIsToError(true);
		} else {
			setIsFromError(false);
			setIsToError(false);
		}

		if (integer && !Number.isInteger(Number(localValues.from))) setIsFromError(true);
		if (integer && !Number.isInteger(Number(localValues.to))) setIsToError(true);
		if (String(localValues.from).startsWith('.') || String(localValues.from).endsWith('.')) setIsFromError(true);
		if (String(localValues.to).startsWith('.') || String(localValues.to).endsWith('.')) setIsToError(true);
	}, [localValues]);

	useEffect(() => {
		setLocalValues(values);
	}, [values]);

	return (
		<div>
			<div className="flex items-center py-4">
				<Input
					data-test-id={`client-search-${testId}-from`}
					wrapperClassName="mr-2"
					placeholder={t('ui.table.from')}
					id="from"
					value={localValues.from === null || localValues.from === undefined ? '' : String(localValues.from)}
					onInput={handleInputChange}
					isError={isFromError}
				/>
				<Input
					data-test-id={`client-search-${testId}-to`}
					placeholder={t('ui.table.to')}
					id="to"
					value={localValues.to === null || localValues.to === undefined ? '' : String(localValues.to)}
					onInput={handleInputChange}
					isError={isToError}
				/>
			</div>
			<div className="flex justify-between items-center">
				<Button data-test-id={`client-search-${testId}-cancel`} variant="secondary" onClick={handleClose}>
					{t('ui.buttons.close')}
				</Button>
				<Button
					data-test-id={`client-search-${testId}-confirm`}
					disabled={isFromError || isToError}
					variant="primary"
					onClick={onConfirm.bind(null, '', localValues)}>
					{t('ui.buttons.confirm')}
				</Button>
			</div>
		</div>
	);
};

export default RangeSetting;
