import React, { forwardRef, type ForwardRefRenderFunction, type InputHTMLAttributes } from 'react';
import clsx from 'clsx';

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
	wrapperClassName?: string;
}
const RadioInput: ForwardRefRenderFunction<HTMLInputElement, IProps> = ({ wrapperClassName, className, ...rest }, ref) => {
	return (
		<div
			className={clsx(
				'relative w-[16px] h-[16px] min-w-[16px] min-h-[16px]',
				wrapperClassName,
				rest.disabled && 'cursor-not-allowed',
			)}>
			<input {...rest} ref={ref} type="radio" className={clsx(className, 'opacity-0 absolute z-10 inset-0 cursor-pointer block')} />
			<div
				className={clsx(
					'absolute inset-0 flex justify-center items-center rounded-full border border-gray-300',
					rest.checked && 'bg-main border-main',
				)}>
				<div className="w-1.5 h-1.5 rounded-full bg-white"></div>
			</div>
		</div>
	);
};

export default forwardRef<HTMLInputElement, IProps>(RadioInput);
