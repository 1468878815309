import React, { type FC } from 'react';
import { Button } from 'components/UI';
import { Modal } from 'components';
import { useTranslation } from 'react-i18next';

interface IProps {
	onClose: () => void;
	onConfirm: () => void;
	show: boolean;
}
const WarningTwoStepModal: FC<IProps> = ({ onClose, onConfirm, show }) => {
	const { t } = useTranslation();

	return (
		<Modal show={show} onClose={onClose}>
			<div className="flex flex-col items-center">
				<h4 className="text-2xl leading-6 font-medium text-gray-900 mb-2">{t('modules.settings.twoStep.warningTitle')}</h4>
				<p className="text-sm leading-5 font-normal text-gray-500 mb-5 max-w-[450px] text-center">
					{t('modules.settings.twoStep.warningSubTitle')}
				</p>
				<div className="flex ml-auto">
					<Button className="mr-3" data-test-id="client-withdrawal-create-new" variant="create" onClick={onClose}>
						{t('ui.buttons.cancel')}
					</Button>
					<Button data-test-id="client-withdrawal-create-close" variant="secondary" onClick={onConfirm}>
						{t('ui.buttons.continue')}
					</Button>
				</div>
			</div>
		</Modal>
	);
};

export default WarningTwoStepModal;
